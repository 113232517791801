import React, { ReactElement, useEffect, useState } from 'react';
import './jumpLinks.scss';
import Icon from '../Icons/Icons';
import { useMobile } from '../../hooks/mediaHook';

export type LinkProps = {
  href: string;
  label: string;
};

export type JumpLinkProps = {
  links: LinkProps[];
};

export default function JumpLinks({ links }: JumpLinkProps): ReactElement {
  const [checked, setChecked] = useState(false);
  const isMobile = useMobile('1024px');
  const [isSafari, setIsSafari] = useState(false);
  const handleClick = (clickedItem: number): void => {
    const hiddenListItem = document.getElementById(`jump-links__hidden-item-${clickedItem}`);
    if (hiddenListItem) {
      hiddenListItem.click();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (window && !!window.GestureEvent) setIsSafari(true);
  }, []);

  return (
    <>
      {/* FIXME: This is here because Safari cannot calculate the height correctly of collapsable elements whilst scrolling */}
      {isSafari && (
        <ul className="jump-links__hidden-list">
          {links.map((link, i) => (
            <li className="jump-links__hidden-list__item" key={i}>
              <a href={link.href} id={`jump-links__hidden-item-${i}`} className="jump-links__hidden-list__link">
                {link.label}
                {!isMobile && <Icon icon="underline" />}
              </a>
            </li>
          ))}
        </ul>
      )}
      <nav className="jump-links">
        <div className="jump-links__container">
          <ul className={`jump-links__list ${isMobile && checked ? 'jump-links__list--open' : ''}`}>
            {isMobile && (
              <li className="jump-links__item jump-links__item--toggle">
                <input
                  className="jump-links__toggle"
                  id="toggle"
                  type="checkbox"
                  defaultChecked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className="jump-links__toggle-label" htmlFor="toggle">
                  On this page
                </label>
              </li>
            )}
            {links.map((link, i) => (
              <li className="jump-links__item" key={i}>
                <a
                  href={link.href}
                  className="jump-links__link"
                  onClick={(e) => {
                    /* FIXME: This is here because Safari cannot calculate the height correctly of collapsable elements whilst scrolling */
                    if (isSafari) {
                      e.preventDefault();
                      e.stopPropagation();
                      setChecked(false);
                      setTimeout(() => {
                        handleClick(i);
                      }, 350);
                    } else {
                      setChecked(false);
                    }
                  }}
                >
                  {link.label}
                  {!isMobile && <Icon icon="underline" />}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}
