import React, { ReactElement, SyntheticEvent } from 'react';
import Icon from '../Icons/Icons';
import './share.scss';

export interface ShareProps {
  heading: string;
  fbLink: string;
  twitterLink: string;
}

export default function Share({ heading, fbLink, twitterLink }: ShareProps): ReactElement {
  const onPopupClick = (e: SyntheticEvent, link: string): void => {
    e.preventDefault();
    window.open(link, 'popup', 'width=600, height=600');
  };

  return (
    <section className="share">
      <h2 className="share__heading">Share {heading}</h2>
      <ul className="share__links">
        <li className="share__icon">
          <a
            className="share__link"
            href={fbLink}
            target="popup"
            data-ga4-type="ui"
            data-ga4-area="social_share"
            onClick={(e: SyntheticEvent) => {
              onPopupClick(e, fbLink);
            }}
          >
            <span className="sr-only">Facebook</span>
            <Icon icon="facebook" ariaHidden />
          </a>
        </li>
        <li className="share__icon">
          <a
            className="share__link"
            href={twitterLink}
            target="popup"
            data-ga4-type="ui"
            data-ga4-area="social_share"
            onClick={(e: SyntheticEvent) => {
              onPopupClick(e, twitterLink);
            }}
          >
            <span className="sr-only">Twitter</span>
            <Icon icon="twitter" ariaHidden />
          </a>
        </li>
      </ul>
    </section>
  );
}
