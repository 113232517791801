import React, { ReactElement, useState } from 'react';
import { GALinkProps } from '../../defaultProps';
import './explorer-image.scss';

export type ImageSource = {
  small: string;
  medium: string;
  large: string;
};

export type ExplorerImageProps = {
  src: ImageSource;
  altText?: string;
  title?: string;
  loadingAttr?: 'lazy' | 'eager';
  gaTags?: GALinkProps;
  onLoad?: () => void;
  width?: number;
  height?: number;
  sizes?: string;
  priority?: boolean;
  component?: React.ElementType;
  quality?: number;
  placeholder?: 'blur' | 'empty';
  blurDataURL?: string;
};

export default function ExplorerImage({
  src,
  altText,
  title,
  loadingAttr,
  gaTags,
  onLoad,
  width,
  height,
  sizes = '100vw',
  priority = false,
  quality,
  placeholder,
  blurDataURL,
  component: ImageComponent = 'img'
}: ExplorerImageProps): ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = (): void => {
    setIsLoaded(true);
    if (onLoad) onLoad();
  };

  const className = `explorer-image ${isLoaded ? 'explorer-image--loaded' : ''}`;

  if (ImageComponent === 'img') {
    return (
      <img
        className={className}
        src={src.large}
        srcSet={`${src.small} 576w, ${src.medium} 992w, ${src.large} 1440w`}
        alt={altText || ''}
        title={title}
        loading={priority ? undefined : loadingAttr}
        width={width}
        height={height}
        sizes={sizes}
        onLoad={handleLoad}
        data-ga4-type={gaTags?.type}
        data-ga4-area={gaTags?.area}
        data-gtm-name={gaTags?.name}
      />
    );
  } else {
    return (
      <ImageComponent
        className={className}
        src={src.large}
        alt={altText || ''}
        title={title}
        width={width}
        height={height}
        sizes={sizes}
        onLoad={handleLoad}
        priority={priority}
        quality={quality}
        placeholder={placeholder}
        blurDataURL={blurDataURL}
        data-ga4-type={gaTags?.type}
        data-ga4-area={gaTags?.area}
        data-gtm-name={gaTags?.name}
      />
    );
  }
}
