import React, { ReactElement } from 'react';
import dayjs from 'dayjs';
import { Input, Checkbox } from '../../FormFields/FormFields';
import { ThemeProps } from '../../../defaultProps';

export interface Under25FieldValues {
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  optIn: string;
}

interface Under25FieldsProps {
  fieldValues: Under25FieldValues;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  theme?: ThemeProps;
  showAgeError: boolean;
}

export default function Under25Fields({
  fieldValues,
  onChange,
  theme,
  showAgeError
}: Under25FieldsProps): ReactElement {
  const earliestBirthdate = dayjs().add(1, 'day').subtract(26, 'years').format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');

  return (
    <>
      <div className="newsletter__two-sections">
        <Input
          label="First name"
          id="firstName"
          type="text"
          name="firstName"
          value={fieldValues.firstName ?? ''}
          required
          onChange={onChange}
        />
        <Input
          label="Last name"
          id="lastName"
          type="text"
          name="lastName"
          value={fieldValues.lastName ?? ''}
          required
          onChange={onChange}
        />
      </div>
      <Input
        label="Email"
        id="email"
        type="email"
        name="email"
        value={fieldValues.email ?? ''}
        required
        onChange={onChange}
      />
      <div>
        <Input
          label="Date of birth"
          id="birthdate"
          type="date"
          name="birthdate"
          value={fieldValues.birthdate ?? ''}
          required
          onChange={onChange}
          ariaInvalid={showAgeError}
          ariaErrorMessage="age-error"
          min={earliestBirthdate}
          max={today}
        />
        {showAgeError && (
          <p className={`newsletter__error newsletter__error--${theme ? theme : ''}`} id="age-error">
            You must be aged under 26 to access this offer
          </p>
        )}
      </div>
      <fieldset className="newsletter__fieldset" aria-describedby="update-description">
        <legend className="newsletter__legend newsletter__legend--required">Stay in the know</legend>
        <p className="newsletter__fieldset-desc" id="update-description">
          We’ll send you regular updates about our 25 & under scheme. Would you also like to receive news about the
          latest events, shop offers and more?
        </p>
        <div className="newsletter__checkbox-group">
          <Checkbox
            type="radio"
            label="Yes, please"
            onChange={onChange}
            theme={theme}
            id="optIn-true"
            name="optIn"
            value="true"
            checked={fieldValues.optIn === 'true'}
            required
          />
          <Checkbox
            type="radio"
            label="No, thank you"
            onChange={onChange}
            theme={theme}
            id="optIn-false"
            name="optIn"
            value="false"
            checked={fieldValues.optIn === 'false'}
            required
          />
        </div>
      </fieldset>
    </>
  );
}
