import React from 'react';
import './explorer-basket-footer.scss';

export interface ExplorerFooterLinkProps {
  label: string;
  href: string;
}

export interface ExplorerBasketFooterProps {
  theme?: string;
  policies_links: ExplorerFooterLinkProps[];
}

export default function ExplorerFooter({ theme, policies_links }: ExplorerBasketFooterProps): JSX.Element {
  return (
    <footer className={`explorer-basket-footer ${theme ?? ''}`}>
      <div className="explorer-basket-footer__container">
        <nav className="explorer-basket-footer__nav">
          {policies_links.map((link, index) => (
            <a key={index} href={link.href} className={'explorer-basket-footer__item explorer-basket-footer__item}'}>
              {link.label}
            </a>
          ))}
        </nav>
      </div>
    </footer>
  );
}
