import { FooterLinkProps } from '@royalacademy/component-library';
import getHostnames from '../utils/getHostnames';

const hostnames = getHostnames();

export const footerAboutLinks = (): FooterLinkProps[] => {
  const footerAboutLinks: FooterLinkProps[] = [
    {
      label: 'About the RA',
      href: hostnames.website + '/about-the-ra',
    },
    {
      label: 'Annual Reports',
      href: hostnames.website + '/about-the-ra#annual-reporting',
    },
    {
      label: 'RA Trust',
      href: hostnames.website + '/royal-academy-trust',
    },
    {
      label: 'RA Magazine',
      href: hostnames.website + '/ra-magazine',
    },
    {
      label: 'Careers',
      href: hostnames.website + '/careers-at-the-ra',
    },
    {
      label: 'Entertaining & Venue Hire',
      href: hostnames.website + '/entertaining-and-event-hire-at-the-royal-academy',
    },
  ];

  return footerAboutLinks;
};

export const footerPoliciesLinks = (): FooterLinkProps[] => {
  const footerPoliciesLinks: FooterLinkProps[] = [
    {
      label: 'Copyright',
      href: hostnames.website + '/copyright-policy',
    },
    {
      label: 'Terms & Conditions',
      href: hostnames.website + '/terms-and-conditions',
    },
    {
      label: 'Privacy',
      href: hostnames.website + '/privacy',
    },
    {
      label: 'Cookies',
      href: hostnames.website + '/cookies',
    },
    {
      label: 'Modern Slavery',
      href: hostnames.website + '/modern-slavery-and-human-trafficking-statement',
    },
    {
      label: 'COVID-19',
      href: hostnames.website + '/covid-19-risk-assessment',
    },
    {
      label: 'Sustainability',
      href: hostnames.website + '/sustainability-and-the-ra',
    },
    {
      label: 'Accessibility Statement',
      href: hostnames.website + '/page/accessibility-statement',
    },
  ];

  return footerPoliciesLinks;
};

export const footerConnectLinks = (): FooterLinkProps[] => {
  const footerConnectLinks: FooterLinkProps[] = [
    {
      label: 'Contact us',
      href: hostnames.website + '/page/contact-us',
    },
    {
      label: 'Press',
      href: hostnames.website + '/press-office',
    },
  ];

  return footerConnectLinks;
};
