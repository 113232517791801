import React, { ReactElement, useState } from 'react';
import { useMobile } from '../../hooks/mediaHook';
import './hero-caption.scss';
import Icon from '../Icons/Icons';

export type HeroCaptionProps = {
  title: string;
  body: string;
  copyright: string;
};

export default function HeroCaption({ title, body, copyright }: HeroCaptionProps): ReactElement {
  const [captionOpen, setCaptionOpen] = useState<boolean>(false);
  const isMobile = useMobile();
  return (
    <div className={`hero-caption ${captionOpen ? 'hero-caption--open' : ''}`}>
      <div className="hero-caption__grid">
        {captionOpen && (
          <div className="hero-caption__content-wrapper">
            <div className="hero-caption__content">
              <span className="hero-caption__title">{title}</span>
              <div
                className="hero-caption__body"
                dangerouslySetInnerHTML={{
                  __html: body
                }}
              />
              <span className="hero-caption__copyright">{copyright}</span>
            </div>
          </div>
        )}
        <div className="hero-caption__sidebar">
          <div className="hero-caption__sidebar-wrapper">
            <button className="hero-caption__btn theme--ra-blue" onClick={() => setCaptionOpen(!captionOpen)}>
              <span className="sr-only">Caption toggle button</span>
              {captionOpen && <Icon icon="close" />}
              {!captionOpen && !isMobile && <Icon icon="chevron-left" />}
              {!captionOpen && isMobile && <Icon icon="caption-copyright" />}
            </button>
            {!isMobile && <div className="hero-caption__header">Image caption</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
