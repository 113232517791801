import React, { ReactElement, useState } from 'react';
import { Input } from '../../FormFields/FormFields';
import { FriendFormComponentProps, NewFriendProps } from '../FriendSignup';
import './confirm-age.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateOfBirthFormats } from '../../../util';

export type ConfirmAgeProps = {
  component: FriendFormComponentProps;
  newFriend: NewFriendProps;
  handleOnBlur: (value: string, name: string, type: string) => void;
  setAgeIsValid: (value: boolean) => void;
  ageIsValid: boolean;
};

export default function ConfirmAge({
  component,
  handleOnBlur,
  ageIsValid,
  setAgeIsValid,
  newFriend
}: ConfirmAgeProps): ReactElement {
  const fieldName = 'tnew_recipient_birth_date';
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const validateFn = (value: string, name: string, age: string): void => {
    // Add dayjs plugins
    dayjs.extend(customParseFormat);

    const enteredDate = dayjs(value, dateOfBirthFormats, true);
    const now = dayjs();
    const startDate = now.subtract(36, 'year').endOf('day');
    const endDate = now.subtract(16, 'year').endOf('day');

    if (!enteredDate.isValid() || dayjs(enteredDate).isAfter(now)) {
      setErrorMessage('Please enter a valid date');
      setAgeIsValid(false);
    } else if (dayjs(enteredDate).isBetween(startDate, endDate) || dayjs(enteredDate).isSame(startDate)) {
      setErrorMessage(undefined);
      setAgeIsValid(true);
      handleOnBlur(value, name, age);
    } else if (dayjs(enteredDate).isBefore(startDate)) {
      setErrorMessage('To become a Young Friend you must be aged between 16 and 35');
      setAgeIsValid(false);
    } else if (dayjs(enteredDate).isAfter(endDate)) {
      setErrorMessage(
        "Pssst, under 16s go free at the RA! If you're between 16 and 35 please check you have entered your birth date correctly"
      );
      setAgeIsValid(false);
    } else {
      setErrorMessage('Please enter a valid date');
      setAgeIsValid(false);
    }
  };
  return (
    <div className="confirm-age">
      <h4 className="confirm-age__title">Confirm age</h4>
      <p className="confirm-age__desc">{component.content} </p>
      <div className="confirm-age__wrapper">
        <Input
          type="text"
          id="confirm_age_dob"
          onBlur={(e) => validateFn(e.target.value, e.target.name, 'age')}
          label="Date of birth"
          name={fieldName}
          value=""
          required={true}
          border={true}
          placeholder="DD/MM/YYYY"
          inlineError={
            newFriend.inlineErrors.includes('tnew_recipient_birth_date') && !ageIsValid
              ? 'Please enter a valid date'
              : errorMessage
          }
        />
      </div>
    </div>
  );
}
