import React, { ReactElement } from 'react';

import './sponsors.scss';
import { formatAnchorName } from '../../util';

type MainSupport = {
  name?: string;
  src?: string;
  alt?: string;
  text?: string;
  linkUrl?: string;
};

export interface SponsorsProps {
  title?: string;
  anchorName?: string;
  mainSupports?: MainSupport[];
  additionalSupports?: string[];
  sponsorText?: string;
  stacked?: boolean;
}

export default function Sponsors({
  title,
  anchorName,
  mainSupports,
  additionalSupports,
  stacked = false,
  sponsorText
}: SponsorsProps): ReactElement | null {
  if ((!mainSupports || mainSupports.length === 0) && (!additionalSupports || additionalSupports.length === 0)) {
    return null;
  }

  const layoutMainSupporters = (): ReactElement[] | null => {
    let hasTitle = false;

    mainSupports?.map((s) => {
      if (s.text) hasTitle = true;
    });

    return mainSupports
      ? mainSupports?.map(
          (s, index): ReactElement => (
            <li className={`sponsors__main-item ${!hasTitle ? 'sponsors__main-item--noTitle' : ''}`} key={index}>
              {hasTitle && <div className="sponsors__main-item__title">{s.text && <h4>{s.text}</h4>}</div>}
              {s.src && s.alt && (
                <div className="sponsors__main-item__image">
                  {s.linkUrl ? (
                    <a href={s.linkUrl}>
                      <img src={s.src} alt={s.alt} />
                    </a>
                  ) : (
                    <img src={s.src} alt={s.alt} />
                  )}
                </div>
              )}
              {!s.src && s.name && (
                <div className="sponsors__main-item__text">
                  <p>{s.name}</p>
                </div>
              )}
            </li>
          )
        )
      : null;
  };

  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`sponsors ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="sponsors__grid">
        {title && <h2 className="sponsors__heading">{title}</h2>}
        <div className={`sponsors__lists ${stacked ? 'sponsors__lists--stacked' : ''}`}>
          {mainSupports && mainSupports.length > 0 && (
            <div className={`sponsors__main ${stacked ? 'sponsors__main--stacked' : ''}`}>
              <ul className="sponsors__main-list">{layoutMainSupporters()}</ul>
            </div>
          )}
          {additionalSupports && additionalSupports.length > 0 && (
            <div
              className={`sponsors__additional ${stacked ? 'sponsors__additional--stacked' : ''} ${
                !(mainSupports && mainSupports.length) ? 'sponsors__additional--alone' : ''
              }`}
            >
              <ul className="sponsors__additional-list">
                {additionalSupports.map((s, index) => (
                  <li className="sponsors__additional-item" key={index}>
                    <p>{s}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {sponsorText && (
            <div className="sponsors__sponsor-text">
              <p>{sponsorText}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
