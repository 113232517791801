import React, { ReactElement } from 'react';
import './tag.scss';
import Icon from '../Icons/Icons';
import { ThemeProps } from '../../defaultProps';

export type TagProps = {
  label: string;
  close?: boolean;
  onClick: (s: string) => void;
  theme?: ThemeProps;
};

export default function Tag({ label, close = true, onClick, theme = 'theme--ra-light-blue' }: TagProps): ReactElement {
  return (
    <button className={`tag ${theme}`} onClick={() => onClick(label)}>
      {label}
      {close && <Icon icon="close"></Icon>}
    </button>
  );
}
