import React, { ReactElement } from 'react';
import { ThemeLightProps } from '../../defaultProps';
import Cta, { CtaProps } from '../Cta/Cta';
import { ImageProps } from '../Image/Image';
import RichText from '../RichText/RichText';
import { formatAnchorName } from '../../util';
import './twoThreeColumn.scss';

type ColumnProps = {
  theme?: ThemeLightProps;
  image?: ImageProps;
  title?: string;
  text: string;
  cta?: CtaProps;
};

export type TwoThreeColumnProps = {
  title?: string;
  anchorName?: string;
  theme?: ThemeLightProps;
  columns: ColumnProps[];
};

export function Column({ theme, image, title, text, cta }: ColumnProps): ReactElement {
  return (
    <div className={`column column--${theme ? theme : ''} ${!image ? 'column--no-image' : ''}`}>
      {image && (
        <div className={`column__image-wrapper column__image-wrapper--${theme ? theme : ''}`}>
          <div className="column__image" style={{ backgroundImage: `url(${image.src.medium})` }} />
        </div>
      )}
      {title && <div className="column__title">{title}</div>}
      {text && (
        <div className="column__text">
          <RichText text={text} noGrid showMore={false} />
        </div>
      )}
      {cta && (
        <div className="column__cta">
          <Cta {...cta} gaTags={{ type: 'ui', area: 'two_three_column' }} />
        </div>
      )}
    </div>
  );
}

export default function TwoThreeColumn({ title, anchorName, columns, theme }: TwoThreeColumnProps): ReactElement {
  return (
    <div
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`two-three-column ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="two-three-column__container">
        {title && <h2 className="two-three-column__title">{title}</h2>}
        <div className="two-three-column__columns">
          {columns.map((column, i) => (
            <Column {...column} theme={theme} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}
