import React, { ReactElement, useCallback, useRef } from 'react';
import Cta from '../../Cta/Cta';
import Icon from '../../Icons/Icons';
import './basket.scss';
import { useMobile } from '../../../hooks/mediaHook';
import { breakpoints } from '../../../util';
import { HostnameProps } from '../../../api/interface';
import { useOnClickOutside } from '../../../hooks/onClickOutside';

export interface BasketApiItemProps {
  id: number;
  type: string;
  title: string;
  description: string;
  price_in_pence: number;
  exhibited: boolean;
  catalogue_number: number;
  data_layer_products: string;
}

export interface BasketStateProps {
  isBasketOpen: boolean;
  basketItems: BasketApiItemProps[];
  timeRemaining: number;
  hasTickets: boolean;
  hasMemberships: boolean;
  hasDonations: boolean;
}

export interface BasketProps extends BasketStateProps {
  closeBasketFn: () => void;
  hostnames: HostnameProps;
}

export default function Basket({
  basketItems,
  hasTickets,
  hasMemberships,
  hasDonations,
  closeBasketFn,
  hostnames
}: BasketProps): ReactElement {
  const theme = 'theme--ra-white';
  const basketRef = useRef<HTMLDivElement>(null);
  const mobile = useMobile(breakpoints.md, true);

  const onClickOutside = useCallback(
    (e: Event) => {
      if (basketRef.current?.contains(e.target as Node)) return;
      closeBasketFn();
    },
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    [closeBasketFn]
  );

  useOnClickOutside(basketRef, onClickOutside);

  return (
    <>
      {!mobile && (
        <div
          className="basket__overlay"
          onClick={() => {
            closeBasketFn();
          }}
        ></div>
      )}
      <div className={`basket ${theme}`} ref={basketRef}>
        <button
          className="basket__close"
          onClick={() => {
            closeBasketFn();
          }}
        >
          {!mobile ? (
            <>
              <Icon icon={'close'} />
              <span className="sr-only">Close</span>
            </>
          ) : (
            <>
              <Icon icon="chevron-left" />
              Back
            </>
          )}
        </button>
        <div className="basket__inner">
          {(hasTickets || hasDonations || hasMemberships) && (
            <div className="basket__section basket__section--tnew">
              <h2>Your basket</h2>
              <ul className="basket__list">
                {hasTickets && (
                  <li className="basket__item">
                    <Icon icon="ticket" /> Ticket(s)
                  </li>
                )}
                {hasMemberships && (
                  <li className="basket__item">
                    <Icon icon="membership" /> Membership
                  </li>
                )}
                {hasDonations && (
                  <li className="basket__item">
                    <Icon icon="donation" /> Donation
                  </li>
                )}
              </ul>
              <Cta
                type="secondary"
                label="Continue to checkout"
                theme={theme}
                gaTags={{ type: 'navigation', area: 'utility', name: 'nav: utility' }}
                href={`${hostnames.tnew}/cart/details`}
              />
            </div>
          )}
          {basketItems.length > 0 && (
            <div className="basket__section basket__section--se">
              <h2>Your Summer Exhibition purchases</h2>
              <ul className="basket__list">
                {basketItems.map((item: BasketApiItemProps, index: number) => (
                  <li key={`basket__item--${index}`} className="basket__item">
                    {item.title}
                  </li>
                ))}
              </ul>
              <Cta
                type="secondary"
                label="Complete your purchase"
                theme={theme}
                href={`${hostnames.account}/basket/checkout`}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
