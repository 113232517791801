import { apiWithoutCredentials } from './config/axiosConfig';

type PostcodeApiProps = {
  addresses: string[];
};

export const postcodeApi = {
  get: async function (apiKey: string, postcode: string): Promise<PostcodeApiProps> {
    const response = await apiWithoutCredentials.request({
      url: `https://api.getaddress.io/find/${postcode}?api-key=${apiKey}&sort=true`,
      method: 'GET'
    });
    return response.data as PostcodeApiProps;
  }
};
