import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const getFriendlyDateRange = (
  startDate: string,
  endDate: string,
  showTime?: boolean,
  showDayOfWeek?: boolean
): string => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  if (startDate === '' || endDate === '') {
    return '';
  }

  if (showTime == undefined) {
    showTime = false;
  }

  // if date is the same.
  if (dayjs(startDate).format('YYYYMMDD') === dayjs(endDate).format('YYYYMMDD')) {
    if (!showTime) {
      return dayjs(startDate)
        .tz('Europe/London')
        .format(showDayOfWeek ? 'dddd D MMMM YYYY' : 'D MMMM YYYY');
    } else {
      const startDateTime = dayjs(startDate).tz('Europe/London').format('h.mm').replace('.00', '');
      const endDateTime = dayjs(endDate).tz('Europe/London').format('h.mm').replace('.00', '');

      let startMeridiem = dayjs(startDate).tz('Europe/London').format('a');
      const endMeridiem = dayjs(endDate).tz('Europe/London').format('a');

      if (startMeridiem == endMeridiem) {
        startMeridiem = '';
      }

      return (
        dayjs(startDate)
          .tz('Europe/London')
          .format(showDayOfWeek ? 'dddd D MMMM YYYY' : 'D MMMM YYYY') +
        ' ' +
        startDateTime +
        startMeridiem +
        ' - ' +
        endDateTime +
        endMeridiem
      );
    }
  }

  const startYear = dayjs(startDate).format('YYYY');
  const endYear = dayjs(endDate).format('YYYY');

  // if year is the same.
  if (startYear === endYear) {
    return (
      dayjs(startDate).format(showDayOfWeek ? 'dddd D MMMM' : 'D MMMM') +
      ' - ' +
      dayjs(endDate).format(showDayOfWeek ? 'dddd D MMMM YYYY' : 'D MMMM YYYY')
    );
  }

  return (
    dayjs(startDate).format(showDayOfWeek ? 'dddd D MMMM YYYY' : 'D MMMM YYYY') +
    ' - ' +
    dayjs(endDate).format(showDayOfWeek ? 'dddd D MMMM YYYY' : 'D MMMM YYYY')
  );
};

export default getFriendlyDateRange;
