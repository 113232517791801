import { useState, useEffect } from 'react';

export function useMobile(mobileWidth = '768px', boolean = true): boolean {
  const [mobile, setMobile] = useState(boolean);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${mobileWidth})`);
    setMobile(!mediaQuery.matches);
    const handler = (e: MediaQueryListEvent): void => setMobile(!e.matches);
    // This is needed to support iOS 12
    if (typeof mediaQuery.addEventListener === 'function') {
      mediaQuery.addEventListener('change', handler);
    } else {
      mediaQuery.addListener(handler);
    }
    return () => {
      // This is needed to support iOS 12
      if (typeof mediaQuery.removeEventListener === 'function') {
        mediaQuery.removeEventListener('change', handler);
      } else {
        mediaQuery.removeListener(handler);
      }
    };
  }, [mobileWidth]);

  return mobile;
}
