import React, { ReactElement } from 'react';
import './pullQuote.scss';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName } from '../../util';
import Icon from '../Icons/Icons';

export type QuoteProps = {
  cite: string;
  name?: string;
  subtitle?: string;
};

export type PullQuoteProps = {
  quotes: QuoteProps[];
  anchorName?: string;
  numberOfColumns: 1 | 2 | 3;
  theme: ThemeProps;
};

export default function PullQuote({ quotes, anchorName, numberOfColumns, theme }: PullQuoteProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`pull-quote ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="pull-quote__container">
        <div className="pull-quote__outer-grid">
          <div className="pull-quote__outer-column">
            <div className={`pull-quote__grid pull-quote__grid--${numberOfColumns}`}>
              {quotes.map((quote, index) => (
                <div className={`pull-quote__quotation pull-quote__quotation--${theme}`} key={`quote${index}`}>
                  <Icon icon="quote" />
                  <blockquote className="pull-quote__cite">{quote.cite}</blockquote>
                  {quote.name && <p className="pull-quote__name">{quote.name}</p>}
                  {quote.subtitle && <p className="pull-quote__subtitle">{quote.subtitle}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
