import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import './user-menu.scss';
import { HeaderSubNavLinkProps, UserStatusProps } from '../Header';
import './user-menu.scss';
import { routes } from '../../../routes';
import Basket, { BasketStateProps } from '../Basket/Basket';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import { useMobile } from '../../../hooks/mediaHook';
import Icon from '../../Icons/Icons';
import { ThemeProps } from '../../../defaultProps';
import { breakpoints } from '../../../util';
import Cta from '../../Cta/Cta';
import { HostnameProps } from '../../../api/interface';
import { UserApi, UserApiProps } from '../../../api/userApi';
import { SessionUserApi, SessionUserApiProps } from '../../../api/sessionUserApi';
import { SessionStorageKey, UseSessionStorage } from '../../../hooks/sessionStorage';

export type UserMenuProps = {
  hostnames: HostnameProps;
  shop: HeaderSubNavLinkProps;
  search: HeaderSubNavLinkProps;
  theme?: ThemeProps;
  accountSignedIn: boolean;
  tnewSignedIn: boolean;
  sticky?: boolean;
  userStatus: UserStatusProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUserStatus: (prevState: any) => void;
  loading: boolean;
  setLoading: (args: boolean) => void;
};

export default function UserMenu({
  hostnames,
  theme = 'theme--ra-black',
  sticky,
  userStatus,
  setUserStatus,
  loading,
  setLoading
}: UserMenuProps): ReactElement {
  const [basketState, updateBasketState] = useState<BasketStateProps>({
    isBasketOpen: false,
    basketItems: [],
    timeRemaining: 0,
    hasTickets: false,
    hasMemberships: false,
    hasDonations: false
  });

  const [searchOpen, setSearchOpen] = useState(false);
  const mobile = useMobile(breakpoints.md, true);
  // TODO: We don't currently need this, but I suspect we might in the near future, so leaving this here for now
  // const isSe = (): boolean => /^(se|se-staging|account|account-staging)?\.?royalacademy/.test(location.hostname);

  const handleToggleBasketFn = (event: React.MouseEvent): void => {
    event.preventDefault();
    updateBasketState((prevState) => ({
      ...prevState,
      isBasketOpen: !basketState.isBasketOpen
    }));
  };

  const handleCloseSearchFn = useCallback(() => {
    setSearchOpen(false);
  }, []);

  const handleToggleSearchFn = (event: React.MouseEvent): void => {
    event.preventDefault();
    setSearchOpen(!searchOpen);
  };

  const handleCloseBasketFn = useCallback(() => {
    updateBasketState((prevState) => ({
      ...prevState,
      isBasketOpen: false
    }));
  }, []);

  const setUserStatusFn = (user: SessionUserApiProps): void => {
    setUserStatus((prevState: UserStatusProps) => ({
      ...prevState,
      loggedIn: user.loggedInAsUser,
      friend: user.constituencies.friend
    }));
    if (user.loggedInAsUser) {
      document.body.classList.add('tnew-logged-in');
    }
    if (user.constituencies.friend) {
      document.body.classList.add('tnew-logged-in--friend');
    }
  };

  const setUserAndBasketFn = (user: SessionUserApiProps): void => {
    setUserStatusFn(user);
    updateBasketState((prevState) => ({
      ...prevState,
      hasTickets: user.cart.hasTickets,
      hasMemberships: user.cart.hasMemberships,
      hasDonations: user.cart.hasDonations
    }));
    setLoading(false);
  };

  const setSeBasketFn = (user: UserApiProps): void => {
    updateBasketState((prevState) => ({
      ...prevState,
      basketItems: user.data.basket.items,
      timeRemaining: user.data.basket.expires_in_seconds
    }));
  };

  // Get the session storage values for the session user
  const [sessionUser, setSessionUser] = UseSessionStorage<SessionUserApiProps>(SessionStorageKey.User, undefined);

  useEffect(() => {
    // If there is an existing session user, we can use that to set the session & basket state
    if (sessionUser !== undefined) setUserAndBasketFn(sessionUser);

    // Then fetch the session user from the api
    SessionUserApi.get(routes.session_user_url(hostnames.api))
      .then((user) => {
        setUserAndBasketFn(user);
        // If the user is logged in to SE then we need to get the basket from the account/se service
        if (user.se) {
          UserApi.get(routes.account_get_user_url(hostnames.account))
            .then((seUser) => {
              setSeBasketFn(seUser);
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error));
        }
        // Setting of the session storage
        setSessionUser(user);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));

    // We only want this to run once
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (basketState.timeRemaining !== 0) {
  //     const timer = setTimeout(() => {
  //       //update basket
  //       console.log('Timer ended');
  //     }, basketState.timeRemaining * 1000);
  //     return () => clearTimeout(timer);
  //   }
  //   return undefined;
  // }, [basketState.timeRemaining]);

  return (
    <div className={`user-menu ${theme}`}>
      <nav className="user-menu__navigation" role="navigation" aria-label="User menu">
        <ul>
          {!loading && !userStatus.loggedIn && (
            <li className="user-menu__item">
              <a
                className="user-menu__link"
                href={`${hostnames.tnew}/account/login?returnurl=%2Fc%2Faccount-hub`}
                tabIndex={sticky ? -1 : 0}
                data-ga4-type="navigation"
                data-ga4-area="utility"
                data-gtm-name="nav: utility"
              >
                Sign in
                {!mobile && <Icon icon="underline" />}
              </a>
            </li>
          )}
          {!loading && userStatus.loggedIn && (
            <li className="user-menu__item">
              <a
                className="user-menu__link"
                href={`${hostnames.tnew}/c/account-hub`}
                tabIndex={sticky ? -1 : 0}
                data-ga4-type="navigation"
                data-ga4-area="utility"
                data-gtm-name="nav: utility"
              >
                Account
                {!mobile && <Icon icon="underline" />}
              </a>
            </li>
          )}

          <li
            className={`user-menu__item user-menu__basket-handle ${
              basketState.isBasketOpen ? 'user-menu__basket-handle--open' : ''
            } ${
              basketState.hasDonations ||
              basketState.hasTickets ||
              basketState.hasMemberships ||
              basketState.basketItems.length > 0
                ? 'user-menu__basket-handle--active'
                : ''
            }`}
          >
            <button
              className="user-menu__link"
              disabled={
                !basketState.hasDonations &&
                !basketState.hasTickets &&
                !basketState.hasMemberships &&
                basketState.basketItems.length === 0
              }
              onClick={(event) => handleToggleBasketFn(event)}
              tabIndex={sticky ? -1 : 0}
              data-ga4-type="navigation"
              data-ga4-area="utility"
              data-gtm-name="nav: utility"
            >
              Basket
              {!mobile && <Icon icon="underline" />}
            </button>
            {basketState.isBasketOpen && (
              <Basket {...basketState} closeBasketFn={handleCloseBasketFn} hostnames={hostnames} />
            )}
          </li>
          <li className="user-menu__item">
            <a
              className="user-menu__link"
              href={routes.shop(hostnames.shop)}
              tabIndex={sticky ? -1 : 0}
              data-ga4-type="navigation"
              data-ga4-area="utility"
              data-gtm-name="nav: utility"
            >
              Shop
              {!mobile && <Icon icon="underline" />}
            </a>
          </li>
          <li
            className={`user-menu__item user-menu__search-handle ${searchOpen ? 'user-menu__search-handle--open' : ''}`}
          >
            <button
              className="user-menu__link"
              onClick={(event) => handleToggleSearchFn(event)}
              tabIndex={sticky ? -1 : 0}
              data-ga4-type="navigation"
              data-ga4-area="utility"
              data-gtm-name="nav: utility"
            >
              Search
              {!mobile && <Icon icon="underline" />}
            </button>
            {searchOpen && <SearchDropdown closeSearchDropdownFn={handleCloseSearchFn} hostnames={hostnames} />}
          </li>
        </ul>
      </nav>
      {/* // We need this for mobile devices */}
      {!userStatus.friend && (
        <div className="user-menu__friend">
          <Cta
            type="primary"
            label="Become a Friend"
            theme={theme}
            hideArrow={true}
            href={`${hostnames.website}/friends-of-the-royal-academy`}
            wide={true}
            gaTags={{ type: 'navigation', area: 'utility', name: 'nav: utility' }}
          ></Cta>
        </div>
      )}
    </div>
  );
}
