import React, { ReactElement } from 'react';
import { CtaProps } from '../Cta';
import './tertiaryCta.scss';
import { formatText } from '../../../util';

export default function TertiaryCta({ label, href, theme, onClick, gaTags, hideArrow }: CtaProps): ReactElement {
  return (
    <>
      {href && href.length > 0 ? (
        <a
          href={href}
          className={`tertiary-cta ${theme ? `tertiary-cta--${theme}` : ''}`}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          <span className="tertiary-cta__text">
            {formatText(label, 'tertiary-cta__label')}
            {!hideArrow && (
              <svg
                className="tertiary-cta__arrow"
                width="19"
                height="17"
                viewBox="0 0 19 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18.0132 7.88736C17.9545 7.62169 17.8215 7.37877 17.63 7.18772C17.4083 6.97587 17.1619 6.79231 16.8961 6.64113L16.4644 6.36783C15.9248 6.03441 15.4499 5.68459 14.9696 5.33477C14.0829 4.69446 13.2291 4.00849 12.4117 3.27957C11.5295 2.49937 10.6917 1.66903 9.9024 0.792563C9.61146 0.511609 9.26066 0.302154 8.87708 0.180376C8.67112 0.105136 8.46013 0.0448576 8.2457 0C8.09773 0.27294 8.02792 0.582299 8.04413 0.89325C8.06034 1.2042 8.16192 1.5044 8.33744 1.76004C8.51626 2.08057 8.73982 2.37328 9.0012 2.62912C9.0012 2.62912 9.20626 2.85323 9.57862 3.21944C9.95097 3.58566 10.4852 4.11586 11.1652 4.71711C11.8451 5.31837 12.66 5.99615 13.5827 6.67392C13.9425 6.93993 14.3203 7.20777 14.716 7.47742L13.3291 7.51568C12.0286 7.54848 10.3017 7.59767 8.56409 7.57581C5.0996 7.52661 1.54877 7.20959 1.24117 7.20412C0.669154 7.15493 0 7.85457 0 7.85457C0.0626139 8.22746 0.257034 8.56454 0.546982 8.80291C0.83693 9.04127 1.2026 9.16464 1.57575 9.15L3.57782 9.29211C4.78661 9.36317 6.42172 9.44516 8.1 9.46702C10.8791 9.46702 13.7123 9.35771 14.9372 9.28665L14.2411 9.98629L11.9422 12.3093C10.5608 13.7195 9.53005 14.769 9.24404 15.0806C8.11619 16.3213 8.43997 16.9171 8.43997 16.9171C9.27642 17.2724 10.4259 16.3705 10.4259 16.3705L12.6654 14.1404L14.9696 11.8392L16.2756 10.5384L16.9501 9.86604L17.4304 9.3905C17.6451 9.19203 17.8162 8.9499 17.9322 8.67993C18.0383 8.43027 18.0666 8.15374 18.0132 7.88736Z" />
              </svg>
            )}
          </span>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 172.3 5.5"
            className="tertiary-cta__line-wrapper"
            xmlSpace="preserve"
            preserveAspectRatio="none"
          >
            <g>
              <g>
                <g>
                  <path
                    d="M0,4.6c0,0,6.3-1.2,12.1-1.4C15.1,3,51.9,1.5,88.1,0.8C124.2,0,159.6,0,159.6,0s10.1,0.2,12.6,1.3c0,0-1.3,0.8-13,0.9
				c-5.9,0-41.4,0.2-75.5,1c-34,0.8-66.6,2.1-66.6,2.1s-3.5,0.2-7.5,0.2C5.8,5.5,1.5,5.4,0,4.6z"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </a>
      ) : (
        <>
          <button
            className={`tertiary-cta ${theme ? `tertiary-cta--${theme}` : ''}`}
            onClick={onClick}
            data-ga4-type={gaTags?.type}
            data-ga4-area={gaTags?.area}
            data-gtm-name={gaTags?.name}
          >
            <span className="tertiary-cta__text">
              {formatText(label, 'tertiary-cta__label')}
              {!hideArrow && (
                <svg
                  className="tertiary-cta__arrow"
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18.0132 7.88736C17.9545 7.62169 17.8215 7.37877 17.63 7.18772C17.4083 6.97587 17.1619 6.79231 16.8961 6.64113L16.4644 6.36783C15.9248 6.03441 15.4499 5.68459 14.9696 5.33477C14.0829 4.69446 13.2291 4.00849 12.4117 3.27957C11.5295 2.49937 10.6917 1.66903 9.9024 0.792563C9.61146 0.511609 9.26066 0.302154 8.87708 0.180376C8.67112 0.105136 8.46013 0.0448576 8.2457 0C8.09773 0.27294 8.02792 0.582299 8.04413 0.89325C8.06034 1.2042 8.16192 1.5044 8.33744 1.76004C8.51626 2.08057 8.73982 2.37328 9.0012 2.62912C9.0012 2.62912 9.20626 2.85323 9.57862 3.21944C9.95097 3.58566 10.4852 4.11586 11.1652 4.71711C11.8451 5.31837 12.66 5.99615 13.5827 6.67392C13.9425 6.93993 14.3203 7.20777 14.716 7.47742L13.3291 7.51568C12.0286 7.54848 10.3017 7.59767 8.56409 7.57581C5.0996 7.52661 1.54877 7.20959 1.24117 7.20412C0.669154 7.15493 0 7.85457 0 7.85457C0.0626139 8.22746 0.257034 8.56454 0.546982 8.80291C0.83693 9.04127 1.2026 9.16464 1.57575 9.15L3.57782 9.29211C4.78661 9.36317 6.42172 9.44516 8.1 9.46702C10.8791 9.46702 13.7123 9.35771 14.9372 9.28665L14.2411 9.98629L11.9422 12.3093C10.5608 13.7195 9.53005 14.769 9.24404 15.0806C8.11619 16.3213 8.43997 16.9171 8.43997 16.9171C9.27642 17.2724 10.4259 16.3705 10.4259 16.3705L12.6654 14.1404L14.9696 11.8392L16.2756 10.5384L16.9501 9.86604L17.4304 9.3905C17.6451 9.19203 17.8162 8.9499 17.9322 8.67993C18.0383 8.43027 18.0666 8.15374 18.0132 7.88736Z" />
                </svg>
              )}
            </span>

            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 172.3 5.5"
              className="tertiary-cta__line-wrapper"
              xmlSpace="preserve"
              preserveAspectRatio="none"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M0,4.6c0,0,6.3-1.2,12.1-1.4C15.1,3,51.9,1.5,88.1,0.8C124.2,0,159.6,0,159.6,0s10.1,0.2,12.6,1.3c0,0-1.3,0.8-13,0.9
				c-5.9,0-41.4,0.2-75.5,1c-34,0.8-66.6,2.1-66.6,2.1s-3.5,0.2-7.5,0.2C5.8,5.5,1.5,5.4,0,4.6z"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </>
      )}
    </>
  );
}
