import React from 'react';
import { ExplorerArtworkProps } from './ExplorerArtwork';

export default function ArtworkDetails({
  height,
  width,
  depth,
  medium,
  totalOfEdition,
  selectedOption,
  vatNumber,
  heightUnframed,
  widthUnframed,
  depthUnframed
}: Pick<
  ExplorerArtworkProps,
  | 'height'
  | 'width'
  | 'depth'
  | 'medium'
  | 'totalOfEdition'
  | 'vatNumber'
  | 'heightUnframed'
  | 'widthUnframed'
  | 'depthUnframed'
  | 'editionSoldFramed'
> & {
  selectedOption: 'edition' | 'exhibit';
}): React.ReactElement {
  const details =
    selectedOption === 'edition'
      ? {
          MEDIUM: medium,
          EDITION: `Edition of ${totalOfEdition}`,
          SIZE: `Unframed ${heightUnframed} x ${widthUnframed} x ${depthUnframed} cm, framed ${height} x ${width} x ${depth} cm`,
          // FRAME: editionSoldFramed
          //   ? 'Edition Framed, exhibited work framed'
          //   : 'Edition unframed, exhibited work framed',
          SELLER_VAT_NO: vatNumber
        }
      : {
          MEDIUM: medium,
          SIZE: `${height} x ${width} x ${depth} cm`,
          // FRAME: editionSoldFramed ? 'Edition Framed, exhibited work framed' : 'Edition unframed, exhibited work frame',
          SELLER_VAT_NO: vatNumber
        };

  const formatKey = (key: string): string =>
    key
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toUpperCase())
      .join(' ');

  return (
    <div className="explorer-artwork__details">
      <div className="explorer-artwork__details__heading">Artwork details</div>
      <dl>
        {Object.entries(details).map(([key, value]) => (
          <React.Fragment key={key}>
            <dt>{formatKey(key)}</dt>
            <dd>{value}</dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
}
