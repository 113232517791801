import React, { ReactElement } from 'react';
import Cta from '../../Cta/Cta';
import './artworkNoResult.scss';

export interface ArtworkNoResultProps {
  onReset: () => void;
}

export default function ArtworkNoResult({ onReset }: ArtworkNoResultProps): ReactElement {
  return (
    <section className="explorer-artwork-no-result">
      <h2 className="explorer-artwork-no-result__heading">No Results</h2>
      <p className="explorer-artwork-no-result__desc">
        There are no artworks that match your current filter selection.
      </p>
      <Cta type="tertiary" label="Reset filters" onClick={onReset} />
    </section>
  );
}
