import React, { ReactElement } from 'react';
import './image.scss';

export type PlaceholderImageProps = {
  ratio?: '4x3' | '1x1' | '16x9';
};

export default function PlaceholderImage({ ratio = '4x3' }: PlaceholderImageProps): ReactElement {
  return <div className={`placeholder-image placeholder-image${ratio}`}></div>;
}
