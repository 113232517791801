import { apiWithCredentials } from './config/axiosConfig';

export interface ExplorerApiProps {
  loggedInAsUser: boolean;
  reservationsCount: number;
}

export interface ReservationResponse {
  reservations: Array<{
    reservationId: number;
    artworkId: number;
    purchaserId: number;
    exhibited: boolean;
    createdOn: string;
    operator: string;
    catalogueNumber: number;
    title: string;
    isForSaleOnline: boolean;
    isForSaleOnlineLate: boolean;
    isExhibitForSaleOnline: boolean;
    exhibitedPrice: number;
    numberOfExhibitedForSale: number;
    numberOfExhibitedAvailable: number;
    numberOfExhibitedSold: number;
    numberOfExhibitedReserved: number;
    exhibitVatOnDeposit: number;
    exhibitDepositExVat: number;
    exhibitDeposit: number;
    exhibitPaymentToArtist: number;
    editionPrice: number;
    totalOfEdition: number;
    editionsSoldFramed: number;
    numberOfEditionsForSale: number;
    numberOfEditionsAvailable: number;
    numberOfEditionsSold: number;
    numberOfEditionsReserved: number;
    editionVatOnDeposit: number;
    editionDepositExVat: number;
    editionDeposit: number;
    editionPaymentToArtist: number;
    isVatRegistered: boolean;
    vatNumber: string | null;
    referToDesk: boolean;
    nfs: boolean;
    catalagueDisplayName: string;
  }>;
}

export const ExplorerApi = {
  get: async function (endpoint: string): Promise<ExplorerApiProps> {
    const response = await apiWithCredentials.request({
      url: endpoint,
      method: 'GET'
    });
    return response.data as ExplorerApiProps;
  },
  //eslint-disable-next-line
  post: async function (endpoint: string, data: any): Promise<ExplorerApiProps> {
    const response = await apiWithCredentials.request({
      url: endpoint,
      method: 'POST',
      //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: data
    });
    return response.data as ExplorerApiProps;
  },

  delete: async function (
    endpoint: string,
    data: { artworkId: number; reservationId: number }
  ): Promise<{ data: ReservationResponse }> {
    const response = await apiWithCredentials.request({
      url: endpoint,
      method: 'DELETE',
      data: data
    });
    return { data: response.data as ReservationResponse };
  }
};
