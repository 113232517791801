import React, { ReactElement } from 'react';
import './featured-promo.scss';
import Image, { ImageProps } from '../Image/Image';
import Cta from '../Cta/Cta';

import { ThemeProps } from '../../defaultProps';
import { formatAnchorName, formatText } from '../../util';
import RichText from '../RichText/RichText';

export interface FeaturedPromoProps {
  title?: string;
  anchorName?: string;
  subtitle?: string;
  date?: string;
  time?: string;
  description?: string;
  label: string;
  theme?: ThemeProps;
  alignment?: string;
  image: ImageProps;
  link?: string;
}

export default function FeaturedPromo({
  title,
  anchorName,
  subtitle,
  date,
  time,
  theme = 'theme--ra-light-purple',
  description,
  image,
  link
}: FeaturedPromoProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`featured-promo ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="featured-promo__container">
        <div className="featured-promo__grid">
          <div className="featured-promo__image">
            <Image {...image} />
          </div>
          <div className={` featured-promo__content ${theme}`}>
            <div className="featured-promo__inner">
              {title && (
                <h2 className="featured-promo__title">
                  {link ? (
                    <a href={link} data-ga4-type="feature" data-ga4-area="featured_exhibition">
                      {formatText(title)}
                      <span className="link-hotspot"></span>
                    </a>
                  ) : (
                    formatText(title)
                  )}
                </h2>
              )}
              {subtitle && <h3 className="featured-promo__subtitle">{subtitle}</h3>}
              {date && <p className="featured-promo__date">{date}</p>}
              {time && <p className="featured-promo__time">{time}</p>}
              {description && (
                <div className="featured-promo__description">
                  <RichText text={description} noGrid showMore={false} />
                </div>
              )}
              {link && (
                <div className="featured-promo__cta">
                  <Cta theme={theme} type="primary" label="Book now" href={link} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
