import React, { ReactElement } from 'react';
import { GALinkProps } from '../../../defaultProps';
import { ExplorerImageProps } from '../../ExplorerImage/ExplorerImage';
import Image from '../../ExplorerImage/ExplorerImage';
import './artwork-card.scss';
import formatCurrency, { formatText } from '../../../util';

export type ArtworkCardProps = {
  artistName: string;
  image: ExplorerImageProps;
  gaTags?: GALinkProps;
  link: string;
  headingTag?: 'h2' | 'h3';
  artworkTitle: string;
  price?: string;
  availableToBuyOnline: boolean;
  lozenge: string;
  categoryNum: number;
  edition: boolean;
} & {
  [key: string]: string | number | boolean | ExplorerImageProps | GALinkProps | undefined;
};

export default function ArtworkCard({
  artworkTitle,
  image,
  gaTags,
  headingTag = 'h2',
  link,
  artistName,
  price,
  categoryNum,
  edition
}: ArtworkCardProps): ReactElement {
  const HeadingTag = headingTag;
  return (
    <div className="artwork-card">
      <a
        className="artwork-card__link"
        href={link}
        data-ga4-type={gaTags?.type}
        data-ga4-area={gaTags?.area}
        data-gtm-name={gaTags?.name}
      >
        <div className="artwork-card__image">
          <Image {...image} />
        </div>
        <div className="artwork-card__content">
          {categoryNum && <p className="artwork-card__category">Cat No. {categoryNum}</p>}
          <HeadingTag className="artwork-card__title">{artistName}</HeadingTag>
          <p className="artwork-card__artist-name">{formatText(artworkTitle.toUpperCase())}</p>
          {price && (
            <p className="artwork-card__price">
              {edition && 'From '} {formatCurrency(price)}
            </p>
          )}
          {/* {lozenge && <div className="artwork-card__edition-available">{lozenge}</div>} */}
        </div>
      </a>
    </div>
  );
}
