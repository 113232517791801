import React, { ReactElement, useState } from 'react';
import './friend-address.scss';
import { Dropdown, Input } from '../../FormFields/FormFields';
import Cta from '../../Cta/Cta';
import { countries } from '../data/countries';
import { postcodeApi } from '../../../api/postcodeApi';
import { NewFriendProps } from '../FriendSignup';

export type FriendAddressProps = {
  addressSet: (b: boolean) => void;
  postcodeApiKey: string;
  newFriend: NewFriendProps;
};

export type Address = {
  label: string;
  value: number;
  rawLabel: string;
};

export type FriendAddressStateProps = {
  addressOptions: Address[];
  selectedAddress: string[];
  showDropDown: boolean;
  showFindAddress: boolean;
  showAddressFields?: boolean;
  addressValue?: string;
  postcode?: string;
  country?: number;
  showError?: boolean;
};

type AddressRowProps = { label: string; rawLabel: string; value: number };

export default function FriendAddress({ addressSet, postcodeApiKey, newFriend }: FriendAddressProps): ReactElement {
  const [addressState, setAddressState] = useState<FriendAddressStateProps>({
    addressOptions: [],
    selectedAddress: [],
    showDropDown: false,
    showFindAddress: true,
    country: 209 /* set default as uk */
  });
  const handleManualAddress = (): void => {
    setAddressState((prevState) => ({
      ...prevState,
      showAddressFields: true
    }));
  };

  const checkAddressOk = (selectedAddress: string[]): void => {
    if (selectedAddress[0] !== undefined && selectedAddress[5] !== undefined) {
      addressSet(true);
    } else {
      addressSet(false);
    }
  };

  const handleSelectCountry = (e: { target: HTMLSelectElement }): void => {
    setAddressState((prevState) => ({
      ...prevState,
      country: Number(e.target.value)
    }));
  };

  const handleSelectAddress = (e: { target: HTMLSelectElement }): void => {
    const selectedAddressRaw: AddressRowProps = addressState.addressOptions[Number(e.target.value)];
    const selectedAddress = selectedAddressRaw.rawLabel.split(',');

    checkAddressOk(selectedAddress);
    setAddressState((prevState) => ({
      ...prevState,
      addressValue: e.target.value,
      selectedAddress: selectedAddress,
      showAddressFields: true,
      showDropDown: false,
      showFindAddress: false
    }));
  };

  const handlePostcodeLookup = (e: React.MouseEvent): void => {
    e.preventDefault();
    const postcode = addressState.postcode && addressState.postcode.replace(/ /gi, '');
    if (postcode) {
      postcodeApi
        .get(postcodeApiKey, postcode)
        .then((data) => {
          if (data.addresses) {
            const addresses = data.addresses.map((address: string, index: number) => ({
              label: address.replace(/ , ,/gi, ''),
              value: index,
              rawLabel: address
            })) as Address[];
            setAddressState((prevState) => ({
              ...prevState,
              addressOptions: addresses,
              showDropDown: true
            }));
          }
        })
        .catch(() => {
          setAddressState((prevState) => ({
            ...prevState,
            showError: true
          }));
        });
    }
  };

  const setPostcode = (e: { target: HTMLInputElement }): void => {
    setAddressState((prevState) => ({
      ...prevState,
      showError: false,
      postcode: e.target.value.toUpperCase()
    }));
  };

  const setSelectedAddress = (e: { target: HTMLInputElement }, index: number): void => {
    const selectedAddress = addressState.selectedAddress;
    selectedAddress[index] = e.target.value;
    checkAddressOk(selectedAddress);
    setAddressState((prevState) => ({
      ...prevState,
      selectedAddress: selectedAddress
    }));
  };

  return (
    <div className="friend-address">
      <div className="friend-address__form">
        <div className="friend-address__form__row friend-address__form__row--postcode">
          {newFriend.showErrors && !newFriend.validAddress && (
            <div className="error-message">Please enter a valid address below</div>
          )}
          <Input
            type="text"
            id="find-address__postcode-lookup"
            value={addressState.postcode}
            name="tn-cust-field-param-gift_postal_code"
            label="Recipient postcode"
            required={!addressState.showAddressFields}
            onChange={(e) => {
              setPostcode(e);
            }}
            border={true}
            inlineError={
              addressState.showError
                ? "Couldn't find an address with that postcode - please try again or input manually"
                : ''
            }
          />
          {addressState.showFindAddress && <Cta type="secondary" label="Find address" onClick={handlePostcodeLookup} />}
        </div>
      </div>
      {addressState.addressOptions.length > 0 && addressState.showDropDown && (
        <div className="friend-address__form__row">
          <Dropdown
            id="find-address__city__address"
            label="Address"
            name="Addresses"
            value={addressState.addressValue ? addressState.addressValue : ''}
            options={addressState.addressOptions}
            searchable={true}
            clearable={false}
            onChange={handleSelectAddress}
            initialOption="Select address"
          />
        </div>
      )}
      {!addressState.showAddressFields && (
        <div className="friend-address__form__row">
          <Cta type="tertiary" label="Enter address manually" onClick={handleManualAddress}></Cta>
        </div>
      )}
      {addressState.showAddressFields && (
        <div>
          <div className="friend-address__form__row">
            <Input
              type="text"
              id="find-address__street1"
              value={addressState.selectedAddress[0]}
              name="tn-cust-field-param-gift_street1"
              label="Recipient address line 1"
              required={true}
              onChange={(e) => {
                setSelectedAddress(e, 0);
              }}
              border={true}
            />
          </div>
          <div className="friend-address__form__row">
            <Input
              type="text"
              id="find-address__street2"
              value={addressState.selectedAddress[1]}
              name="tn-cust-field-param-gift_street2"
              label="Recipient address line 2"
              onChange={(e) => {
                setSelectedAddress(e, 1);
              }}
              border={true}
            />
          </div>
          <div className="friend-address__form__row">
            <Input
              type="text"
              id="find-address__city"
              value={addressState.selectedAddress[5]}
              name="tn-cust-field-param-gift_city"
              label="City"
              required={true}
              onChange={(e) => {
                setSelectedAddress(e, 5);
              }}
              border={true}
            />
          </div>
          <div className="friend-address__form__row">
            <Input
              type="text"
              id="find-address__postal_code"
              value={addressState.postcode}
              name="tn-cust-field-param-gift_postal_code"
              label="Recipient Postcode"
              required={true}
              onChange={(e) => {
                setPostcode(e);
              }}
              border={true}
            />
          </div>
          <div className="friend-address__form__row">
            <Dropdown
              options={countries}
              id="find-address__city__country"
              name="tn-cust-field-param-gift_country"
              value={String(addressState.country)}
              label="Country"
              initialOption="Select country..."
              searchable={true}
              clearable={false}
              onChange={(e) => {
                handleSelectCountry(e);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
