import React, { useState, useCallback } from 'react';
import './explorer-artwork.scss';
import { HostnameProps } from '../../api/interface';
import ExplorerPurchase from './ExplorerPurchase';
import ExplorerOptions from './ExplorerOptions';
import ArtworkHeader from './ExplorerArtworkHeader';
import ArtworkDetails from './ExplorerDetails';
import { CtaProps } from '../Cta/Cta';
import PurchaseInfo from './ExplorerPurchaseInfo';
import { AccordionWrapperProps } from '../AccordionWrapper/AccordionWrapper';
import { ExplorerImageProps } from '../ExplorerImage/ExplorerImage';
import { ExplorerArtworkZoom } from './ExplorerArtworkZoom/ExplorerArtworkZoom';

export type LinkProps = {
  href: string;
  label: string;
};

export interface ExplorerArtworkProps {
  artworkId: number;
  catalogueNumber: string;
  title: string;
  medium: string;
  typeOfWork: string;
  height: number;
  width: number;
  depth: number;
  weight: number | null;
  isForSaleOnline: boolean;
  isForSaleOnlineLate: boolean;
  isExhibitForSaleOnline: boolean;
  exhibitedPrice: number;
  numberOfExhibitedSold: number;
  editionPrice: number;
  totalOfEdition: number;
  numberOfEditionsForSale: number;
  numberOfEditionsAvailable: number;
  numberOfEditionsReserved: number;
  referToDesk: boolean;
  catalogueDisplayName: string;
  image: ExplorerImageProps;
  description: string;
  onAddToBasket: (isExhibit: boolean) => void;
  hostnames: HostnameProps;
  vatNumber: number;
  heightUnframed: number;
  widthUnframed: number;
  depthUnframed: number;
  editionSoldFramed: boolean;
  cta: CtaProps;
  accordionWrapperProps: AccordionWrapperProps;
  paymentInfo: LinkProps;
  galleryLink: LinkProps;
  deposit: number;
  lastChance: boolean;
  availableAfterClose: boolean;
}

export default function ExplorerArtwork({
  catalogueNumber,
  title,
  description,
  medium,
  catalogueDisplayName,
  image,
  isExhibitForSaleOnline,
  exhibitedPrice,
  editionPrice,
  totalOfEdition,
  numberOfEditionsAvailable,
  height,
  width,
  depth,
  hostnames,
  artworkId,
  isForSaleOnline,
  numberOfExhibitedSold,
  isForSaleOnlineLate,
  vatNumber,
  heightUnframed,
  widthUnframed,
  depthUnframed,
  editionSoldFramed,
  cta,
  accordionWrapperProps,
  paymentInfo,
  galleryLink,
  deposit,
  lastChance,
  availableAfterClose
}: ExplorerArtworkProps & {
  hostnames: { api: string; tnew: string; explorer: string };
  accordionWrapperProps: AccordionWrapperProps;
}): React.ReactElement {
  const [selectedOption, setSelectedOption] = useState<'edition' | 'exhibit'>(
    isExhibitForSaleOnline ? 'exhibit' : 'edition'
  );

  const handleOptionChange = useCallback((option: 'edition' | 'exhibit') => {
    setSelectedOption(option);
  }, []);

  return (
    <div className="explorer-artwork">
      <div className="explorer-artwork__image">
        <ExplorerArtworkZoom {...image} />
      </div>
      <div className="explorer-artwork__info">
        <ArtworkHeader
          catalogueNumber={catalogueNumber}
          title={title}
          description={description}
          medium={medium}
          catalogueDisplayName={catalogueDisplayName}
          height={height}
          width={width}
          depth={depth}
          heightUnframed={heightUnframed}
          widthUnframed={widthUnframed}
          depthUnframed={depthUnframed}
          image={image}
          galleryLink={galleryLink}
        />
        <ExplorerOptions
          isExhibitForSaleOnline={isExhibitForSaleOnline}
          exhibitedPrice={exhibitedPrice}
          editionPrice={editionPrice}
          totalOfEdition={totalOfEdition}
          numberOfEditionsAvailable={numberOfEditionsAvailable}
          numberOfExhibitedSold={numberOfExhibitedSold}
          selectedOption={selectedOption}
          onOptionChange={handleOptionChange}
        />
        <ExplorerPurchase
          numberOfExhibitedSold={numberOfExhibitedSold}
          editionsForSale={numberOfEditionsAvailable > 0}
          editionsCopiesLeft={numberOfEditionsAvailable > 0}
          isExhibitForSaleOnline={isExhibitForSaleOnline}
          exhibitedPrice={exhibitedPrice}
          editionPrice={editionPrice}
          selectedOption={selectedOption}
          artworkId={artworkId}
          hostnames={hostnames}
          catalogueNumber={catalogueNumber}
          cta={cta}
          paymentInfo={paymentInfo}
          deposit={deposit}
          lastChance={lastChance}
          availableAfterClose={availableAfterClose}
          isForSaleOnline={isForSaleOnline}
          isForSaleOnlineLate={isForSaleOnlineLate}
        />
        <ArtworkDetails
          height={height}
          width={width}
          depth={depth}
          medium={medium}
          totalOfEdition={totalOfEdition}
          selectedOption={selectedOption}
          vatNumber={vatNumber}
          heightUnframed={heightUnframed}
          widthUnframed={widthUnframed}
          depthUnframed={depthUnframed}
          editionSoldFramed={editionSoldFramed}
        />
        <PurchaseInfo accordionWrapperProps={accordionWrapperProps} />
      </div>
    </div>
  );
}
