import axios, { AxiosError } from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    crossDomain?: boolean;
  }
}

export const apiWithCredentials = axios.create({
  withCredentials: true,
  crossDomain: true
});

export const apiWithoutCredentials = axios.create({ crossDomain: true });

const errorHandler = (error: AxiosError): Promise<AxiosError> => {
  const statusCode = error.response?.status;

  if (statusCode && statusCode !== 401) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return Promise.reject(error);
};

apiWithCredentials.interceptors.response.use(undefined, (error) => errorHandler(error as AxiosError));
