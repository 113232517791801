import React, { ReactElement, useEffect, useState } from 'react';
import { useMobile } from '../../hooks/mediaHook';
import { usePagination, DOTS } from '../../hooks/pagination';
import './pagination.scss';

export interface PaginationProps {
  onPageChange: (arg: number) => void;
  totalCount: number;
  currentPage: number;
  pageSize: number;
}

export default function Pagination({
  onPageChange,
  totalCount,
  currentPage,
  pageSize
}: PaginationProps): ReactElement | null {
  const [defaultSiblingCount, setDefaultSiblingCount] = useState(1);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: defaultSiblingCount,
    pageSize
  });

  const mobile = useMobile('768px', false);

  useEffect(() => {
    if (mobile) {
      setDefaultSiblingCount(-1);
    } else {
      setDefaultSiblingCount(1);
    }
  }, [mobile]);

  if (currentPage === 0 || (paginationRange ? paginationRange.length < 2 : null)) {
    return null;
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : null;

  return (
    <ul className="pagination">
      <li className="pagination__item" onClick={onPrevious}>
        <button
          className={`pagination__button pagination__button__left-arrow ${
            currentPage === 1 ? 'pagination__button__left-arrow--disabled' : ''
          }`}
          aria-label="Previous page"
          disabled={currentPage === 1}
        />
      </li>

      {paginationRange?.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination__dots" key={i}>
              &#8230;
            </li>
          );
        }
        return (
          <li className="pagination__item" onClick={() => onPageChange(pageNumber as number)} key={i}>
            <button
              className={`pagination__button ${pageNumber === currentPage ? 'pagination__button--selected' : ''}`}
              aria-label={`Page ${pageNumber}`}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}

      <li className="pagination__item" onClick={onNext}>
        <button
          className={`pagination__button pagination__button__right-arrow ${
            currentPage === lastPage ? 'pagination__button__right-arrow--disabled' : ''
          }`}
          aria-label="Next page"
          disabled={currentPage === lastPage}
        />
      </li>
    </ul>
  );
}
