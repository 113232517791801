import React, { ReactElement } from 'react';
import Icon from '../../Icons/Icons';
import './explorer-navigation.scss';
import { useMobile } from '../../../hooks/mediaHook';
import { ThemeProps } from '../../../defaultProps';
import { HostnameProps } from '../../../api/interface';
import { BaseUrl } from '../../../types';

export interface ExplorerHeaderLinkProps {
  label: string;
  slug: string;
  baseUrl: BaseUrl;
}

export type ExplorerExhibitionNavigationProps = {
  theme: ThemeProps;
  hostnames: HostnameProps;
  links: ExplorerHeaderLinkProps[];
};

export default function ExplorerExhibitionNavigation({
  links,
  theme
}: ExplorerExhibitionNavigationProps): ReactElement {
  const mobile = useMobile('1024px', true);

  const handleMenuClickFn = (slug: string, event: React.MouseEvent): void => {
    event.preventDefault();
    window.location.href = slug;
  };

  const linkOutputFn = (): ReactElement[] =>
    links.map((link: ExplorerHeaderLinkProps, index: number) => (
      <li className="explorer-navigation__link" key={`explorer-navigation__link--${index}`}>
        <button
          onClick={(event) => handleMenuClickFn(link.slug, event)}
          data-ga4-type="navigation"
          data-ga4-area="main_primary"
          data-gtm-name="nav: explorer exhibition"
        >
          {link.label}
          {!mobile && <Icon icon="underline" />}
          {mobile && <Icon icon="chevron-right" />}
        </button>
      </li>
    ));

  return (
    <nav className={`explorer-navigation ${theme}`} role="navigation" aria-label="Explorer Exhibition">
      <div className="explorer-navigation__container">
        <ul className="explorer-navigation__links">{linkOutputFn()}</ul>
      </div>
    </nav>
  );
}
