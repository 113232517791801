import React from 'react';
import ArtworkCard, { ArtworkCardProps } from '../Card/ArtworkCard/ArtworkCard';
import { artworkCardPropsArrayRandom } from '../../defaultProps';
import TertiaryCta from '../Cta/TertiaryCta/TertiaryCta';
import './explorer-selected-works.scss';

export interface SelectedWorksProps {
  artworks?: ArtworkCardProps[];
  title: string;
  categoryLink?: {
    href: string;
    label: string;
  };
}

export default function ExplorerSelectedWorks({
  artworks,
  categoryLink,
  title
}: SelectedWorksProps): React.ReactElement {
  const displayedArtworks = artworks || artworkCardPropsArrayRandom(8);

  return (
    <section className="explorer-selected-works">
      <div className="explorer-selected-works__header">
        <h2 className="explorer-selected-works__title">{title}</h2>
        <div className="explorer-selected-works__desktop-cta">
          {categoryLink && (
            <TertiaryCta href="/artworks" label={categoryLink.label} gaTags={{ type: 'ui', area: 'listings_card' }} />
          )}
        </div>
      </div>
      <div className="explorer-selected-works__grid">
        {displayedArtworks.map((artwork, index) => (
          <ArtworkCard key={index} {...artwork} gaTags={{ type: 'card', area: 'selected_works' }} />
        ))}
      </div>
      <div className="explorer-selected-works__mobile-cta">
        {categoryLink && (
          <TertiaryCta href="/artworks" label={categoryLink.label} gaTags={{ type: 'ui', area: 'listings_card' }} />
        )}
      </div>
    </section>
  );
}
