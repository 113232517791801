import React, { ReactElement } from 'react';

import './big-link.scss';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName, formatText } from '../../util';
import { LinkProps } from '../JumpLinks/JumpLinks';

export interface BigLinkProps {
  theme: ThemeProps;
  links?: LinkProps[];
  title?: string;
  anchorName?: string;
}

export default function BigLink({ title, links, theme, anchorName }: BigLinkProps): ReactElement {
  return (
    <div
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`big-link ${theme} ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="big-link__container">
        {title && <h2 className="big-link__title">{formatText(title)}</h2>}
        <ul className="big-link__list">
          {links &&
            links.map((link, index) => (
              <li className="big-link__list__item" key={`big-link__list-item--${index}`}>
                <a
                  className="big-link__link"
                  href={link.href}
                  key={`big-link__link--${index}`}
                  data-ga4-type={'feature'}
                  data-ga4-area={'big_link'}
                >
                  {link.label}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
