import React, { ReactElement } from 'react';
import { CtaProps } from '../Cta';
import './secondaryCta.scss';
import { formatText } from '../../../util';
import Icon from '../../Icons/Icons';

const buttonInnerFn = (label: string): ReactElement => (
  <>
    <div className="secondary-cta__inner">
      {formatText(label, 'secondary-cta__text')}
      {<Icon icon={'arrow-right'} />}
    </div>
  </>
);

export default function SecondaryCta({ label, href, theme, onClick, gaTags, modifier }: CtaProps): ReactElement {
  let white = false;
  switch (theme) {
    case 'theme--ra-blue':
    case 'theme--ra-pink':
    case 'theme--ra-purple':
    case 'theme--ra-green':
    case 'theme--ra-black':
      white = true;
  }
  return (
    <>
      {href && href.length > 0 ? (
        <a
          href={href}
          className={`secondary-cta ${white ? 'secondary-cta--white' : ''} ${
            modifier ? `secondary-cta--${modifier}` : ''
          }`}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          {buttonInnerFn(label)}
        </a>
      ) : (
        <button
          className={`secondary-cta ${white ? 'secondary-cta--white' : ''} ${
            modifier ? `secondary-cta--${modifier}` : ''
          }`}
          onClick={onClick}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          {buttonInnerFn(label)}
        </button>
      )}
    </>
  );
}
