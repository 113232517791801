import React, { ReactElement } from 'react';
import './home-hero.scss';
import { ImageProps } from '../Image/Image';

import Cta, { CtaProps } from '../Cta/Cta';
import Icon, { IconType } from '../Icons/Icons';
import { formatText } from '../../util';

export type VisitInfoItemProps = {
  title: string;
  description: string;
  descriptionOverride?: string;
  location?: string;
  icon: IconType;
};

export type HomeHeroProps = {
  promoTitle: string;
  promoSubtitle?: string;
  promoDate?: string;
  promoTime?: string;
  promoCta: CtaProps;
  promoBackgroundColor: string;
  promoTextColor?: string;
  promoImage: ImageProps;
  visitDescription: string;
  visitCta: CtaProps;
  visitBackgroundColor: string;
  visitTextColor?: string;
  visitInfoItems: VisitInfoItemProps[];
};

export default function HomeHero({
  promoTitle,
  promoSubtitle,
  promoDate,
  promoTime,
  promoCta,
  promoBackgroundColor,
  promoTextColor = 'white',
  promoImage,
  visitDescription,
  visitCta,
  visitBackgroundColor,
  visitTextColor = 'black',
  visitInfoItems
}: HomeHeroProps): ReactElement {
  const promoDefaultBg = promoTextColor === 'white' ? 'theme--ra-blue' : 'theme--ra-light-blue';
  const promoStyles: { [key: string]: React.CSSProperties } = {
    container: {
      backgroundColor: promoBackgroundColor
    }
  };
  const visitDefaultBg = visitTextColor === 'white' ? 'theme--ra-blue' : 'theme--ra-light-blue';
  const visitStyles: { [key: string]: React.CSSProperties } = {
    container: {
      backgroundColor: visitBackgroundColor
    }
  };

  const handleClick = (): void => {
    const promoBlock = document.querySelector('.home-hero__promo__content');
    const cta: HTMLButtonElement | null | undefined = promoBlock?.querySelector('.primary-cta');
    cta?.click();
  };

  const visitItems = (): ReactElement[] =>
    visitInfoItems.map((item, index: number) => {
      const itemDescription = item.descriptionOverride ? item.descriptionOverride : item.description;
      return (
        <div
          className={`info-items__item ${item.icon ? `info-items__item--icon info-items__item--${item.icon}` : ''}`}
          key={`info-items__item--${index}`}
        >
          <Icon icon={item.icon} />
          <h2 className="info-items__title">{item.title}</h2>
          <div className="info-items__description">
            {item.location ? (
              <a
                className="info-items__location"
                href={item.location}
                target="_blank"
                rel="noreferrer"
                data-ga4-type="ui"
                data-ga4-area="main_hero"
              >
                {formatText(itemDescription)}
              </a>
            ) : (
              formatText(itemDescription)
            )}
          </div>
        </div>
      );
    });

  return (
    <section className="home-hero">
      <div className="home-hero__container">
        <div className="home-hero__promo">
          <div className="home-hero__promo__grid">
            <div className="home-hero__promo__image" style={{ backgroundImage: `url(${promoImage.src.large})` }} />
            <div
              className={` home-hero__promo__content ${promoDefaultBg}`}
              style={promoStyles.container}
              onClick={handleClick}
            >
              <div className="home-hero__promo__inner">
                <h2 className="home-hero__promo__title">{formatText(promoTitle)}</h2>
                {promoSubtitle && <h3 className="home-hero__promo__subtitle">{promoSubtitle}</h3>}
                {promoDate && <p className="home-hero__promo__date">{promoDate}</p>}
                {promoTime && <p className="home-hero__promo__time">{promoTime}</p>}
                <Cta
                  {...promoCta}
                  theme={promoDefaultBg}
                  type={'primary'}
                  gaTags={{ type: 'cta', area: 'main_hero' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`home-hero__visit ${visitDefaultBg}`} style={visitStyles.container}>
          <h1 className="home-hero__visit__title">
            <span className="sr-only">Royal Academy of Arts</span>
            <Icon icon="ra-title-logo" />
          </h1>
          <div className="home-hero__visit__grid">
            <div className="home-hero__visit__info-items">{visitItems()}</div>
            <div className="home-hero__visit__about">
              <p className="home-hero__visit__description">{visitDescription}</p>
              <Cta {...visitCta} theme={visitDefaultBg} type={'tertiary'} gaTags={{ type: 'ui', area: 'main_hero' }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
