import React, { ReactElement, useState, useEffect, useCallback, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import FocusTrap from 'focus-trap-react';
import Cta, { CtaProps } from '../Cta/Cta';
import ImageCard from '../Card/ImageCard/ImageCard';
import ImagePopUp, { ImagePopUpProps } from './ImagePopUp/ImagePopUp';
import '../Carousel/carousel.scss';
import './imageGallery.scss';
import { formatAnchorName } from '../../util';
import Icon from '../Icons/Icons';

export type ImageGalleryProps = {
  title?: string;
  anchorName?: string;
  cards: ImagePopUpProps[];
  cta?: CtaProps;
  color?: string;
};

export default function ImageGallery({ title, anchorName, cards, cta }: ImageGalleryProps): ReactElement {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps'
  });
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [cardIndex, setCardIndex] = useState<number>(0);
  const [prevButtonEnabled, setPrevButtonEnabled] = useState<boolean>();
  const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>();
  const [restrictHeight, setRestrictHeight] = useState<boolean>(false);
  const cardList = useRef<HTMLUListElement>(null);

  const openModal = useCallback((): void => {
    document.body.classList.add('no-scroll');
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  const closeModal = useCallback((): void => {
    document.body.classList.remove('no-scroll');
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  const onSelect = useCallback((): void => {
    if (!emblaApi) return;
    setPrevButtonEnabled(emblaApi.canScrollPrev());
    setNextButtonEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setNextButtonEnabled, setPrevButtonEnabled]);

  const checkImageHeights = useCallback(() => {
    if (cardList.current && !restrictHeight) {
      const elements: HTMLImageElement[] = Array.from(cardList.current.querySelectorAll('.image-card img'));
      elements.map((item) => {
        if (item.offsetHeight > 420) setRestrictHeight(true);
      });
    }
    // We only want this to run once
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    checkImageHeights();
    emblaApi.reInit();
    emblaApi.scrollTo(cardIndex);
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect, cardIndex, checkImageHeights]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const popUpScrollPrev = useCallback(() => {
    setCardIndex((prev) => prev - 1);
  }, [setCardIndex]);

  const popUpScrollNext = useCallback(() => {
    setCardIndex((prev) => prev + 1);
  }, [setCardIndex]);

  const onCardClick = useCallback(
    (index: number) => {
      if (emblaApi?.clickAllowed()) {
        setCardIndex(index);
        openModal();
      }
    },
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    [emblaApi, setCardIndex, openModal]
  );

  const imageOnLoad = (): void => {
    checkImageHeights();
  };

  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`image-gallery ${modalIsOpen ? 'image-gallery--modal-open' : ''} ${anchorName ? 'ra--offset' : ''}`}
    >
      {modalIsOpen && (
        <FocusTrap>
          <div className="image-gallery__modal">
            <ImagePopUp
              {...cards[cardIndex]}
              onClickPrev={cardIndex !== 0 ? popUpScrollPrev : undefined}
              onClickNext={cardIndex !== cards.length - 1 ? popUpScrollNext : undefined}
              onClose={() => closeModal()}
            />
          </div>
        </FocusTrap>
      )}
      <div className="image-gallery__carousel">
        <div className="image-gallery__top">
          {title && <h2 className="image-gallery__title">{title}</h2>}
          {cards.length > 3 && (
            <div className="image-gallery__nav">
              <button
                className={`image-gallery__button image-gallery__button--prev ${
                  prevButtonEnabled ? 'theme--ra-blue' : ''
                }`}
                onClick={scrollPrev}
                disabled={!prevButtonEnabled}
                data-ga4-type="ui"
                data-ga4-area="image_gallery"
              >
                <span className="sr-only">previous</span>
                <Icon icon="arrow-left" />
              </button>
              <button
                className={`image-gallery__button image-gallery__button--next ${
                  nextButtonEnabled ? 'theme--ra-blue' : ''
                }`}
                onClick={scrollNext}
                disabled={!nextButtonEnabled}
                data-ga4-type="ui"
                data-ga4-area="image_gallery"
              >
                <span className="sr-only">next</span>
                <Icon icon="arrow-right" />
              </button>
            </div>
          )}
        </div>
        <div className="image-gallery__embla" ref={emblaRef}>
          <ul
            className={`image-gallery__list ${restrictHeight ? 'image-gallery__list--restrict-height' : ''}`}
            ref={cardList}
          >
            {cards.map((card, idx) => (
              <li className="image-gallery__card" key={idx}>
                <ImageCard {...card} onClick={() => onCardClick(idx)} imageOnLoad={imageOnLoad} />
              </li>
            ))}
          </ul>
        </div>
        {cta && (
          <div className="image-gallery__cta">
            <Cta {...cta} gaTags={{ type: 'ui', area: 'image_gallery' }} />
          </div>
        )}
      </div>
    </section>
  );
}
