import React, { ReactElement } from 'react';
import './error.scss';
import { formatText } from '../../util';

export interface ErrorProps {
  title: string;
  description: string;
}

export default function Error({ title, description }: ErrorProps): ReactElement {
  return (
    <section className="error">
      <div className="error__container">
        <h1 className="error__title">{title}</h1>
        <div className="error__description">{formatText(description)}</div>
      </div>
    </section>
  );
}
