import React, { ReactElement, useCallback, useState } from 'react';
import { HostnameProps } from '../../api/interface';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName, formatText } from '../../util';
import Cta from '../Cta/Cta';
import { Input } from '../FormFields/FormFields';
import Image, { ImageProps } from '../Image/Image';

import './donate.scss';

export type AmountsProps = {
  value: string;
  desciption: string;
  image: ImageProps;
  default?: boolean;
};

export type DonateProps = {
  hostnames: HostnameProps;
  title: string;
  anchorName?: string;
  description: string;
  amounts: AmountsProps[];
  theme?: ThemeProps;
  defaultValue?: 1 | 2 | 3;
};

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

export default function Donate({
  hostnames,
  title,
  anchorName,
  description,
  theme = 'theme--ra-blue',
  amounts,
  defaultValue = 1
}: DonateProps): ReactElement {
  const [errorMsg] = useState(false);

  const [selectedValue, updateSelectedValue] = useState({
    about: amounts[defaultValue - 1].desciption,
    value: amounts[defaultValue - 1].value
  });

  const updateAmountFn = (event: React.SyntheticEvent, obj: AmountsProps): void => {
    event.preventDefault();
    updateSelectedValue({ about: obj.desciption, value: obj.value });
  };

  const updateOtherAmountFn = (event: React.SyntheticEvent): void => {
    updateSelectedValue({ about: selectedValue.about, value: (event.target as HTMLInputElement).value });
  };

  const onOtherAmountChange = (event: React.SyntheticEvent): void => {
    // Enforce input minimum value
    const inputEl = event.target as HTMLInputElement;
    if (inputEl.value != '' && parseInt(inputEl.value) < parseInt(inputEl.min)) {
      inputEl.value = '';
    } else {
      updateOtherAmountFn(event);
    }
  };

  const amountsOutputFn = (amountsArray: AmountsProps[], button_color: ThemeProps): ReactElement[] =>
    amountsArray.map((amount: AmountsProps, index: number) => (
      <div className="donate__amount__item" key={index}>
        <Cta
          label={`£${amount.value}`}
          theme={button_color}
          hideArrow={true}
          selected={selectedValue.value == amount.value}
          onClick={(event: React.SyntheticEvent) => updateAmountFn(event, amount)}
          gaTags={{ type: 'ui', area: 'donate' }}
        />
      </div>
    ));

  const imagesOutputFn = (amountsArray: Array<AmountsProps>): ReactElement => {
    const matchingAmount = amountsArray.find((amount) => amount.value === selectedValue.value);
    return (
      <div className="donate__image__item">
        <Image {...(matchingAmount?.image ?? amountsArray[1].image)} />
      </div>
    );
  };

  const completeDonation = useCallback((): void => {
    window.location.href = `${hostnames.tnew}/donate/i/1?amount=${Number(selectedValue.value)}`;
  }, [hostnames.tnew, selectedValue.value]);

  const submitFormFn = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'donate',
      'data-ga4-type': 'cta',
      'data-ga4-area': 'donate',
      'data-ga4-amount': selectedValue.value
    });
    completeDonation();
  };

  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`donate ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="donate__container">
        <div className="donate__grid">
          <div className="donate__image">{imagesOutputFn(amounts)}</div>
          <div className={` donate__content ${theme}`}>
            <div className="donate__inner">
              <h2 className="donate__title">{formatText(title)}</h2>
              {description && <div className="donate__description">{formatText(description)}</div>}
              <form>
                <fieldset>
                  <div className="donate__wrapper">
                    <div className="donate__amounts">{amountsOutputFn(amounts, theme)}</div>
                    <div className="donate__about">{selectedValue.about}</div>
                    <div className="donate__other">
                      <div className="donate__other__field">
                        <Input
                          label={'Other amount'}
                          type={'number'}
                          id={'donate__other'}
                          field_type={'inline-half'}
                          onChange={onOtherAmountChange}
                          min={1}
                        />
                        <div className="donate__other__button">
                          <Cta
                            label={'Donate now'}
                            theme={theme}
                            type={'secondary'}
                            onClick={(event) => submitFormFn(event)}
                            gaTags={{ type: 'cta', area: 'donate' }}
                          />
                        </div>
                        {errorMsg && (
                          <div className="donate__error-message">
                            <p>Sorry, we&apos;re unable to process your donation right now.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
