import React, { ReactElement, useEffect, useState } from 'react';
import './listingInfo.scss';

export interface ListingInfoProps<T> {
  results: T[];
  pageSize: number;
  resultsTotal: number;
  currentPage: number;
}

export default function ListingInfo<T>({
  currentPage,
  results,
  pageSize,
  resultsTotal
}: ListingInfoProps<T>): ReactElement {
  const [resultsFrom, setResultsFrom] = useState<number>();
  const [resultsTo, setResultsTo] = useState<number>();

  const startPage = currentPage - 1;

  useEffect(() => {
    setResultsFrom(startPage * pageSize + 1);
    setResultsTo(Math.min(startPage * pageSize + 1 + pageSize - 1, resultsTotal));
    return () => {};
  }, [startPage, pageSize, results, results.length, resultsTotal]);

  return (
    <div className="listing-info">
      <p className="listing-info__info">
        <span className="listing-info__strong">
          {resultsFrom} - {resultsTo}
        </span>{' '}
        of <span className="listing-info__strong">{resultsTotal}</span> results found
      </p>
    </div>
  );
}
