/* eslint-disable react/no-unescaped-entities */
import React, { ReactElement } from 'react';
import { ThemeProps } from '../../../defaultProps';
import { Input, Checkbox } from '../../FormFields/FormFields';

export interface InterestFieldValues {
  firstName: string;
  lastName: string;
  email: string;
  interests: string;
}

export type Option = {
  label: string;
  value: string;
};

interface InterestFieldsProps {
  fieldValues: InterestFieldValues;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  interests: Option[];
  theme?: ThemeProps;
}

export default function InterestFields({ fieldValues, onChange, interests, theme }: InterestFieldsProps): ReactElement {
  return (
    <>
      <div className="newsletter__two-sections">
        <Input
          label="First name"
          id="firstName"
          type="text"
          name="firstName"
          value={fieldValues.firstName ?? ''}
          required
          onChange={onChange}
        />
        <Input
          label="Last name"
          id="lastName"
          type="text"
          name="lastName"
          value={fieldValues.lastName ?? ''}
          required
          onChange={onChange}
        />
      </div>
      <Input
        label="Email"
        id="email"
        type="email"
        name="email"
        value={fieldValues.email ?? ''}
        required
        onChange={onChange}
      />
      <fieldset className="newsletter__fieldset">
        <legend className="newsletter__legend">I'm also interested in</legend>
        <div className="newsletter__checkbox-group">
          {interests.map((p) => (
            <Checkbox
              key={`interest-${p.value}`}
              type="checkbox"
              name="interests"
              label={p.label}
              value={p.value}
              checked={fieldValues.interests?.split(',').includes(`${p.value}:Y`) ?? false}
              onChange={onChange}
              id={`interest-${p.value}`}
              theme={theme}
            />
          ))}
        </div>
      </fieldset>
    </>
  );
}
