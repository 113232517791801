import React, { ReactElement } from 'react';
import { CtaProps } from '../Cta/Cta';
import InlineImage, { InlineImageProps } from '../InlineImage/InlineImage';
import RichText from '../RichText/RichText';
import './articleHalfHalf.scss';
import { formatAnchorName } from '../../util';

export interface ArticleHalfHalfProps {
  image: InlineImageProps;
  anchorName?: string;
  text: string;
  alignment: 'left' | 'right';
  cta?: CtaProps;
}

export default function ArticleHalfHalf({
  image,
  anchorName,
  text,
  cta,
  alignment
}: ArticleHalfHalfProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`article-half-half article-half-half--${alignment} ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="article-half-half__container">
        <div className="article-half-half__grid">
          <div className="article-half-half__image">
            <InlineImage {...image} />
          </div>
          <div className="article-half-half__text">
            <RichText text={text} cta={cta} noGrid={true} showMore={false} />
          </div>
        </div>
      </div>
    </section>
  );
}
