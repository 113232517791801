import React from 'react';
import { AccordionWrapperProps } from '../AccordionWrapper/AccordionWrapper';
import AccordionWrapper from '../AccordionWrapper/AccordionWrapper';

export default function PurchaseInfo({
  accordionWrapperProps
}: {
  accordionWrapperProps: AccordionWrapperProps;
}): React.ReactElement {
  return (
    <div className="explorer-artwork__purchase-info">
      <AccordionWrapper {...accordionWrapperProps} />
    </div>
  );
}
