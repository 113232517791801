import React, { useState, useCallback } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import ExplorerImage, { ExplorerImageProps } from '../../ExplorerImage/ExplorerImage';
import Icon from '../../Icons/Icons';
import './explorer-artwork-zoom.scss';

export const ExplorerArtworkZoom: React.FC<ExplorerImageProps> = (props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [scale, setScale] = useState(0.6);
  const minScale = 0.5;
  const maxScale = 3;
  const zoomIncrement = 0.5;

  const handleFullscreenToggle = useCallback(() => {
    setIsFullscreen((prevState) => !prevState);
    setScale(0.7);
  }, []);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent) => {
      e.preventDefault();
      handleFullscreenToggle();
    },
    [handleFullscreenToggle]
  );

  return (
    <div className="image-viewer">
      <div onClick={handleFullscreenToggle} onTouchStart={handleTouchStart} className="image-viewer__thumbnail">
        <ExplorerImage {...props} />
      </div>
      {isFullscreen && (
        <div className="image-viewer__fullscreen">
          <TransformWrapper
            initialScale={0.6}
            minScale={minScale}
            maxScale={maxScale}
            centerOnInit={true}
            limitToBounds={true}
            centerZoomedOut={true}
            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
            zoomAnimation={{ size: 3 }}
            onZoom={({ state }) => {
              setScale(state.scale);
            }}
          >
            {(ref: ReactZoomPanPinchContentRef) => (
              <>
                <TransformComponent wrapperClass="image-viewer__fullscreen-content">
                  <ExplorerImage {...props} />
                </TransformComponent>
                <div className="zoom-controls">
                  <button
                    onClick={() => {
                      const newScale = Math.max(scale - zoomIncrement, minScale);
                      setScale(newScale);
                      ref.setTransform(0, 0, newScale);
                    }}
                    className="zoom-control-button"
                  >
                    <Icon icon="zoom-out" />
                  </button>
                  <input
                    type="range"
                    min={minScale}
                    max={maxScale}
                    step="0.1"
                    value={scale}
                    onChange={(e) => {
                      const newScale = parseFloat(e.target.value);
                      setScale(newScale);
                      ref.setTransform(0, 0, newScale);
                    }}
                  />
                  <button
                    onClick={() => {
                      const newScale = Math.min(scale + zoomIncrement, maxScale);
                      setScale(newScale);
                      ref.setTransform(0, 0, newScale);
                    }}
                    className="zoom-control-button"
                  >
                    <Icon icon="zoom-in" />
                  </button>
                </div>
                <button onClick={handleFullscreenToggle} className="image-viewer__close">
                  X
                </button>
              </>
            )}
          </TransformWrapper>
        </div>
      )}
    </div>
  );
};
