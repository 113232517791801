import React, { useState, useEffect, useRef, ReactElement } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import Image, { ImageProps } from '../Image/Image';
import HeroCaption, { HeroCaptionProps } from '../HeroCaption/HeroCaption';
import './hero-carousel.scss';
import Icon from '../Icons/Icons';

export type HeroCarouselSlide = {
  image: ImageProps;
  caption?: HeroCaptionProps;
};

export type HeroCarouselProps = {
  slides: HeroCarouselSlide[];
};

export default function HeroCarousel({ slides }: HeroCarouselProps): ReactElement {
  const autoplay = useRef(Autoplay({ delay: 5000, stopOnInteraction: false }));
  const carouselOptions = { loop: true, inViewThreshold: 1, speed: 50 };
  const [emblaRef, emblaCarousel] = useEmblaCarousel(carouselOptions, [autoplay.current]);
  const [autoplayPause, setAutoplayPause] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (autoplay.current) {
      if (autoplayPause) {
        autoplay.current.stop();
        return;
      }
      if (!autoplayPause) {
        autoplay.current.reset();
      }
    }
  }, [autoplayPause]);

  useEffect(() => {
    if (emblaCarousel) {
      emblaCarousel.on('settle', () => setActiveIndex(emblaCarousel.selectedScrollSnap()));
    }
  }, [emblaCarousel]);

  const activeSlide = slides[activeIndex];

  return (
    <div className="hero-carousel">
      <div className="hero-carousel__container">
        <div className="hero-carousel__grid">
          <div className="hero-carousel__viewport" ref={emblaRef}>
            <div className="hero-carousel__wrapper">
              {slides.map((slide, index) => (
                <div
                  className={`hero-carousel__slide ${index === activeIndex ? 'hero-carousel__slide--active' : ''}`}
                  key={index}
                >
                  <div className="hero-carousel__image-wrapper">
                    <Image {...slide.image} />
                    <div className="hero-carousel__pause-wrapper">
                      <button
                        className="hero-carousel__pause-btn"
                        onClick={() => setAutoplayPause(!autoplayPause)}
                        tabIndex={index === activeIndex ? 0 : -1}
                      >
                        {!autoplayPause && <Icon icon="pause" />}
                        {autoplayPause && <Icon icon="play" />}
                        <span className="sr-only">Pause Hero carousel</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {activeSlide.caption && <HeroCaption {...activeSlide.caption} />}
        </div>
      </div>
    </div>
  );
}
