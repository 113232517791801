import React from 'react';
import { ExplorerArtworkProps } from './ExplorerArtwork';
import { ExplorerArtworkZoom } from './ExplorerArtworkZoom/ExplorerArtworkZoom';

export default function ArtworkHeader({
  catalogueNumber,
  title,
  medium,
  catalogueDisplayName,
  height,
  width,
  depth,
  heightUnframed,
  widthUnframed,
  depthUnframed,
  image,
  galleryLink
}: Pick<
  ExplorerArtworkProps,
  | 'catalogueNumber'
  | 'title'
  | 'description'
  | 'medium'
  | 'catalogueDisplayName'
  | 'height'
  | 'width'
  | 'depth'
  | 'heightUnframed'
  | 'widthUnframed'
  | 'depthUnframed'
  | 'image'
  | 'galleryLink'
>): React.ReactElement {
  return (
    <>
      <section className="explorer-artwork__mobile-header">
        <div className="explorer-artwork__mobile-header__category">Cat no. {catalogueNumber}</div>
        <div className="explorer-artwork__mobile-header__displayname">{catalogueDisplayName}</div>
        <div className="explorer-artwork__mobile-header__description">{title}</div>
        <div className="explorer-artwork__image_mobile">
          <ExplorerArtworkZoom {...image} />
        </div>
        <div className="explorer-artwork__mobile-header__description">{medium}</div>
        <div className="explorer-artwork__mobile-header__description">
          Unframed: {heightUnframed} x {widthUnframed} x {depthUnframed} cm, Framed: {height} x {width} x {depth} cm
        </div>
        <div className="explorer-artwork__mobile-header__gallery-description">
          On display in&nbsp;<a href={galleryLink.href}>{galleryLink.label} </a>
        </div>
      </section>

      <section className="explorer-artwork__header">
        <div className="explorer-artwork__category">Cat no. {catalogueNumber}</div>
        <div className="explorer-artwork__header__displayname">{catalogueDisplayName}</div>
        <div className="explorer-artwork__description">{title}</div>
        <div className="explorer-artwork__description">{medium}</div>
        <div className="explorer-artwork__description">
          Unframed: {heightUnframed} x {widthUnframed} x {depthUnframed} cm, Framed: {height} x {width} x {depth} cm
        </div>
        <div className="explorer-artwork__description">
          On display in&nbsp;<a href={galleryLink.href}>{galleryLink.label} </a>
        </div>
      </section>
    </>
  );
}
