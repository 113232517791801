import React, { ReactElement, useEffect, useMemo, useState, useRef } from 'react';
import EventCard, { EventCardProps } from '../../Card/EventCard/EventCard';
import Pagination from '../../Pagination/Pagination';
import ListingInfo from '../../ListingInfo/ListingInfo';
import './whatsOnListing.scss';
export interface WhatsOnListingProps {
  data: EventCardProps[];
  pageSize: number;
  pageNumber: number;
  onPageChange?: (args: number) => void;
  setPageNumber: (args: number) => void;
}

export default function WhatsOnListing({
  data,
  pageSize,
  pageNumber,
  setPageNumber
}: WhatsOnListingProps): ReactElement {
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, pageSize]);

  const listingRef = useRef<HTMLElement | null>(null);

  function handlePageChange(page: number): void {
    setPageNumber(page);
    setCurrentPage(page);

    if (listingRef.current) {
      const topDist = listingRef.current.getBoundingClientRect().top + window.pageYOffset - 92;
      setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: topDist });
      }, 1);
    }
  }

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [data, pageNumber, setCurrentPage]);

  return (
    <section ref={listingRef} className="whats-on-listing">
      <ListingInfo currentPage={currentPage} pageSize={pageSize} results={currentData} resultsTotal={data.length} />
      <ul className="whats-on-listing__list">
        {currentData.map((event: EventCardProps, i) => (
          <li className="whats-on-listing__item" key={i}>
            <EventCard {...event} gaTags={{ type: 'card', area: 'listings_card' }} />
          </li>
        ))}
      </ul>
      <Pagination
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={pageSize}
        onPageChange={(page) => handlePageChange(page)}
      />
    </section>
  );
}
