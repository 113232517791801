import React, { ReactElement } from 'react';
import Accordion from '../Accordion/Accordion';
import Image, { ImageProps } from '../Image/Image';
import './inlineImage.scss';
import { formatAnchorName } from '../../util';

export interface InlineImageProps {
  image: ImageProps;
  captionTitle?: string;
  captionDescription?: string;
  copyright?: string;
  container?: boolean;
  hideCaption?: boolean;
  anchorName?: string;
}

export default function InlineImage({
  image,
  anchorName,
  captionTitle,
  captionDescription,
  copyright,
  container = false,
  hideCaption = false
}: InlineImageProps): ReactElement {
  return (
    <div
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`inline-image ${container ? 'inline-image--container' : ''} ${anchorName ? 'ra--offset' : ''}`}
    >
      <figure className={`inline-image__figure ${container ? 'inline-image__figure--grid' : ''}`}>
        <Image {...image} />
        {!hideCaption && captionTitle && (
          <figcaption className="inline-image__fig-caption">
            {captionDescription || copyright ? (
              <Accordion
                heading={captionTitle}
                body={captionDescription ? captionDescription : ''}
                copyright={copyright}
                preview
              />
            ) : (
              <span className="inline-image__fig-caption__title">{captionTitle}</span>
            )}
          </figcaption>
        )}
      </figure>
    </div>
  );
}
