import { useEffect, RefObject } from 'react';

export const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (e: Event) => void): void => {
  useEffect(() => {
    const listener = (e: Event): void => {
      if (!ref.current || ref.current.contains(e.target as Node)) return;
      handler(e);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
