import { apiWithCredentials } from './config/axiosConfig';

export interface SessionExplorerApiProps {
  loggedInAsUser: boolean;
  reservationsCount: number;
}

export const SessionExplorerApi = {
  get: async function (endpoint: string): Promise<SessionExplorerApiProps> {
    const response = await apiWithCredentials.request({
      url: endpoint,
      method: 'GET'
    });
    return response.data as SessionExplorerApiProps;
  }
};
