import { isBrowser } from '../util';
import { useState, useEffect } from 'react';

export enum SessionStorageKey {
  AlertBanners = 'ra_hidden_banners',
  User = 'ra_session_user',
  Explorer = 'ra_explorer_user'
}

function getSessionStorageOrDefault<T>(key: SessionStorageKey, defaultValue: T): T | undefined {
  if (!isBrowser) return defaultValue;
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  try {
    return JSON.parse(stored) as T;
  } catch (e) {
    return undefined;
  }
}

export function UseSessionStorage<T>(
  key: SessionStorageKey,
  defaultValue: T | undefined
): [T | undefined, (value: T) => void] {
  const [value, setValue] = useState(getSessionStorageOrDefault(key, defaultValue));

  useEffect(() => {
    if (value !== undefined && isBrowser) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
}
