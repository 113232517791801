import React, { ReactElement, useEffect, useState } from 'react';
import { ThemeProps } from '../../defaultProps';
import { formatText } from '../../util';
import Icon from '../Icons/Icons';

import './alert-banner.scss';
import { SessionStorageKey, UseSessionStorage } from '../../hooks/sessionStorage';
import dayjs from 'dayjs';

export interface AlertBannerProps {
  id: number;
  type: 'high' | 'medium' | 'low';
  theme: ThemeProps;
  text: string;
  close: boolean;
}

export default function AlertBanner({ id, text, type, theme, close = true }: AlertBannerProps): ReactElement {
  // Get the session storage values for hidden alert banners
  const [hiddenAlertBanners, setHiddenAlertBanner] = UseSessionStorage<string[]>(SessionStorageKey.AlertBanners, []);

  // Set the state of the alert banner to hidden at first
  const [showAlertBanner, updateShowAlertBanner] = useState(false);

  // Concat the current date with the alert text to hide the alert for the day
  const alert = dayjs().format('YYYY-MM-DD-').concat(String(id));

  // When the component mounts, check if the (hashed) alert is in the hidden alert banners array
  useEffect(() => {
    // If it is not in the array, show the alert banner
    updateShowAlertBanner(!hiddenAlertBanners?.includes(alert));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the alert banner is closed, add it to the hidden alert banners array
  const closeAlertFn = (): void => {
    // Add the alert to the hidden alerts array, and hide the alert banner
    setHiddenAlertBanner(hiddenAlertBanners?.concat([alert]) ?? [alert]);
    updateShowAlertBanner(false);
  };

  return (
    <div
      className={`alert-banner ${theme} alert-banner--${type} ${close ? 'alert-banner--with-button' : ''}`}
      style={{ display: showAlertBanner ? 'block' : 'none' }}
    >
      <div className="alert-banner__inner">
        <h3 className="alert-banner__text">{formatText(text)}</h3>
        {close && (
          <button onClick={() => closeAlertFn()} className="alert-banner__close">
            <Icon icon="close" />
            <span className="sr-only">Close</span>
          </button>
        )}
      </div>
    </div>
  );
}
