import React, { ReactElement } from 'react';
import Image, { ImageProps } from '../Image/Image';
import { ThemeProps } from '../../defaultProps';
import { formatText } from '../../util';
import { useMobile } from '../../hooks/mediaHook';
import './landingHero.scss';

export interface LandingHeroProps {
  title: string;
  description?: string;
  image?: ImageProps;
  theme?: ThemeProps;
}

export default function LandingHero({
  title,
  description,
  image,
  theme = 'theme--ra-light-blue'
}: LandingHeroProps): ReactElement {
  const isMobile = useMobile();
  const infoElements = (
    <div className="landing-hero__info-wrapper">
      <div className="landing-hero__info">
        <h1 className="landing-hero__title">{formatText(title)}</h1>
        {description && <div className="landing-hero__desc">{formatText(description)}</div>}
      </div>
    </div>
  );

  return (
    <div className={`landing-hero ${image ? 'landing-hero--with-image' : ''} ${theme}`}>
      {!isMobile && infoElements}
      {image && (
        <div className="landing-hero__image">
          <Image {...image} />
        </div>
      )}
      {isMobile && infoElements}
    </div>
  );
}
