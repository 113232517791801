import React, { ReactElement } from 'react';
import { Input } from '../../FormFields/FormFields';

export interface BasicFieldValues {
  email: string;
}

interface BasicFieldsProps {
  fieldValues: BasicFieldValues;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function BasicFields({ fieldValues, onChange }: BasicFieldsProps): ReactElement {
  return (
    <>
      <Input
        label="Email"
        id="email"
        type="email"
        name="email"
        value={fieldValues.email ?? ''}
        required
        onChange={onChange}
      />
    </>
  );
}
