import React, { ReactElement, useState } from 'react';
import { Radio } from '../../FormFields/FormFields';
import { FriendFormComponentProps, NewFriendProps } from '../FriendSignup';
import './payment-method.scss';

export type PaymentMethodProps = {
  component: FriendFormComponentProps;
  setParam: (param: string, isParam: boolean) => void;
  newFriend: NewFriendProps;
  selectMembership: (n: number) => void;
};

export default function PaymentMethod({
  component,
  newFriend,
  setParam,
  selectMembership
}: PaymentMethodProps): ReactElement {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="payment-method">
      <h4 className="payment-method__title">Payment method</h4>
      {component.content && (
        <p className="payment-method__desc">
          {component.content}{' '}
          <button type="button" className="payment-method__more-info" onClick={() => setShowInfo((prev) => !prev)}>
            More info
          </button>
        </p>
      )}
      {showInfo && (
        <div className="tnew-dd-modal">
          <div className="tnew-dd-modal__content">
            <p>
              Direct Debit is convenient and safe and helps us to keep administration costs low. You are fully protected
              by the Direct Debit guarantee and can cancel future payments at any time by contacting your bank.
            </p>
          </div>
        </div>
      )}
      <div className="payment-method__options">
        <Radio
          type="radio"
          id="direct-debit"
          name="tn-cust-field-param-is_dd"
          value={newFriend.isDD}
          checked={newFriend.isDD}
          onChange={() => setParam('isDD', true)}
          label={
            <>
              <strong>£{newFriend.selectedMembership?.price.dd}</strong>
              <br />
              Recurring annual Direct Debit
            </>
          }
        />
        <Radio
          type="radio"
          id="one-off"
          name="tn-cust-field-param-is_dd"
          value={newFriend.isDD}
          checked={!newFriend.isDD}
          onChange={() => setParam('isDD', false)}
          label={
            <>
              <strong>£{newFriend.selectedMembership?.price.standard}</strong>
              <br />
              One-off payment
            </>
          }
        />
      </div>
      <div className="error-message--mobile">
        {newFriend.showErrors && 'Please correct errors highlighted in the form'}
      </div>
      {component.upsellContent && (
        <div className="payment-method__additional-information">
          <button
            type="button"
            onClick={() => {
              if (component.upsellMembershipId) {
                selectMembership(component.upsellMembershipId);
              }
            }}
          >
            <span>{component.upsellContent}</span>
          </button>
        </div>
      )}
    </div>
  );
}
