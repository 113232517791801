import React, { ReactElement } from 'react';
import Cta from '../../Cta/Cta';
import './whatsOnNoResult.scss';

export interface WhatsOnNoResultProps {
  onReset: () => void;
}

export default function WhatsOnNoResult({ onReset }: WhatsOnNoResultProps): ReactElement {
  return (
    <section className="whats-on-no-result">
      <p className="whats-on-no-result__listing-info">
        <span>0</span> results found
      </p>
      <h2 className="whats-on-no-result__heading">Sorry</h2>
      <p className="whats-on-no-result__desc">There are no upcoming exhibitions or events that match the selection.</p>
      <Cta type="tertiary" label="Reset your filters" onClick={onReset} />
    </section>
  );
}
