import React, { ReactElement } from 'react';
import Image, { ImageProps } from '../../Image/Image';
import { useMobile } from '../../../hooks/mediaHook';
import './imagePopUp.scss';
import { formatText } from '../../../util';
import Icon from '../../Icons/Icons';

export type ImagePopUpProps = {
  title: string;
  subtitle: string;
  details?: string;
  description?: string;
  onClose?: () => void;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  image: ImageProps;
};

export default function ImagePopUp({
  title,
  subtitle,
  details,
  description,
  onClose,
  onClickPrev,
  onClickNext,
  image
}: ImagePopUpProps): ReactElement {
  const isMobile = useMobile();
  const arrowsEl = (
    <div className="image-popup__arrows">
      <button
        className={`image-popup__button image-popup__button--prev ${onClickPrev ? 'theme--ra-blue' : ''}`}
        onClick={onClickPrev}
        disabled={onClickPrev === undefined}
        data-ga4-type="ui"
        data-ga4-area="image_lightbox"
      >
        <span className="sr-only">previous</span>
        <Icon icon="arrow-left" />
      </button>
      <button
        className={`image-popup__button image-popup__button--next ${onClickNext ? 'theme--ra-blue' : ''}`}
        onClick={onClickNext}
        disabled={onClickNext === undefined}
        data-ga4-type="ui"
        data-ga4-area="image_lightbox"
      >
        <span className="sr-only">next</span>
        <Icon icon="arrow-right" />
      </button>
    </div>
  );
  const closeEl = (
    <button
      className="image-popup__button image-popup__button--close theme--ra-blue"
      onClick={onClose}
      data-ga4-type="ui"
      data-ga4-area="image_lightbox"
    >
      <span className="sr-only">Close pop up</span>
      <Icon icon="close" />
    </button>
  );

  return (
    <section className="image-popup">
      <div className="image-popup__wrapper">
        {!isMobile && closeEl}
        <div className="image-popup__image-wrapper">
          <Image {...image} />
        </div>
        <div className="image-popup__info-wrapper">
          <div className="image-popup__text-wrapper">
            <h1 className="image-popup__title">{title}</h1>
            {subtitle && <h2 className="image-popup__subtitle">{subtitle}</h2>}
            {description && <div className="image-popup__desc">{formatText(description)}</div>}
            {details && <div className="image-popup__details">{formatText(details)}</div>}
          </div>
          {!isMobile && arrowsEl}
        </div>
        {isMobile && (
          <div className="image-popup__controls">
            {arrowsEl}
            {closeEl}
          </div>
        )}
      </div>
    </section>
  );
}
