import React, { ReactElement } from 'react';
import { GALinkProps } from '../../../defaultProps';
import { ImageProps } from '../../Image/Image';
import Image from '../../Image/Image';
import './artist-card.scss';
import { formatText } from '../../../util';
import PlaceholderImage from '../../Image/PlaceholderImage';

export type ArtistCardProps = {
  title: string;
  image?: ImageProps;
  gaTags?: GALinkProps;
  link: string;
  headingTag?: 'h2' | 'h3';
  subtitle?: string;
  discipline?: string;
  graduationYear?: number;
};

export default function ArtistCard({
  title,
  image,
  gaTags,
  headingTag = 'h2',
  link,
  subtitle
}: ArtistCardProps): ReactElement {
  const HeadingTag = headingTag;

  return (
    <div className="artist-card">
      <div className="artist-card__container">
        <div className="artist-card__image">{image ? <Image {...image} /> : <PlaceholderImage ratio="16x9" />}</div>
        <div className="artist-card__content">
          <HeadingTag className="artist-card__title">
            <a
              className="artist-card__link"
              href={link}
              data-ga4-type={gaTags?.type}
              data-ga4-area={gaTags?.area}
              data-gtm-name={gaTags?.name}
            >
              {formatText(title)}
              <span className="link-hotspot"></span>
            </a>
          </HeadingTag>
          {subtitle && <p className="artist-card__subtitle">{subtitle}</p>}
        </div>
      </div>
    </div>
  );
}
