import React, { ReactElement } from 'react';
import { Dropdown, Input, Radio } from '../../FormFields/FormFields';
import { FriendFormComponentProps, NewFriendProps } from '../FriendSignup';
import './gift-recipient.scss';
import { prefixes } from '../data/prefixes';
import FriendAddress from '../FriendAddress/FriendAddress';

export type GiftRecipientProps = {
  component: FriendFormComponentProps;
  handleFormInput: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    component: FriendFormComponentProps,
    title: string
  ) => void;
  handleOnBlur: (value: string, name: string, type: string) => void;
  newFriend: NewFriendProps;
  setParam: (n: string, v: boolean) => void;
  handleGiftMessage: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  postcodeApiKey: string;
};

export default function GiftRecipient({
  component,
  handleFormInput,
  handleOnBlur,
  newFriend,
  setParam,
  handleGiftMessage,
  postcodeApiKey
}: GiftRecipientProps): ReactElement {
  const validateField = (name: string, errorMessage: string): string | undefined => {
    if (newFriend.inlineErrors && newFriend.inlineErrors.includes(name)) return errorMessage;
  };

  const handleOnChangeFn = (e: React.ChangeEvent<HTMLInputElement>, title: string): void =>
    handleFormInput(e, component, title);

  const addressSetFn = (isAddressSet: boolean): void => setParam('validAddress', isAddressSet);
  return (
    <div className="gift-recipient">
      <h4 className="gift-recipient__title">Your gift recipient&#39;s details</h4>
      <p className="gift-recipient__desc">{component.content}</p>
      <div className="gift-recipient__form">
        <div className="gift-recipient__form__row gift-recipient__form__row--inline">
          <Dropdown
            options={prefixes}
            name="tn-cust-field-param-gift_prefix"
            id="gift-recipient__form__title"
            required={false}
            value={component.inputData?.title}
            label="Title"
            initialOption="Select..."
            onChange={(e) => {
              handleFormInput(e, component, 'title');
            }}
          />
          <Input
            type="text"
            id="gift-recipient__form__firstname"
            value={component.inputData ? component.inputData.firstName : ''}
            name="tn-cust-field-param-gift_fname"
            inlineError={validateField('tn-cust-field-param-gift_fname', 'Name must be 2 letters long')}
            label="First name"
            onBlur={(e) => handleOnBlur(e.target.value, e.target.name, 'firstName')}
            required={true}
            autoComplete="new-password"
            onChange={(e) => {
              handleFormInput(e, component, 'firstName');
            }}
            border={true}
            modifierClass="form-field--firstname"
          />

          <Input
            type="text"
            id="gift-recipient__form__lastname"
            value={component.inputData ? component.inputData.lastName : ''}
            name="tn-cust-field-param-gift_lname"
            inlineError={validateField('tn-cust-field-param-gift_lname', 'Name must be 2 letters long')}
            label="Last name"
            onBlur={(e) => handleOnBlur(e.target.value, e.target.name, 'lastName')}
            required={true}
            autoComplete="new-password"
            onChange={(e) => {
              handleFormInput(e, component, 'lastName');
            }}
            border={true}
            modifierClass="form-field--lastname"
          />
        </div>

        <FriendAddress addressSet={addressSetFn} postcodeApiKey={postcodeApiKey} newFriend={newFriend} />

        <div className="gift-recipient__form__row">
          <Input
            type="email"
            id="gift-recipient__form__email"
            value={component.inputData?.email}
            name="tn-cust-field-param-gift_email"
            label="Email (optional)"
            onChange={(e) => {
              handleOnChangeFn(e, 'email');
            }}
            border={true}
            help={component.contentEmail && <div dangerouslySetInnerHTML={{ __html: component.contentEmail }} />}
          />
        </div>
        <div className="gift-recipient__form__row">
          {/* Gift message goes in tessitura notes field */}
          <Input
            type="text"
            id="gift-recipient__form__tn-cust-field-param-notes"
            value={''}
            name="tn-cust-field-param-notes"
            label="Gift message (optional)"
            maxLength={48}
            onKeyUp={(e) => handleGiftMessage(e)}
            border={true}
            help={`Character limit: ${newFriend.giftMessageLength ? 48 - newFriend.giftMessageLength : 48}`}
          />
        </div>
        <h4 className="gift-recipient__subtitle">Delivery address</h4>
        <p>Where shall we send this gift?</p>
        <div className="gift-recipient__form__row gift-recipient__form__row--inline">
          <Radio
            type="radio"
            id="send-to-me"
            name="tn-cust-field-param-send-to-address"
            value={true}
            checked={newFriend.paramSendToAddress}
            onChange={() => setParam('param-send-to-address', true)}
            label="Send to me"
          />
          <Radio
            type="radio"
            id="send-to-recipient"
            name="tn-cust-field-param-send-to-address"
            value={false}
            checked={!newFriend.paramSendToAddress}
            onChange={() => setParam('param-send-to-address', false)}
            label="Send to recipient"
          />
        </div>
      </div>
    </div>
  );
}
