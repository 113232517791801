import { apiWithCredentials } from './config/axiosConfig';

export interface SessionUserApiProps {
  tnew: boolean;
  se: boolean;
  loggedInAsUser: boolean;
  cart: {
    hasDonations: boolean;
    hasMemberships: boolean;
    hasTickets: boolean;
  };
  constituencies: {
    friend: boolean;
  };
}

export const SessionUserApi = {
  get: async function (endpoint: string): Promise<SessionUserApiProps> {
    const response = await apiWithCredentials.request({
      url: endpoint,
      method: 'GET'
    });
    return response.data as SessionUserApiProps;
  }
};
