import React, { ReactElement } from 'react';
import './external-embed.scss';
import { formatAnchorName, RenderCookieConsent, renderJotformEmbed } from '../../util';

export type ExternalEmbedProps = {
  title: string;
  description?: string;
  embed?: string;
  jotformId?: string;
  layout?: 'stacked' | '2col';
  anchorName?: string;
};

export default function ExternalEmbed({
  title,
  description,
  embed,
  jotformId,
  layout = 'stacked',
  anchorName
}: ExternalEmbedProps): ReactElement {
  const checkIfCookieRendered = (code: string): ReactElement =>
    code.indexOf('ra_embed') == -1 ? (
      <RenderCookieConsent code={code}></RenderCookieConsent>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: code }}></div>
    );

  return (
    <div
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`external-embed ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="external-embed__container">
        <div className={`external-embed__grid ${layout == '2col' ? 'external-embed__grid--2col' : ''}`}>
          <div className="external-embed__text">
            {title && <h2 className="external-embed__title">{title}</h2>}
            {description && <p className="external-embed__description">{description}</p>}
          </div>
          <div className="external-embed__content">
            {embed && <div className="external-embed__embed">{!jotformId && checkIfCookieRendered(embed)}</div>}
            {jotformId && (
              <div className="external-embed__jotform">
                <RenderCookieConsent
                  code={renderJotformEmbed(jotformId, title)}
                  title={'Jotform'}
                ></RenderCookieConsent>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
