import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import './artists-filter.scss';
import { Input, DropdownCheckbox } from '../FormFields/FormFields';
import { ThemeProps } from '../../defaultProps';
import ArtistCard, { ArtistCardProps } from '../Card/ArtistCard/ArtistCard';
import Pagination from '../Pagination/Pagination';
import ListingInfo from '../ListingInfo/ListingInfo';
import Tag from '../Tag/Tag';
import { formatAnchorName, invertTheme } from '../../util';
import TertiaryCta from '../Cta/TertiaryCta/TertiaryCta';

interface ArtistsFilterDropdownProps {
  label: string;
  value: string;
}

export interface ArtistsFilterProps {
  title: string;
  dropdown: {
    title: string;
    options: ArtistsFilterDropdownProps[];
  };
  type: string;
  data: ArtistCardProps[];
  theme: ThemeProps;
  cardsPerPage: number;
  pageNumber: number;
  preselectedValue?: string;
  anchorName?: string;
}

interface GetNewResultsProps {
  searchedString?: string;
  selectedValue?: string;
}

export default function ArtistsFilter({
  title,
  dropdown,
  data,
  type,
  theme,
  cardsPerPage = 15,
  pageNumber = 1,
  preselectedValue,
  anchorName
}: ArtistsFilterProps): ReactElement {
  const ALL_FILTERS = 'all';
  const [searchValue, setSearchValue] = useState('');
  const [searchedValue, updateSearchedValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState([ALL_FILTERS]);
  const [results, setResults] = useState(data);
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);
  const [inlineButton, setInlineButton] = useState<'search' | 'close'>('search');
  const searchInput = useRef<HTMLDivElement>(null);
  const listingRef = useRef<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);

  const paginatedResults = useMemo(() => {
    const firstPageIndex = (currentPageNumber - 1) * cardsPerPage;
    const lastPageIndex = firstPageIndex + cardsPerPage;
    return results.slice(firstPageIndex, lastPageIndex);
  }, [currentPageNumber, results, cardsPerPage]);

  const forceLoading = (): void => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const getNewResults = ({ searchedString, selectedValue }: GetNewResultsProps): ArtistCardProps[] => {
    const searchedStringVal = searchedString !== undefined ? searchedString : searchedValue;
    updateSearchedValue(searchedStringVal);

    let dropdownArray: string[] = [...dropdownValue];
    if (selectedValue) {
      dropdownArray = dropdownValue.includes(selectedValue)
        ? dropdownValue.filter((v) => v !== selectedValue)
        : [...dropdownValue, selectedValue];
      dropdownArray = selectedValue !== ALL_FILTERS ? dropdownArray.filter((v) => v !== ALL_FILTERS) : [ALL_FILTERS];
      if (dropdownArray.length === 0) dropdownArray.push(ALL_FILTERS);
      setDropdownValue(dropdownArray);
    }
    return data.filter((item) => {
      const filterCondition = type === 'graduates' || type === 'students' ? item.graduationYear : item.discipline;
      const searchTermMatch =
        searchedStringVal.length > 0 ? item.title.toLowerCase().includes(searchedStringVal.toLowerCase()) : true;
      const dropdownValueMatch = !dropdownArray.includes(ALL_FILTERS)
        ? dropdownArray.includes(`${filterCondition ? filterCondition : ''}`)
        : true;
      return searchTermMatch && dropdownValueMatch;
    });
  };

  const handlePageChange = (page: number): void => {
    forceLoading();
    setCurrentPageNumber(page);
    if (listingRef.current) {
      const topDist = listingRef.current.getBoundingClientRect().top + window.pageYOffset - 92;
      setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: topDist });
      }, 50);
    }
  };

  const clearInput = (): void => {
    forceLoading();
    if (searchInput.current) {
      const input = searchInput.current.querySelector<HTMLInputElement>('input');
      if (input) input.value = '';
    }
    const updatedResults = getNewResults({ searchedString: '' });
    setResults(updatedResults);
    setCurrentPageNumber(1);
    setSearchValue('');
    setInlineButton('search');
  };

  const searchHandler = (): void => {
    forceLoading();
    if (inlineButton === 'close') {
      clearInput();
    } else {
      const updatedResults = getNewResults({ searchedString: searchValue });
      setResults(updatedResults);
      setCurrentPageNumber(1);
      setInlineButton('close');
    }
  };

  const handleKeyDown = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
    setInlineButton('search');
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === 'Enter') {
      searchHandler();
    }
  };

  const handleDropdownChange = (value: string): void => {
    forceLoading();
    const updatedResults = getNewResults({ selectedValue: value });
    setResults(updatedResults);
    setCurrentPageNumber(1);
  };

  const resetFilters = (): void => {
    handleDropdownChange(ALL_FILTERS);
    clearInput();
  };

  useEffect(() => {
    if (preselectedValue) handleDropdownChange(preselectedValue);
    // We only want this to run once
    // eslint-disable-next-line
  }, []);

  const selectedFilters: number = dropdownValue.filter((v) => v !== ALL_FILTERS).length;

  const invertedTheme = invertTheme(theme);

  return (
    <div
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`artists-filter ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className={`artists-filter__filters ${theme}`}>
        <div className="artists-filter__container">
          <h2 className="artists-filter__title">
            <em>{title}</em>
          </h2>
          <div className="artists-filter__grid">
            <div className="artists-filter__search" ref={searchInput}>
              <Input
                id="artists-filter__search__input"
                type="text"
                border={true}
                theme={invertedTheme}
                placeholder="Search by name..."
                inlineButton={inlineButton}
                buttonHandlerFn={searchHandler}
                label="Search"
                hideLabel={true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleKeyDown(event)}
                onKeyUp={(event) => handleKeyUp(event)}
                buttonGaTags={{ type: 'navigation', area: 'artist_module_search' }}
              />
            </div>

            <div className="artists-filter__dropdown">
              <DropdownCheckbox
                options={dropdown.options}
                name="artists-filter__select__dropdown"
                required={true}
                value={dropdownValue}
                label={dropdown.title}
                onChange={handleDropdownChange}
                gaTags={{ type: 'navigation', area: 'artist_module_dropdown' }}
                theme={invertedTheme}
                selectedFilters={selectedFilters}
              />
            </div>
            <div className="artists-filter__tags">
              {dropdownValue.map(
                (tag, index): ReactElement | boolean =>
                  tag !== ALL_FILTERS && (
                    <Tag key={index} label={tag} onClick={() => handleDropdownChange(tag)} theme={invertedTheme} />
                  )
              )}
            </div>
          </div>
        </div>
      </div>

      <section className="artists-filter__results" ref={listingRef}>
        <div className="artists-filter__results__inner">
          {!loading &&
            results &&
            (results.length > 0 ? (
              <>
                <ListingInfo
                  currentPage={currentPageNumber}
                  pageSize={cardsPerPage}
                  results={paginatedResults}
                  resultsTotal={results.length}
                />
                <div className="artists-filter__cards">
                  {paginatedResults.map(
                    (card, index): ReactElement => (
                      <ArtistCard
                        key={index}
                        title={card.title}
                        link={card.link}
                        image={card.image}
                        subtitle={card.discipline}
                        gaTags={{ type: 'ui', area: 'artist_module_card' }}
                      />
                    )
                  )}
                </div>
                <Pagination
                  totalCount={results.length}
                  pageSize={cardsPerPage}
                  currentPage={currentPageNumber}
                  onPageChange={(page) => handlePageChange(page)}
                ></Pagination>
              </>
            ) : (
              <section className="artists-filter__no-results">
                <p className="artists-filter__no-results__listing-info">
                  <strong>0</strong> results found
                </p>
                <h2 className="artists-filter__no-results__heading">Sorry</h2>
                <p className="artists-filter__no-results__desc">There are no results that match your search.</p>
                <TertiaryCta onClick={resetFilters} label="Reset filters" />
              </section>
            ))}
        </div>
      </section>
    </div>
  );
}
