import React, { ReactElement, useState, useCallback } from 'react';
import './explorer-artworks.scss';
import ExplorerListing from './ExplorerListing/ExplorerListing';
import ExplorerFilter from './ExplorerFilter/ExplorerFilter';
import ArtworkNoResult from './ArtworkNoResult/ArtworkNoResult';
import { ArtworkCardProps } from '../Card/ArtworkCard/ArtworkCard';

export type FilterType = 'Category' | 'Price' | 'Size';

export type FilterProps = {
  type: FilterType;
  label: string;
  value: string;
};

export interface ExplorerArtworksProps {
  artworks: ArtworkCardProps[];
  initialPage: number;
  pageSize: number;
  onFilterChange: (filters: FilterProps[], availableToBuyOnline: boolean) => void;
  onPageChange: (page: number) => void;
  loading: boolean;
  totalCount: number;
}

export default function ArtworkExplorer({
  artworks,
  initialPage,
  pageSize,
  onFilterChange,
  onPageChange,
  loading,
  totalCount
}: ExplorerArtworksProps): ReactElement {
  const [currentFilters, setCurrentFilters] = useState<FilterProps[]>([]);
  const [availableToBuyOnline, setAvailableToBuyOnline] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);

  const handleFilterChange = useCallback(
    (newFilters: FilterProps[], newAvailableToBuyOnline: boolean) => {
      setCurrentFilters(newFilters);
      setAvailableToBuyOnline(newAvailableToBuyOnline);
      setCurrentPage(1);
      onFilterChange(newFilters, newAvailableToBuyOnline);
    },
    [onFilterChange]
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      setCurrentPage(newPage);
      onPageChange(newPage);
    },
    [onPageChange]
  );

  const clearAllFilters = useCallback(() => {
    handleFilterChange([], false);
  }, [handleFilterChange]);

  const renderContent = useCallback(() => {
    if (loading) return null;
    if (artworks.length === 0) return <ArtworkNoResult onReset={clearAllFilters} />;
    return (
      <ExplorerListing
        data={artworks}
        pageSize={pageSize}
        pageNumber={currentPage}
        onPageChange={handlePageChange}
        totalCount={totalCount}
        isLoading={loading}
      />
    );
  }, [loading, clearAllFilters, artworks, pageSize, currentPage, handlePageChange, totalCount]);

  return (
    <>
      <ExplorerFilter
        onFilterChange={handleFilterChange}
        currentFilters={currentFilters}
        availableToBuyOnline={availableToBuyOnline}
      />
      <div className="explorer-artworks">
        <section className="explorer-artworks__artworks">{renderContent()}</section>
      </div>
    </>
  );
}
