import React, { ReactElement } from 'react';
import { FriendFormComponentProps, MembershipProps, NewFriendProps } from '../FriendSignup';
import Cta from '../../Cta/Cta';
import './membership-select.scss';
import Icon from '../../Icons/Icons';
import MembershipDropdown from '../MembershipDropdown/MembershipDropdown';
import { useMobile } from '../../../hooks/mediaHook';
import { ThemeProps } from '../../../defaultProps';
import { formatSnakeCase } from '../../../util';

export type MembershipSelectProps = {
  membership: MembershipProps;
  selectMembership: (id: number) => void;
  isSelected: boolean;
  newFriend: NewFriendProps;
  procedureId: number;
  renderComponentFn: (component: FriendFormComponentProps) => ReactElement;
  handleFormSubmit: (e: React.SyntheticEvent) => void;
  theme: ThemeProps;
};

export default function MembershipSelect({
  membership,
  selectMembership,
  isSelected,
  newFriend,
  procedureId,
  renderComponentFn,
  handleFormSubmit,
  theme
}: MembershipSelectProps): ReactElement {
  const highlightMembership = /tnew-membership-standard/.test(membership.name);
  const mobile = useMobile('1024px');

  const cardClickHandlerFn = (membershipId: number): void => {
    selectMembership(membershipId);
    if (window.innerWidth < 800) {
      window.scrollTo({
        top: (document.querySelector('.friend-signup__dropdown') as HTMLDivElement)?.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  // Selected membership's colour theme is the opposite of FriendSignup theme
  let selectedTheme: ThemeProps = 'theme--ra-blue';
  switch (theme) {
    case 'theme--ra-blue':
      selectedTheme = 'theme--ra-light-blue';
      break;
    case 'theme--ra-light-green':
      selectedTheme = 'theme--ra-green';
      break;
    case 'theme--ra-green':
      selectedTheme = 'theme--ra-light-green';
      break;
    case 'theme--ra-light-purple':
      selectedTheme = 'theme--ra-purple';
      break;
    case 'theme--ra-purple':
      selectedTheme = 'theme--ra-light-purple';
      break;
    case 'theme--ra-light-pink':
      selectedTheme = 'theme--ra-pink';
      break;
    case 'theme--ra-pink':
      selectedTheme = 'theme--ra-light-pink';
      break;
    case 'theme--ra-white':
      selectedTheme = 'theme--ra-blue';
      break;
  }

  return (
    <div
      className={`membership-select membership-select--${membership.name} ${
        isSelected ? 'membership-select--selected' : ''
      }`}
    >
      {highlightMembership && <div className="membership-select__label">Most popular</div>}
      <div
        className={`membership-select__selection ${
          theme === 'theme--ra-white' ? (isSelected ? 'theme--ra-blue' : 'theme--ra-light-blue') : ''
        } ${isSelected ? selectedTheme : ''}`}
      >
        <h3 className="membership-select__title">
          <span>{membership.title}</span>
        </h3>
        <div className="membership-select__benefits">
          <div className="membership-select__benefits__item">
            <span className="membership-select__benefits__icon">
              {Array(membership.cardholders.count)
                .fill(undefined)
                .map((_, index) => (
                  <Icon icon="membership" ariaHidden key={index} />
                ))}
            </span>
            <span>{`${membership.cardholders.count} ${membership.cardholders.description}`}</span>
          </div>
          <div className="membership-select__benefits__item">
            <span className="membership-select__benefits__icon">
              {Array(membership.cardholders.count)
                .fill(undefined)
                .map((_, index) => (
                  <Icon icon="ticket" ariaHidden key={index} />
                ))}
            </span>
            <span>+{`${membership.familyMembers.count} ${membership.familyMembers.description}`}</span>
          </div>
        </div>
        <div className="membership-select__price">
          From <span>£{membership.price.dd}</span>
        </div>
        <Cta
          label={membership.isGift ? 'Buy as a gift' : 'Join or renew'}
          type="secondary"
          theme={isSelected ? selectedTheme : 'theme--ra-white'}
          onClick={() => {
            cardClickHandlerFn(membership.id);
          }}
          gaTags={{ type: 'cta', area: formatSnakeCase(membership.title) }}
        />
      </div>
      {isSelected && mobile && (
        <MembershipDropdown
          newFriend={newFriend}
          procedureId={procedureId}
          renderComponentFn={renderComponentFn}
          handleFormSubmit={handleFormSubmit}
          selectMembership={selectMembership}
          theme={theme}
        />
      )}
    </div>
  );
}
