import React, { ReactElement } from 'react';
import './reviewCard.scss';

export interface ReviewCardProps {
  rating: number;
  quote: string;
  cite: string;
  url?: string;
}

export default function ReviewCard({ rating, quote, cite, url }: ReviewCardProps): ReactElement {
  return (
    <div className="review-card">
      {rating > 0 && rating <= 5 && (
        <ul className="review-card__rating-list">
          {Array.from(Array(rating).keys()).map((item) => (
            <li className="review-card__rating" key={item}>
              &#9733;
            </li>
          ))}
        </ul>
      )}
      {quote && <p className="review-card__quote">{quote}</p>}
      {cite && url && (
        <a href={url} className="review-card__cite review-card__link" target="_blank" rel="noreferrer">
          {cite}
        </a>
      )}
      {cite && !url && <p className="review-card__cite">{cite}</p>}
    </div>
  );
}
