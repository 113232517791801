import React, { ReactElement } from 'react';
import Image from '../Image/Image';
import './articleHero.scss';
import { HeroCarouselSlide } from '../HeroCarousel/HeroCarousel';
import HeroCaption from '../HeroCaption/HeroCaption';
import { useMobile } from '../../hooks/mediaHook';

export interface ArticleHeroProps {
  title: string;
  subtitle?: string;
  image: HeroCarouselSlide;
  date?: string;
  standfirst?: string;
}

export default function ArticleHero({ title, subtitle, standfirst, image, date }: ArticleHeroProps): ReactElement {
  const isTablet = useMobile('1024px');

  return (
    <div className="article-hero">
      {image && (
        <div className="article-hero__container">
          <div className="article-hero__grid">
            <>
              {isTablet && image.caption && <HeroCaption {...image.caption} />}
              <div className="article-hero__image-wrapper">
                <Image {...image.image} loadingAttr="eager" />
              </div>
              {!isTablet && image.caption && <HeroCaption {...image.caption} />}
            </>
          </div>
        </div>
      )}

      <div className="article-hero__container">
        <div className="article-hero__grid">
          <div className="article-hero__text">
            <h1 className="article-hero__text__title">{title}</h1>
            {subtitle && <p className="article-hero__text__subtitle">{subtitle}</p>}
            {date && <p className="article-hero__text__date">{date}</p>}
            {standfirst && <p className="article-hero__text__standfirst">{standfirst}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
