import React, { ReactElement } from 'react';
import { GALinkProps } from '../../../defaultProps';
import { ExplorerImageProps } from '../../ExplorerImage/ExplorerImage';
import ExplorerImage from '../../ExplorerImage/ExplorerImage';
import './explorer-card.scss';
import { formatText } from '../../../util';
import PlaceholderImage from '../../Image/PlaceholderImage';

export type ExplorerCardProps = {
  title: string;
  image: ExplorerImageProps;
  url: string;
  gaTags?: GALinkProps;
};

export default function ExplorerCard({ title, image, url, gaTags }: ExplorerCardProps): ReactElement {
  return (
    <div className="explorer-card">
      <div className="explorer-card__container">
        <div className="explorer-card__image">{image ? <ExplorerImage {...image} /> : <PlaceholderImage />}</div>
        <div className="explorer-card__content">
          <div className="explorer-card__title">
            <a
              className="explorer-card__link"
              href={url}
              data-ga4-type={gaTags?.type}
              data-ga4-area={gaTags?.area}
              data-gtm-name={gaTags?.name}
            >
              {formatText(title)}
              <span className="link-hotspot"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
