import { HostnameProps } from '@royalacademy/component-library';

const getHostnames = (): HostnameProps => {
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT;

  if (env == 'production') {
    return {
      website: 'https://www.royalacademy.org.uk',
      account: 'https://account.royalacademy.org.uk',
      tnew: 'https://my.royalacademy.org.uk',
      tnewApi: 'https://my.royalacademy.org.uk/api',
      se: 'https://se.royalacademy.org.uk',
      shop: 'https://shop.royalacademy.org.uk',
      api: 'https://api.royalacademy.org.uk',
      yass: 'https://youngartists.royalacademy.org.uk',
      signUp: 'https://api.royalacademy.org.uk',
      explorer: 'https://explorer.royalacademy.org.uk',
    };
  }

  return {
    website: 'https://knight.royalacademy.org.uk',
    account: 'https://account-staging.royalacademy.org.uk',
    tnew: 'https://my-staging.royalacademy.org.uk',
    tnewApi: 'https://my-staging.royalacademy.org.uk/api',
    se: 'https://se-staging.royalacademy.org.uk',
    shop: 'https://shop.royalacademy.org.uk',
    api: 'https://api-staging.royalacademy.org.uk',
    yass: 'https://youngartists-staging.royalacademy.org.uk',
    signUp: 'https://api-staging.royalacademy.org.uk',
    explorer: 'https://explorer-staging.royalacademy.org.uk',
  };
};

export default getHostnames;
