import React, { ReactElement, useRef } from 'react';
import { ThemeProps } from '../../../defaultProps';
import { FriendFormComponentProps, NewFriendProps } from '../FriendSignup';
import MembershipSummary from '../MembershipSummary/MembershipSummary';

export interface MembershipDropdownProps {
  newFriend: NewFriendProps;
  procedureId: number;
  renderComponentFn: (component: FriendFormComponentProps, key?: string) => ReactElement;
  handleFormSubmit: (e: React.SyntheticEvent) => void;
  selectMembership: (id: number) => void;
  theme: ThemeProps;
}

export default function MembershipDropdown({
  newFriend,
  procedureId,
  renderComponentFn,
  handleFormSubmit,
  selectMembership,
  theme
}: MembershipDropdownProps): ReactElement {
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={`friend-signup__dropdown friend-signup__dropdown--${newFriend?.selectedMembership?.id ?? 1}`}
      ref={dropdownRef}
    >
      <form
        key={newFriend?.selectedMembership?.id}
        id="tnew-membership_form"
        className={`friend-signup__form ${
          newFriend.showErrors || newFriend?.inlineErrors !== null ? 'friend-signup__form--errors' : ''
        }`}
        // onSubmit={handleFormSubmit}
      >
        <div className="friend-signup__dropdown__grid">
          <input type="hidden" name="exec" value="true" />
          <input type="hidden" name="procedureId" value={Number(procedureId)} />
          <input type="hidden" name="tn-cust-field-param-memb_start_dt" value={String(newFriend?.paramMembStartDt)} />
          <input
            type="hidden"
            name="tn-cust-field-param-memb_level_no"
            value={
              newFriend.isDD
                ? newFriend?.selectedMembership?.memb_level_no_dd
                : newFriend?.selectedMembership?.memb_level_no_standard
            }
          />
          <section className="friend-signup__dropdown__components">
            {newFriend.selectedMembership?.components &&
              newFriend.selectedMembership.components.map((component, index) =>
                renderComponentFn(component, index.toString())
              )}
          </section>
          <MembershipSummary
            newFriend={newFriend}
            selectMembership={selectMembership}
            theme={theme}
            handleFormSubmit={(e) => handleFormSubmit(e)}
          />
        </div>
      </form>
    </div>
  );
}
