import React, { useState, useCallback, useEffect } from 'react';
import { SessionExplorerApi, SessionExplorerApiProps } from '../../api/sessionExplorerApi';
import { ExplorerApi, ExplorerApiProps } from '../../api/explorerApi';
import { UseSessionStorage, SessionStorageKey } from '../../hooks/sessionStorage';
import { HostnameProps } from '../../api/interface';
import { routes } from '../../routes';
import Icon, { IconType } from '../Icons/Icons';
import Cta, { CtaProps } from '../Cta/Cta';
import { LinkProps } from './ExplorerArtwork';
import formatCurrency from '../../util';

interface ArtworkPurchaseProps {
  artworkId: number;
  isExhibitForSaleOnline: boolean;
  exhibitedPrice: number;
  editionPrice: number;
  selectedOption: 'edition' | 'exhibit';
  catalogueNumber: string;
  hostnames: HostnameProps;
  editionsForSale: boolean;
  editionsCopiesLeft: boolean;
  numberOfExhibitedSold: number;
  cta: CtaProps;
  paymentInfo: LinkProps;
  deposit: number;
  lastChance: boolean;
  availableAfterClose: boolean;
  isForSaleOnline: boolean;
  isForSaleOnlineLate: boolean;
}

interface UserStatus {
  loggedIn: boolean;
}

interface BasketState {
  reservationsCount: number;
}

export default function ArtworkPurchase({
  exhibitedPrice,
  editionPrice,
  selectedOption,
  artworkId,
  hostnames,
  isExhibitForSaleOnline,
  editionsForSale,
  editionsCopiesLeft,
  numberOfExhibitedSold,
  paymentInfo,
  deposit,
  lastChance,
  availableAfterClose
}: ArtworkPurchaseProps): React.ReactElement | null {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userStatus, setUserStatus] = useState<UserStatus>({ loggedIn: false });
  //eslint-disable-next-line
  const [basketState, setBasketState] = useState<BasketState>({ reservationsCount: 0 });

  const isExhibit = selectedOption === 'exhibit';
  const price = isExhibit ? exhibitedPrice : editionPrice;

  const [sessionUser, setSessionUser] = UseSessionStorage<SessionExplorerApiProps | undefined>(
    SessionStorageKey.Explorer,
    undefined
  );

  const setUserStatusFn = useCallback((user: SessionExplorerApiProps) => {
    setUserStatus({
      loggedIn: user.loggedInAsUser
    });
    if (user.loggedInAsUser) {
      document.body.classList.add('explorer-logged-in');
    }
  }, []);

  const setUserAndBasketFn = useCallback(
    (user: SessionExplorerApiProps) => {
      setUserStatusFn(user);
      setBasketState((prevState) => ({
        ...prevState,
        reservationsCount: user.reservationsCount
      }));
    },
    [setUserStatusFn]
  );

  const setSeBasketFn = useCallback((user: ExplorerApiProps) => {
    setBasketState((prevState) => ({
      ...prevState,
      reservationsCount: user.reservationsCount
    }));
  }, []);

  useEffect(
    () => {
      if (sessionUser !== undefined) setUserAndBasketFn(sessionUser);

      SessionExplorerApi.get(routes.explorer_user_url(hostnames.api))
        .then((user) => {
          setUserAndBasketFn(user);
          if (user.loggedInAsUser) {
            ExplorerApi.get(routes.explorer_user_url(hostnames.api))
              .then(setSeBasketFn)
              //eslint-disable-next-line
              .catch(console.error);
          }
          setSessionUser(user);
        })
        //eslint-disable-next-line
        .catch(console.error);
    },
    //eslint-disable-next-line
    []
  );

  const handleBuyNow = async (): Promise<void> => {
    if (!userStatus.loggedIn) {
      const returnToBasket = `${hostnames.explorer}/basket/add?artworkId=${artworkId}&type=${
        isExhibit ? 'exhibit' : 'edition'
      }`;
      const signInUrl = `${hostnames.tnew}/account/login?returnurl=${encodeURIComponent(returnToBasket)}`;
      window.location.href = signInUrl;
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      await ExplorerApi.post(routes.explorer_reservation_url(hostnames.api), {
        artworkId: artworkId,
        exhibited: isExhibit
      });
      setBasketState((prevState) => ({
        ...prevState,
        reservationsCount: prevState.reservationsCount + 1
      }));
    } catch (err) {
      setError('Failed to make reservation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuyNowClick = (): void => {
    void handleBuyNow();
  };

  const ctaProps: CtaProps = {
    type: 'secondary',
    label: isLoading ? 'Processing...' : 'Buy now',
    onClick: handleBuyNowClick,
    theme: 'theme--ra-white',
    wide: true,
    hideArrow: false,
    modifier: 'dark'
  };

  const isEditionSoldOut = !editionsForSale || (editionsForSale && !editionsCopiesLeft);
  const isExhibitSoldOut = !isExhibitForSaleOnline || numberOfExhibitedSold > 0;
  const isSoldOut = isExhibit ? isExhibitSoldOut : isEditionSoldOut;

  if (isSoldOut) {
    return null;
  }

  return (
    <div className="explorer-artwork__purchase">
      <div className="info-row">
        <span className="label">Total price</span>
        <span className="value">{formatCurrency(price)}</span>
      </div>
      <div className="info-row">
        <h3>Deposit payable today</h3>
        <span className="deposit-value">{formatCurrency(deposit)}</span>
      </div>

      {lastChance && (
        <div className="info-row">
          <span className="label-center">Last chance! Only a few remaining</span>
        </div>
      )}
      <div className="artwork-explorer__cta">
        <Cta {...ctaProps} />
      </div>
      {error && <p className="error-message">{error}</p>}
      <p className="payment-info">
        The artist will contact you to settle the remaining balance and arrange delivery of the{' '}
        {isExhibit ? 'exhibit' : 'edition'}. <a href={paymentInfo.href}>{paymentInfo.label}</a>
      </p>
      {availableAfterClose && (
        <>
          <div className="artwork-explorer__cta">
            <Cta {...ctaProps} />
          </div>
          {error && <p className="error-message">{error}</p>}
          <p className="payment-info">
            The artist will contact you to settle the remaining balance and arrange delivery of the{' '}
            {isExhibit ? 'exhibit' : 'edition'}. <a href={paymentInfo.href}>{paymentInfo.label}</a>
          </p>
        </>
      )}
      <div className="payment-methods">
        {['visa', 'mastercard', 'maestro', 'amex'].map((card) => (
          <Icon key={card} icon={card as IconType} />
        ))}
      </div>
    </div>
  );
}
