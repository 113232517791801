import React, { ReactElement, useState } from 'react';
import { GALinkProps } from '../../defaultProps';
import './image.scss';

export type ImageProps = {
  src: {
    small: string;
    medium: string;
    large: string;
  };
  altText?: string | null;
  title?: string | null;
  loadingAttr?: 'lazy' | 'eager' | undefined;
  gaTags?: GALinkProps | undefined;
  onLoad?: () => void;
};

export default function Image({ src, altText, title, loadingAttr = 'lazy', gaTags, onLoad }: ImageProps): ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = (): void => {
    setIsLoaded(true);
    if (onLoad) onLoad();
  };

  return (
    <img
      className={`image ${isLoaded ? 'image--loaded' : ''}`}
      src={src.large}
      srcSet={`${src.small} 576w, ${src.medium} 992w, ${src.large} 1440w`}
      alt={altText || ''}
      title={title || ''}
      loading={loadingAttr}
      data-ga4-type={gaTags?.type}
      data-ga4-area={gaTags?.area}
      data-gtm-name={gaTags?.name}
      onLoad={handleLoad}
    />
  );
}
