import React, { ReactElement } from 'react';
import './accordionWrapper.scss';
import Accordion, { AccordionProps } from '../Accordion/Accordion';
import { formatAnchorName } from '../../util';

export interface AccordionWrapperProps {
  title?: string;
  anchorName?: string;
  accordionItems: AccordionProps[];
}

export default function AccordionWrapper({ title, accordionItems, anchorName }: AccordionWrapperProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`accordion-wrapper ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="accordion-wrapper__container">
        <div className="accordion-wrapper__grid">
          {title && <h2 className="accordion-wrapper__title">{title}</h2>}
          <div className="accordion-wrapper__accordion">
            {accordionItems.map((accordion, i) => (
              <Accordion {...accordion} key={i} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
