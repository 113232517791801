import React, { ReactElement } from 'react';
import EventCard, { EventCardProps } from '../../Card/EventCard/EventCard';
import TertiaryCta from '../../Cta/TertiaryCta/TertiaryCta';
import './whatsOnCategories.scss';

export interface WhatsOnCategoriesProps {
  whatsOnCategories: {
    title: string;
    link: {
      href: string;
      label: string;
    };
    cards: EventCardProps[];
  }[];
}

export default function WhatsOnCategories({ whatsOnCategories }: WhatsOnCategoriesProps): ReactElement {
  return (
    <section className="whats-on-categories">
      {whatsOnCategories.map((category, categoryIndex) => (
        <div className="whats-on-categories__category" key={categoryIndex}>
          <div className="whats-on-categories__header">
            <h2 className="whats-on-categories__title">{category.title}</h2>
            <TertiaryCta
              href={category.link.href}
              label={category.link.label}
              gaTags={{ type: 'ui', area: 'listings_card' }}
            />
          </div>
          <ul className="whats-on-categories__list">
            {category.cards.map((card, cardIndex) => (
              <li className="whats-on-categories__item" key={cardIndex}>
                <EventCard {...card} gaTags={{ type: 'card', area: 'listings_card' }} headingTag="h3" />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
}
