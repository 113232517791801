import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { dateLabel, handleWhatsOnGADataLayerPush } from '../../util';
import { Checkbox } from '../FormFields/FormFields';
import './date-filter.scss';
import DatePicker from './DatePicker';
import { DateRange } from './DateRange';

export type DateFilterProps = {
  value?: DateRange;
  onChange: (value: DateRange, name: string) => void;
  removeDate: () => void;
};

export default function DateFilter({ value, onChange, removeDate }: DateFilterProps): ReactElement {
  const [filterSelectedMonth, setFilterSelectedMonth] = useState((value ? value[0] : dayjs()).startOf('month'));
  const today: DateRange = [dayjs().startOf('day'), dayjs().endOf('day')];

  const nowComingSoon: DateRange = [dayjs().startOf('day'), today[1].add(4, 'month')];
  const thisWeek: DateRange = [
    today[0].date(today[0].date()),
    today[1].date(today[1].date() - (today[1].day() - 1) + 6)
  ];
  const thisWeekend: DateRange = [
    today[0].date(today[0].date() - (today[0].day() - 1) + 5),
    today[1].date(today[1].date() - (today[1].day() - 1) + 6)
  ];
  const nextWeek: DateRange = [thisWeekend[0].add(2, 'day'), thisWeekend[1].add(7, 'day')];

  function isSelected(test: DateRange, v: DateRange): boolean {
    return test[0].isSame(v[0]) && test[1].isSame(v[1], 'seconds');
  }

  function handleOnChange(v: DateRange, n: string): void {
    if (value && v[0].isSame(value[0]) && v[1].isSame(value[1])) {
      removeDate();
      return;
    }
    onChange(v, n);
    setFilterSelectedMonth(v[0].startOf('month'));
    handleWhatsOnGADataLayerPush('when', n);
  }

  function onDatePickerClick(date: Dayjs, label: string): void {
    if (!value) {
      onChange([date.startOf('day'), date.endOf('day')], label);
      handleWhatsOnGADataLayerPush('when', dateLabel([date, date]));
      return;
    }

    if (date.isSame(value[0], 'day') || date.isSame(value[1], 'day')) {
      onChange([date.startOf('day'), date.endOf('day')], label);
      return;
    }

    if (date.isBefore(value[0])) {
      onChange([date.startOf('day'), value[1].endOf('day')], label);
      handleWhatsOnGADataLayerPush('when', dateLabel([date, value[1]]));
      return;
    }

    if (date.isAfter(value[0])) {
      onChange([value[0].startOf('day'), date.endOf('day')], label);
      handleWhatsOnGADataLayerPush('when', dateLabel([value[0], date]));
      return;
    }
  }

  const pickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pickerRef.current) {
      pickerRef.current.scrollIntoView({ block: 'end' });
    }
  });

  return (
    <div className="date-filter">
      <div className="date-filter__filters">
        <ol role="group" aria-labelledby="date-filter__legend">
          <li className="date-filter__filters__item">
            <Checkbox
              type="checkbox"
              name="Today"
              label="Today"
              value="today"
              id="date-filter__filters__today__checkbox"
              checked={!!value && isSelected(today, value)}
              onChange={() => handleOnChange(today, 'Today')}
              theme="theme--ra-light-blue"
            ></Checkbox>
          </li>
          <li className="date-filter__filters__item">
            <Checkbox
              type="checkbox"
              name="This week"
              label="This week"
              value="this-week"
              id="date-filter__filters__this-week__checkbox"
              checked={!!value && isSelected(thisWeek, value)}
              onChange={() => handleOnChange(thisWeek, 'This week')}
              theme="theme--ra-light-blue"
            ></Checkbox>
          </li>
          <li className="date-filter__filters__item">
            <Checkbox
              type="checkbox"
              name="Next week"
              label="Next week"
              value="next-week"
              id="date-filter__filters__next-week__checkbox"
              checked={!!value && isSelected(nextWeek, value)}
              onChange={() => handleOnChange(nextWeek, 'Next week')}
              theme="theme--ra-light-blue"
            ></Checkbox>
          </li>
          <li className="date-filter__filters__item">
            <Checkbox
              type="checkbox"
              name="On now and coming soon"
              label="On now and coming soon"
              value="now-coming-soon"
              id="date-filter__filters__now-coming-soon__checkbox"
              checked={!!value && isSelected(nowComingSoon, value)}
              onChange={() => handleOnChange(nowComingSoon, 'On now and coming soon')}
              theme="theme--ra-light-blue"
            ></Checkbox>
          </li>
        </ol>
      </div>
      {
        <div className="date-filter__picker" ref={pickerRef}>
          <DatePicker
            selectedMonth={filterSelectedMonth}
            setSelectedMonth={setFilterSelectedMonth}
            handleChange={(v) => onDatePickerClick(v, v.format('DD MMM YYYY'))}
            value={value}
          />
        </div>
      }
    </div>
  );
}
