import React, { ReactElement } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import './date-picker.scss';

import isBetween from 'dayjs/plugin/isBetween';
import { DateRange } from './DateRange';
import Icon from '../Icons/Icons';

dayjs.extend(isBetween);

export default function DatePicker({
  handleChange,
  selectedMonth,
  setSelectedMonth,
  value,
  endDate
}: {
  handleChange: (value: Dayjs) => void;
  selectedMonth: Dayjs;
  setSelectedMonth: (value: Dayjs) => void;
  value?: DateRange;
  endDate?: Dayjs;
}): ReactElement {
  const today = dayjs().startOf('day');

  function dayOfWeekStartingMonday(day: Dayjs): number {
    return (day.day() - 1 + 7) % 7;
  }

  function datesForGrid(start: Dayjs): Dayjs[] {
    const startCalendarFrom = start.subtract(dayOfWeekStartingMonday(start), 'day');
    const grid = [];
    let lastDate = startCalendarFrom;
    const nextMonth = selectedMonth.add(1, 'month');

    while (grid.length < 42) {
      grid.push(lastDate);
      lastDate = lastDate.add(1, 'day');

      if (lastDate.month() === nextMonth.month()) break;
    }

    return grid;
  }

  return (
    <div className="date-picker">
      <div className="date-picker__container">
        <div className="date-picker__year-month-wrap">
          <button
            className="date-picker__left-arrow"
            type="button"
            onClick={() => setSelectedMonth(selectedMonth.subtract(1, 'month'))}
            aria-label={`Select previous month (${selectedMonth.subtract(1, 'month').format('MMMM')})`}
          >
            {' '}
            <Icon icon="arrow-left"></Icon>
          </button>
          <div className="date-picker__month-year">{`${selectedMonth.format('MMMM')}, ${selectedMonth.year()}`}</div>
          <button
            className="date-picker__right-arrow"
            type="button"
            onClick={() => setSelectedMonth(selectedMonth.add(1, 'month'))}
            aria-label={`Select next month (${selectedMonth.add(1, 'month').format('MMMM')})`}
          >
            <Icon icon="arrow-right"></Icon>
          </button>
        </div>
        <div className="date-picker__grid">
          <div className="date-picker__days-grid" role="presentation">
            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((d, i) => (
              <div key={`date-picker__days--${i}`} className="date-picker__days">
                {d}
              </div>
            ))}
          </div>
          <div className="date-picker__day-grid">
            {datesForGrid(selectedMonth).map((date) => (
              <button
                type="button"
                key={date.toString()}
                onClick={() => handleChange(date)}
                aria-label={`Events on ${date.format('ddd, D MMM YYYY')}`}
                disabled={date.isBefore(today) || (endDate && date.isAfter(endDate))}
                className={[
                  'date-picker__day',
                  value && date.isBetween(value[0], value[1], 'day', '[]') ? 'date-picker__day--highlight' : '',
                  date.isSame(today) ? 'date-picker__day--today' : '',
                  value && date.isSame(value[1].startOf('day')) ? 'date-picker__day--highlight--last' : '',
                  value && date.isSame(value[0].startOf('day')) ? 'date-picker__day--highlight--first' : '',
                  date.month() !== selectedMonth.month() ? 'date-picker__day--hide' : '',
                  date.isBefore(today) ? 'date-picker__day--disabled' : ''
                ].join(' ')}
              >
                <span className="date-picker__date">{date.date()}</span>
                <span
                  className="date-picker__day--screen-reader"
                  aria-label={`Selected date ${date.format('ddd, D MMM YYYY')}`}
                >
                  Selected date {date.format('ddd, D MMM YYYY')}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
