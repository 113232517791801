import { apiWithCredentials } from './config/axiosConfig';

export interface UserApiProps {
  status: string;
  data: {
    token: string;
    name: string;
    type: string;
    has_access_to: [];
    basket: {
      expires_in_seconds: number;
      extra_time_added: boolean;
      items: {
        id: number;
        type: string;
        title: string;
        description: string;
        price_in_pence: number;
        exhibited: false;
        catalogue_number: number;
        data_layer_products: string;
      }[];
    };
    gallery: [];
  };
}

export const UserApi = {
  get: async function (endpoint: string): Promise<UserApiProps> {
    const response = await apiWithCredentials.request({
      url: endpoint,
      method: 'GET'
    });
    return response.data as UserApiProps;
  }
};
