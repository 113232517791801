import React, { ReactElement, useState } from 'react';
import './search-dropdown.scss';
import { useMobile } from '../../../hooks/mediaHook';
import { Input } from '../../FormFields/FormFields';
import Icon from '../../Icons/Icons';
import { breakpoints } from '../../../util';
import { HostnameProps } from '../../../api/interface';

export type SearchDropdownProps = {
  closeSearchDropdownFn: () => void;
  hostnames: HostnameProps;
};

export default function SearchDropdown({ closeSearchDropdownFn, hostnames }: SearchDropdownProps): ReactElement {
  const theme = 'theme--ra-white';
  const mobile = useMobile(breakpoints.md, true);
  const [searchValue, updateSearchValue] = useState('');

  const searchHandlerFn = (): void => {
    if (typeof window !== 'undefined') {
      window.location.href = `${hostnames.website}/search-results?q=${searchValue}`;
    }
  };

  const handleKeyDown = (): void => {
    searchHandlerFn();
  };

  return (
    <>
      {!mobile && (
        <div
          className="search-dropdown__overlay"
          onClick={() => {
            closeSearchDropdownFn();
          }}
        ></div>
      )}
      <div className="search-dropdown">
        <div className="search-dropdown__header">
          <button
            className="search-dropdown__close"
            onClick={() => {
              closeSearchDropdownFn();
            }}
            tabIndex={0}
          >
            {!mobile ? (
              <>
                <span className="sr-only">Close</span>
                <Icon icon={'close'} />
              </>
            ) : (
              <>
                <Icon icon="chevron-left" />
                Back
              </>
            )}
          </button>
        </div>

        <div className={`search-dropdown__inner ${theme}`}>
          <div className="search-dropdown__grid">
            <div className="search-dropdown__form">
              <h2 className="search-dropdown__title">Search the RA</h2>
              <Input
                id="search-dropdown--input"
                type="text"
                border={true}
                theme={theme}
                placeholder="Your search begins here..."
                inlineButton="search"
                buttonHandlerFn={() => searchHandlerFn()}
                label="Search"
                hideLabel={true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateSearchValue(event.target.value)}
                buttonGaTags={{ type: 'navigation', area: 'utility', name: 'nav: utility' }}
                handleKeyDown={handleKeyDown}
              />
              <p>Looking for one of our artists or something from our Collection?</p>
              <div className="search-dropdown__collection-link">
                <a
                  href={`${hostnames.website}/art-artists/search/search-the-collection`}
                  data-ga4-type="navigation"
                  data-ga4-area="utility"
                  data-gtm-name="nav: utility"
                >
                  Search the RA Collection
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
