import React from 'react';
import './explorer-basket-header.scss';
import Icon from '../../Icons/Icons';
import { HostnameProps } from '../../../api/interface';

export interface BasketHeaderProps {
  className?: string;
  timeRemaining?: string;
  hostnames: HostnameProps;
  theme?: string;
  year: number;
}

export default function ExplorerBasketHeader({
  className,
  timeRemaining = '15:00',
  hostnames,
  theme = 'theme--ra-black',
  year = 2024
}: BasketHeaderProps): React.ReactElement {
  return (
    <header className={`explorer-basket-header ${theme} ${className || ''}`}>
      <div className="explorer-basket-header__container">
        <div className="explorer-basket-header__left">
          <div className="explorer-basket-header__logo">
            <a href={hostnames.website}>
              <Icon icon="ra-logo" />
              <span className="explorer-basket-header__exhibition-text">
                Summer{'\n'}Exhibition{'\n'}
                {year}
              </span>
            </a>
          </div>
        </div>
        <div className="explorer-basket-header__timer">
          <Icon icon="clock" />
          <span className="explorer-basket-header__timer-text">Time remaining {timeRemaining}</span>
        </div>
      </div>
    </header>
  );
}
