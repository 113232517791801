import React, { useState } from 'react';
import './explorer-basket.scss';
import ExplorerImage, { ExplorerImageProps } from '../ExplorerImage/ExplorerImage';
import Icon, { IconType } from '../Icons/Icons';
import Cta, { CtaProps } from '../Cta/Cta';
import { ExplorerApi } from '../../api/explorerApi';
import { routes } from '../../routes';
import { HostnameProps } from 'api/interface';
import formatCurrency from '../../util';

export interface ExplorerBasketItem {
  artworkId: number;
  reservationId: number;
  catalogueNumber: string;
  title: string;
  artist: string;
  type: string;
  price: number;
  deposit: number;
  image: ExplorerImageProps;
  note: string;
}

export interface ExplorerBasketPageProps {
  items: ExplorerBasketItem[];
  total: number;
  deposit: number;
  cta: CtaProps;
  emptyCta: CtaProps;
  hostnames: HostnameProps;
  onBasketUpdate?: (updatedItems: ExplorerBasketItem[]) => void;
}

export default function ExplorerBasketPage({
  items: initialItems,
  total: initialTotal,
  deposit: initialDeposit,
  cta,
  emptyCta,
  hostnames,
  onBasketUpdate
}: ExplorerBasketPageProps): React.ReactElement {
  const [items, setItems] = useState(initialItems);
  const [total, setTotal] = useState(initialTotal);
  const [deposit, setDeposit] = useState(initialDeposit);
  const [isDeleting, setIsDeleting] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleDeleteItem = async (artworkId: number, reservationId: number): Promise<void> => {
    setIsDeleting(artworkId);
    setError(null);

    try {
      const response = await ExplorerApi.delete(routes.explorer_reservation_url(hostnames.api), {
        artworkId,
        reservationId
      });

      if (response.data?.reservations) {
        const updatedItems = items.filter((item) => item.reservationId !== reservationId);
        const newTotal = updatedItems.reduce((sum, item) => sum + item.price, 0);
        const newDeposit = updatedItems.reduce((sum, item) => sum + item.deposit, 0);

        setItems(updatedItems);
        setTotal(newTotal);
        setDeposit(newDeposit);

        if (onBasketUpdate) {
          onBasketUpdate(updatedItems);
        }
      }
    } catch (err) {
      setError('Failed to remove item. Please try again.');
    } finally {
      setIsDeleting(null);
    }
  };

  if (items.length === 0) {
    return (
      <div className="basket-page__sold-out">
        <div className="basket-page__content">
          <main className="basket-page__main">
            <h1 className="basket-page__title">Basket</h1>
            <div className="basket-page__empty">
              <div className="basket-page__empty-message">
                <p>Your basket is empty</p>
              </div>
              <div className="basket-page__empty-cta">
                <Cta {...emptyCta} />
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="basket-page">
      <div className="basket-page__content">
        <main className="basket-page__main">
          <h3 className="basket-page__title">Basket</h3>
          {error && <div className="basket-page__error">{error}</div>}

          {items.map((item) => (
            <div key={item.artworkId} className="basket-page__item">
              <div className="basket-page__item-image">
                <ExplorerImage {...item.image} />
              </div>
              <div className="basket-page__item-details">
                <div className="basket-page__item-header">
                  <span className="basket-page__item-catalogue">Cat No. {item.catalogueNumber}</span>
                  <button
                    className="basket-page__item-remove"
                    onClick={() => void handleDeleteItem(item.artworkId, item.reservationId)}
                    disabled={isDeleting === item.artworkId}
                  >
                    {isDeleting === item.artworkId ? '...' : '×'}
                  </button>
                </div>
                <h5 className="basket-page__item-artist">{item.artist}</h5>
                <p className="basket-page__item-title">{item.title}</p>
                <p className="basket-page__item-type">{item.type}</p>
                <hr className="basket-page__item-divider" />
                <div className="basket-page__item-price">
                  <span>Total price</span>
                  <span>{formatCurrency(item.price)}</span>
                </div>
                <div className="basket-page__item-deposit">
                  <span>Deposit payable today</span>
                  <span className="basket-page__item-deposit--bold">{formatCurrency(item.deposit)}</span>
                </div>
                <p className="basket-page__item-note">{item.note}</p>
              </div>
            </div>
          ))}

          <a href="/artworks" className="basket-page__continue">
            Continue shopping
          </a>
        </main>

        <aside className="basket-page__summary">
          <h4 className="basket-page__summary-title">Order summary</h4>
          <div className="basket-page__summary-total">
            <span>Order total</span>
            <span>{formatCurrency(total)}</span>
          </div>
          <div className="basket-page__summary-deposit">
            <span>Deposit payable today</span>
            <span>{formatCurrency(deposit)}</span>
          </div>
          <Cta {...cta} />
          <p className="basket-page__summary-secure">
            <Icon icon="lock" />
            Secure checkout
          </p>
          <div className="basket-page__summary-payments">
            {['visa', 'mastercard', 'maestro', 'amex'].map((card) => (
              <Icon key={card} icon={card as IconType} />
            ))}
          </div>
        </aside>
      </div>
    </div>
  );
}
