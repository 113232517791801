export const countries = [
  {
    value: 209,
    label: 'United Kingdom'
  },
  {
    value: 1,
    label: 'USA'
  },
  {
    value: 226,
    label: 'Afghanistan'
  },
  {
    value: 3,
    label: 'Albania'
  },
  {
    value: 4,
    label: 'Algeria'
  },
  {
    value: 267,
    label: 'American Samoa'
  },
  {
    value: 5,
    label: 'Andorra'
  },
  {
    value: 6,
    label: 'Angola'
  },
  {
    value: 271,
    label: 'Anguilla'
  },
  {
    value: 280,
    label: 'Antarctica'
  },
  {
    value: 10,
    label: 'Antigua and Barbuda'
  },
  {
    value: 7,
    label: 'Argentina'
  },
  {
    value: 235,
    label: 'Armenia'
  },
  {
    value: 8,
    label: 'Aruba'
  },
  {
    value: 281,
    label: 'Ascension Islands'
  },
  {
    value: 264,
    label: 'Ashmore & Cartier Islands'
  },
  {
    value: 227,
    label: 'Australia'
  },
  {
    value: 11,
    label: 'Austria'
  },
  {
    value: 273,
    label: 'Azerbaijan'
  },
  {
    value: 12,
    label: 'Azores'
  },
  {
    value: 230,
    label: 'Bahamas'
  },
  {
    value: 18,
    label: 'Bahrain'
  },
  {
    value: 24,
    label: 'Bangladesh'
  },
  {
    value: 219,
    label: 'Barbados'
  },
  {
    value: 236,
    label: 'Belarus'
  },
  {
    value: 15,
    label: 'Belgium'
  },
  {
    value: 22,
    label: 'Belize'
  },
  {
    value: 16,
    label: 'Benin'
  },
  {
    value: 17,
    label: 'Bermuda'
  },
  {
    value: 19,
    label: 'Bhutan'
  },
  {
    value: 25,
    label: 'Bolivia'
  },
  {
    value: 237,
    label: 'Bosnia and Herzegovina'
  },
  {
    value: 26,
    label: 'Botswana'
  },
  {
    value: 231,
    label: 'Bouvet Island'
  },
  {
    value: 27,
    label: 'Brazil'
  },
  {
    value: 283,
    label: 'British Indian Ocean Territory'
  },
  {
    value: 202,
    label: 'British Virgin Island'
  },
  {
    value: 28,
    label: 'Brunei Darussalam'
  },
  {
    value: 29,
    label: 'Bulgaria'
  },
  {
    value: 21,
    label: 'Burkina Faso'
  },
  {
    value: 30,
    label: 'Burma'
  },
  {
    value: 23,
    label: 'Burundi'
  },
  {
    value: 238,
    label: 'Cambodia'
  },
  {
    value: 31,
    label: 'Cameroon'
  },
  {
    value: 32,
    label: 'Canada'
  },
  {
    value: 42,
    label: 'Canal Zone'
  },
  {
    value: 41,
    label: 'Canary Islands'
  },
  {
    value: 46,
    label: 'Cape Verde'
  },
  {
    value: 49,
    label: 'Cayman Islands'
  },
  {
    value: 33,
    label: 'Central African Rep'
  },
  {
    value: 36,
    label: 'Chad'
  },
  {
    value: 35,
    label: 'Channel Islands'
  },
  {
    value: 37,
    label: 'Chile'
  },
  {
    value: 239,
    label: 'China'
  },
  {
    value: 43,
    label: 'Colombia'
  },
  {
    value: 40,
    label: 'Comoros'
  },
  {
    value: 171,
    label: 'Confed of Senegambia'
  },
  {
    value: 44,
    label: 'Congo'
  },
  {
    value: 39,
    label: 'Cook Islands'
  },
  {
    value: 45,
    label: 'Costa Rica'
  },
  {
    value: 232,
    label: 'Croatia'
  },
  {
    value: 47,
    label: 'Cuba'
  },
  {
    value: 48,
    label: 'Curacao'
  },
  {
    value: 50,
    label: 'Cyprus'
  },
  {
    value: 240,
    label: 'Czech Republic'
  },
  {
    value: 51,
    label: 'Czechoslovakia'
  },
  {
    value: 52,
    label: 'Dahomey'
  },
  {
    value: 53,
    label: 'Denmark'
  },
  {
    value: 54,
    label: 'Djibouti'
  },
  {
    value: 145,
    label: "Dm People's Rp Korea"
  },
  {
    value: 55,
    label: 'Dominica'
  },
  {
    value: 56,
    label: 'Dominican Republic'
  },
  {
    value: 241,
    label: 'East Timor'
  },
  {
    value: 57,
    label: 'Ecuador'
  },
  {
    value: 58,
    label: 'Egypt'
  },
  {
    value: 59,
    label: 'El Salvador'
  },
  {
    value: 60,
    label: 'England'
  },
  {
    value: 61,
    label: 'Equatorial Guinea'
  },
  {
    value: 242,
    label: 'Eritrea'
  },
  {
    value: 62,
    label: 'Estonia'
  },
  {
    value: 63,
    label: 'Ethiopia'
  },
  {
    value: 64,
    label: 'Faeroe Islands'
  },
  {
    value: 67,
    label: 'Falkland Islands'
  },
  {
    value: 69,
    label: 'Fed Rep of Germany'
  },
  {
    value: 243,
    label: 'Federated States of Micronesia'
  },
  {
    value: 66,
    label: 'Fiji'
  },
  {
    value: 65,
    label: 'Finland'
  },
  {
    value: 68,
    label: 'France'
  },
  {
    value: 34,
    label: 'French Guiana'
  },
  {
    value: 70,
    label: 'French Polynesia'
  },
  {
    value: 269,
    label: 'French Southern & Antarct     '
  },
  {
    value: 71,
    label: 'Gabon'
  },
  {
    value: 244,
    label: 'Georgia'
  },
  {
    value: 74,
    label: 'Germany'
  },
  {
    value: 75,
    label: 'Ghana'
  },
  {
    value: 76,
    label: 'Gibraltar'
  },
  {
    value: 77,
    label: 'Gilbert & Ellice Is'
  },
  {
    value: 79,
    label: 'Greece'
  },
  {
    value: 80,
    label: 'Greenland'
  },
  {
    value: 73,
    label: 'Grenada'
  },
  {
    value: 81,
    label: 'Guadaloupe'
  },
  {
    value: 78,
    label: 'Guatemala'
  },
  {
    value: 83,
    label: 'Guinea'
  },
  {
    value: 82,
    label: 'Guinea-Bissau'
  },
  {
    value: 84,
    label: 'Guyana'
  },
  {
    value: 85,
    label: 'Haiti'
  },
  {
    value: 88,
    label: 'Honduras'
  },
  {
    value: 86,
    label: 'Hong Kong'
  },
  {
    value: 89,
    label: 'Hungary'
  },
  {
    value: 90,
    label: 'Iceland'
  },
  {
    value: 92,
    label: 'India'
  },
  {
    value: 91,
    label: 'Indonesia'
  },
  {
    value: 95,
    label: 'Iran'
  },
  {
    value: 96,
    label: 'Iraq'
  },
  {
    value: 94,
    label: 'Ireland'
  },
  {
    value: 97,
    label: 'Isle Of Man'
  },
  {
    value: 98,
    label: 'Israel'
  },
  {
    value: 99,
    label: 'Italy'
  },
  {
    value: 100,
    label: 'Ivory Coast'
  },
  {
    value: 101,
    label: 'Jamaica'
  },
  {
    value: 102,
    label: 'Japan'
  },
  {
    value: 103,
    label: 'Jordan'
  },
  {
    value: 105,
    label: 'Kampuchea'
  },
  {
    value: 245,
    label: 'Kazakhstan'
  },
  {
    value: 106,
    label: 'Kenya'
  },
  {
    value: 108,
    label: 'Kiribati'
  },
  {
    value: 246,
    label: 'Kosovo'
  },
  {
    value: 109,
    label: 'Kuwait'
  },
  {
    value: 247,
    label: 'Kyrgyzstan'
  },
  {
    value: 110,
    label: 'Laos'
  },
  {
    value: 111,
    label: 'Latvia'
  },
  {
    value: 112,
    label: 'Lebanon'
  },
  {
    value: 113,
    label: 'Lesotho'
  },
  {
    value: 114,
    label: 'Liberia'
  },
  {
    value: 118,
    label: 'Libya'
  },
  {
    value: 115,
    label: 'Liechtenstein'
  },
  {
    value: 116,
    label: 'Lithuania'
  },
  {
    value: 117,
    label: 'Luxembourg'
  },
  {
    value: 119,
    label: 'Macau'
  },
  {
    value: 248,
    label: 'Macedonia'
  },
  {
    value: 120,
    label: 'Madagascar'
  },
  {
    value: 127,
    label: 'Malawi'
  },
  {
    value: 121,
    label: 'Malaysia'
  },
  {
    value: 249,
    label: 'Maldives'
  },
  {
    value: 126,
    label: 'Mali'
  },
  {
    value: 133,
    label: 'Malta'
  },
  {
    value: 250,
    label: 'Marshall Islands'
  },
  {
    value: 122,
    label: 'Martinique'
  },
  {
    value: 123,
    label: 'Mauritania'
  },
  {
    value: 134,
    label: 'Mauritius'
  },
  {
    value: 125,
    label: 'Mexico'
  },
  {
    value: 251,
    label: 'Moldova'
  },
  {
    value: 130,
    label: 'Monaco'
  },
  {
    value: 129,
    label: 'Mongolia'
  },
  {
    value: 252,
    label: 'Montenegro'
  },
  {
    value: 128,
    label: 'Montserrat Island'
  },
  {
    value: 131,
    label: 'Morocco'
  },
  {
    value: 132,
    label: 'Mozambique'
  },
  {
    value: 253,
    label: 'Myanmar'
  },
  {
    value: 135,
    label: 'Namibia'
  },
  {
    value: 136,
    label: 'Nauru'
  },
  {
    value: 138,
    label: 'Nepal'
  },
  {
    value: 87,
    label: 'Netherlands'
  },
  {
    value: 148,
    label: 'Netherlands Antilles'
  },
  {
    value: 139,
    label: 'Nevis'
  },
  {
    value: 137,
    label: 'New Caledonia'
  },
  {
    value: 140,
    label: 'New Guinea'
  },
  {
    value: 213,
    label: 'New Hebrides'
  },
  {
    value: 149,
    label: 'New Zealand'
  },
  {
    value: 141,
    label: 'Nicaragua'
  },
  {
    value: 142,
    label: 'Niger'
  },
  {
    value: 147,
    label: 'Nigeria'
  },
  {
    value: 144,
    label: 'Niue'
  },
  {
    value: 254,
    label: 'North Korea'
  },
  {
    value: 311,
    label: 'Northern Cyprus'
  },
  {
    value: 143,
    label: 'Northern Ireland, UK'
  },
  {
    value: 146,
    label: 'Norway'
  },
  {
    value: 150,
    label: 'Okinawa'
  },
  {
    value: 151,
    label: 'Oman'
  },
  {
    value: 152,
    label: 'Pacific Islands'
  },
  {
    value: 153,
    label: 'Pakistan'
  },
  {
    value: 255,
    label: 'Palau'
  },
  {
    value: 306,
    label: 'Palestinian Authority'
  },
  {
    value: 154,
    label: 'Panama'
  },
  {
    value: 155,
    label: 'Papua New Guinea'
  },
  {
    value: 156,
    label: 'Paraguay'
  },
  {
    value: 222,
    label: "People's Dm Rp Yemen"
  },
  {
    value: 38,
    label: "People's Rp of China"
  },
  {
    value: 157,
    label: 'Peru'
  },
  {
    value: 158,
    label: 'Philippines'
  },
  {
    value: 159,
    label: 'Pitcairn'
  },
  {
    value: 160,
    label: 'Poland'
  },
  {
    value: 161,
    label: 'Portugal'
  },
  {
    value: 20,
    label: 'Portuguese Guinea'
  },
  {
    value: 266,
    label: 'Puerto Rico'
  },
  {
    value: 162,
    label: 'Qatar'
  },
  {
    value: 177,
    label: 'Republic of Korea'
  },
  {
    value: 124,
    label: 'Republic of Maldives'
  },
  {
    value: 256,
    label: 'Republic of the Congo'
  },
  {
    value: 163,
    label: 'Reunion Islands'
  },
  {
    value: 164,
    label: 'Romania'
  },
  {
    value: 233,
    label: 'Russia'
  },
  {
    value: 166,
    label: 'Rwanda'
  },
  {
    value: 167,
    label: 'Ryukyu Islands'
  },
  {
    value: 185,
    label: 'Saint Lucia'
  },
  {
    value: 265,
    label: 'Saipan'
  },
  {
    value: 257,
    label: 'Samoa'
  },
  {
    value: 178,
    label: 'San Marino'
  },
  {
    value: 168,
    label: 'Sao Tome & Principe'
  },
  {
    value: 169,
    label: 'Saudi Arabia'
  },
  {
    value: 170,
    label: 'Scotland'
  },
  {
    value: 172,
    label: 'Senegal'
  },
  {
    value: 258,
    label: 'Serbia'
  },
  {
    value: 272,
    label: 'Serbia & Montenegro'
  },
  {
    value: 173,
    label: 'Seychelles'
  },
  {
    value: 174,
    label: 'Sierra Leone'
  },
  {
    value: 175,
    label: 'Singapore'
  },
  {
    value: 234,
    label: 'Slovakia'
  },
  {
    value: 228,
    label: 'Slovenia'
  },
  {
    value: 181,
    label: 'Solomon Islands'
  },
  {
    value: 182,
    label: 'Somalia'
  },
  {
    value: 180,
    label: 'South Africa'
  },
  {
    value: 229,
    label: 'South Korea'
  },
  {
    value: 259,
    label: 'South Sudan'
  },
  {
    value: 183,
    label: 'Spain'
  },
  {
    value: 184,
    label: 'Sri Lanka'
  },
  {
    value: 186,
    label: 'St Martin'
  },
  {
    value: 187,
    label: 'St Vincent & Grenad'
  },
  {
    value: 176,
    label: 'St. Kitts and Nevis'
  },
  {
    value: 188,
    label: 'Sudan'
  },
  {
    value: 189,
    label: 'Suriname'
  },
  {
    value: 190,
    label: 'Swaziland'
  },
  {
    value: 191,
    label: 'Sweden'
  },
  {
    value: 192,
    label: 'Switzerland'
  },
  {
    value: 193,
    label: 'Syria'
  },
  {
    value: 194,
    label: 'Tahiti'
  },
  {
    value: 195,
    label: 'Taiwan'
  },
  {
    value: 260,
    label: 'Tajikistan'
  },
  {
    value: 196,
    label: 'Tanzania'
  },
  {
    value: 197,
    label: 'Thailand'
  },
  {
    value: 13,
    label: 'The Bahamas'
  },
  {
    value: 72,
    label: 'The Gambia'
  },
  {
    value: 198,
    label: 'Tibet'
  },
  {
    value: 199,
    label: 'Timor'
  },
  {
    value: 200,
    label: 'Togo'
  },
  {
    value: 201,
    label: 'Tonga'
  },
  {
    value: 203,
    label: 'Trinidad & Tobago'
  },
  {
    value: 204,
    label: 'Tunisia'
  },
  {
    value: 205,
    label: 'Turkey'
  },
  {
    value: 261,
    label: 'Turkmenistan'
  },
  {
    value: 268,
    label: 'Turks & Caicos Island'
  },
  {
    value: 206,
    label: 'Tuvalu'
  },
  {
    value: 208,
    label: 'Uganda'
  },
  {
    value: 262,
    label: 'Ukraine'
  },
  {
    value: 207,
    label: 'United Arab Emirates'
  },
  {
    value: 211,
    label: 'Uruguay'
  },
  {
    value: 263,
    label: 'Uzbekistan'
  },
  {
    value: 215,
    label: 'Vanuatu'
  },
  {
    value: 214,
    label: 'Vatican City'
  },
  {
    value: 216,
    label: 'Venezuela'
  },
  {
    value: 217,
    label: 'Vietnam'
  },
  {
    value: 278,
    label: 'Virgin Islands'
  },
  {
    value: 218,
    label: 'Wales'
  },
  {
    value: 277,
    label: 'West Indies'
  },
  {
    value: 179,
    label: 'Western Samoa'
  },
  {
    value: 220,
    label: 'Yemen Arab Republic'
  },
  {
    value: 223,
    label: 'Yugoslavia'
  },
  {
    value: 107,
    label: 'Zaire'
  },
  {
    value: 224,
    label: 'Zambia'
  },
  {
    value: 225,
    label: 'Zimbabwe'
  },
  {
    value: 279,
    label: '!!!State with UK as Country'
  }
];
