import React, { ReactElement, useState } from 'react';
import { formatText } from '../../util';
import RichText from '../RichText/RichText';
import './accordion.scss';

export interface AccordionProps {
  heading?: string;
  body: string;
  preview?: boolean;
  expandText?: string;
  collapseText?: string;
  copyright?: string;
  wrapper?: boolean;
}

export default function Accordion({
  heading,
  body,
  copyright,
  preview = false,
  expandText = 'More',
  collapseText = 'Less',
  wrapper = false
}: AccordionProps): ReactElement {
  const [open, setOpen] = useState(false);
  return (
    <div className={`accordion ${wrapper ? 'accordion--wrapper' : ''}`}>
      <button
        type="button"
        className="accordion__button"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
        aria-label={open ? 'close accordion' : 'open accordion'}
        role="button"
      >
        {heading && <span className="accordion__label">{formatText(heading)}</span>}
        <span className={`accordion__caret ${open ? 'accordion__caret--open' : ''}`}>
          {!wrapper && (open ? collapseText : expandText)}
        </span>
      </button>
      {(preview || open) && (
        <div className={`accordion__panel ${open ? 'accordion__panel--open' : ''}`} role="region" aria-label={heading}>
          <div className="accordion__body">
            <RichText text={body} noGrid={true} showMore={false} />
          </div>
          {copyright && <div className="accordion__copyright">{copyright}</div>}
        </div>
      )}
    </div>
  );
}
