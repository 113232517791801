import React, { ReactElement } from 'react';
import { ThemeProps } from '../../../defaultProps';
import Cta from '../../Cta/Cta';
import { NewFriendProps } from '../FriendSignup';
import './membership-summary.scss';
import { formatSnakeCase } from '../../../util';

export type MembershipSummaryProps = {
  newFriend: NewFriendProps;
  selectMembership: (n: number) => void;
  handleFormSubmit: (e: React.SyntheticEvent) => void;
  theme: ThemeProps;
};

export default function MembershipSummary({
  newFriend,
  selectMembership,
  handleFormSubmit,
  theme
}: MembershipSummaryProps): ReactElement {
  return (
    <div className="membership-summary">
      {newFriend.selectedMembership && (
        <div className={`membership-summary__container ${theme}`}>
          <h4 className="membership-summary__title">Summary</h4>

          <div className="membership-summary__content">
            <div className="membership-summary__message">
              {newFriend.selectedMembership.isGift
                ? `Buying a ${newFriend.selectedMembership.title} membership as a gift`
                : `Buying a ${newFriend.selectedMembership.title} membership for yourself`}
            </div>
            <div className="membership-summary__switch">
              <button
                type="button"
                className="membership-summary__switch__link"
                onClick={() => {
                  if (newFriend.selectedMembership) {
                    selectMembership(newFriend.selectedMembership.switchId);
                  }
                }}
              >
                {newFriend.selectedMembership.isGift ? 'Buy for yourself' : 'Buy as a gift for someone else'}
              </button>
            </div>
            <div className="membership-summary__price">
              {newFriend.isDD ? (
                <>
                  <div className="membership-summary__price__amount">£{newFriend.selectedMembership.price.dd}</div>
                  <div className="membership-summary__price__message">Recurring annual payment by Direct Debit</div>
                </>
              ) : (
                <>
                  <div className="membership-summary__price__amount">
                    £{newFriend.selectedMembership.price.standard}
                  </div>
                  <div className="membership-summary__price__message">One-off payment</div>
                </>
              )}
            </div>
            <div className="error-message">
              {newFriend.showErrors && 'Please correct errors highlighted in the form'}
            </div>
            <div className="error-message">{newFriend.errorMessage && newFriend.errorMessage}</div>
          </div>
          <div className="membership-summary__button">
            <Cta
              type="secondary"
              label="Add to basket"
              onClick={handleFormSubmit}
              gaTags={{
                type: 'cta',
                area: `add_${formatSnakeCase(newFriend.selectedMembership.title)}${newFriend.isGift ? '_gift' : ''}`
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
