import React, { ReactElement } from 'react';
import Icon, { IconType } from '../Icons/Icons';
import { formatAnchorName, formatText } from '../../util';
import { ThemeProps } from '../../defaultProps';
import Cta, { CtaProps } from '../Cta/Cta';
import './benefitsBlock.scss';

type BenefitsBlockCardProps = {
  title: string;
  description: string;
  icon: IconType;
};
export type BenefitsBlockProps = {
  title: string;
  theme: ThemeProps;
  cards: BenefitsBlockCardProps[];
  cta?: CtaProps;
  anchorName?: string;
};

export default function BenefitsBlock({
  title,
  cards,
  cta,
  theme = 'theme--ra-light-blue',
  anchorName
}: BenefitsBlockProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`benefits-block ${theme} ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="benefits-block__container">
        {title && <h2 className="benefits-block__title">{formatText(title)}</h2>}
        <ul className="benefits-block__list">
          {cards.map((card, i) => (
            <li className="benefits-block__card" key={i}>
              <Icon icon={card.icon} />
              <h3 className="benefits-block__card__title">{card.title}</h3>
              <p className="benefits-block__card__description">{formatText(card.description)}</p>
            </li>
          ))}
        </ul>
        {cta && (
          <div className="benefits-block__cta">
            <Cta {...cta} />
          </div>
        )}
      </div>
    </section>
  );
}
