export const prefixes = [
  {
    label: 'Mr',
    value: 331
  },
  {
    label: 'Mrs',
    value: 324
  },
  {
    label: 'Miss',
    value: 3
  },
  {
    label: 'Ms',
    value: 321
  },
  {
    label: 'Dr',
    value: 364
  },
  {
    label: 'Sir',
    value: 18
  },
  {
    label: 'Lady',
    value: 20
  },
  {
    label: 'Professor',
    value: 15
  },
  {
    label: 'Lord',
    value: 153
  },
  {
    label: 'Mx',
    value: 206
  }
];
