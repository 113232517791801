import React, { ReactElement, useState, useEffect, useLayoutEffect } from 'react';
import { EventCardProps } from '../Card/EventCard/EventCard';
import './whatsOn.scss';
import WhatsOnCategories, { WhatsOnCategoriesProps } from './WhatsOnCategories/WhatsOnCategories';
import WhatsOnListing from './WhatsOnListing/WhatsOnListing';
import WhatsOnNoResult from './WhatsOnNoResult/WhatsOnNoResult';
import WhatsOnFilters from './WhatsOnFilters/WhatsOnFilters';
import { ImageProps } from '../Image/Image';
import dayjs from 'dayjs';
import { auidienceLabels, categoryLabels, dateLabel, handleWhatsOnGADataLayerPush } from '../../util';
import { DateRange } from '../DateFilter/DateRange';

export type WhatsonListingItem = {
  title: string;
  path: string;
  type: 'Event' | 'Exhibition';
  startsAt?: string;
  image: ImageProps;
  endsAt?: string;
  category: string;
  categoryType: string;
  audiences?: string[];
  isFree: boolean;
  isOnline: boolean;
  subtitle?: string;
  formattedDate?: string;
  formattedTime?: string;
  status?: string;
  orderWeight: number;
};

export type FilterCheckBoxType = 'what' | 'who';

export type FilterCheckboxProps = {
  type: FilterCheckBoxType;
  label: string;
  value: string;
};

export type DateStateProps = {
  dates?: DateRange;
  label?: string;
};

export type CheckboxTag = {
  type: 'checkbox';
  label: string;
  value: string;
};

export type DateTag = {
  type: 'date';
  dates: DateRange;
  label: string;
};

export type BooleanTag = {
  type: 'boolean';
  label: string;
  value: boolean;
};

export type TagProps = CheckboxTag | DateTag | BooleanTag;

export interface WhatsOnProps {
  data: WhatsonListingItem[];
  pageSize: number;
  whatsOnCategories: WhatsOnCategoriesProps;
}

function mapListingItemToEventCard(item: WhatsonListingItem): EventCardProps {
  return {
    title: item.title,
    label: item.categoryType,
    subtitle: item.subtitle,
    date: item.formattedDate,
    time: item.formattedTime,
    image: item.image,
    free: item.isFree,
    link: item.path,
    hasVideo: false,
    ...(item.status && { status: item.status })
  };
}

// TODO: extract to util
function itemMultiSort(itemA: WhatsonListingItem, itemB: WhatsonListingItem): number {
  if (itemA.orderWeight === itemB.orderWeight) {
    if (itemA.startsAt === undefined && itemB.startsAt === undefined) {
      return 0;
    }

    if (!itemA.startsAt) {
      return 1;
    }

    if (!itemB.startsAt) {
      return -1;
    }

    const dateA = dayjs(itemA.startsAt);
    const dateB = dayjs(itemB.startsAt);

    return dateA.unix() - dateB.unix();
  }

  return itemA.orderWeight - itemB.orderWeight;
}

// TODO: extract to util
function isInDateRange(item: WhatsonListingItem, dates: DateRange): boolean {
  if (item.startsAt && item.endsAt) {
    const itemStartDate = dayjs(item.startsAt);
    const itemEndDate = dayjs(item.endsAt);
    if (itemStartDate.unix() >= dates[0].unix() && itemStartDate.unix() <= dates[1].unix()) {
      return true;
    }
    // Check for ongoing events
    if (itemStartDate.unix() < dates[0].unix() && itemEndDate.unix() >= dates[0].unix()) {
      return true;
    }
  }

  return false;
}

function filterItems(
  items: WhatsonListingItem[],
  checkBoxFilters: FilterCheckboxProps[],
  dateFilters: DateRange | undefined,
  freeFilter: boolean,
  onlineFilter: boolean
): WhatsonListingItem[] {
  const whatFilters = checkBoxFilters
    .filter((filter) => filter.type === 'what')
    .map((filter) => filter.value.toLowerCase());
  const whoFilters = checkBoxFilters
    .filter((filter) => filter.type === 'who')
    .map((filter) => filter.value.toLowerCase());

  return items.filter((item) => {
    // This filter is used to filter out items that are not in the date range
    const dateCheck = dateFilters ? isInDateRange(item, dateFilters) : true;

    // This filter is used to filter out items that do not match the selected categories
    const whatCheck = whatFilters.length > 0 ? whatFilters.includes(item.category) : true;

    // TODO This is a hack to display extra audiences on bar and garden and house, it needs to be removed once we can add more than 2 audiences via the cms
    const audiences = item.audiences;
    const filters = whoFilters;
    if (filters?.includes('bar-and-garden')) filters.push('friends');
    if (filters?.includes('house')) filters.push('friends', 'bar-and-garden');

    // This filter is used to filter out items that do not match the selected audience
    const whoCheck = Boolean(filters?.length ? audiences?.filter((i) => filters?.includes(i))?.length : true);

    // This filter is used to filter out items that are not free
    const freeCheck = freeFilter ? item.isFree : true;

    // This filter is used to filter out items that are not online
    const onlineCheck = onlineFilter ? item.isOnline : true;

    return dateCheck && whatCheck && whoCheck && freeCheck && onlineCheck;
  });
}

function updateURLFilterParams(
  dateFilter: DateStateProps | undefined,
  checkboxFilters: FilterCheckboxProps[],
  freeFilter: boolean,
  onlineFilter: boolean,
  pageNumber: number
): void {
  const url = new URL(window.location.href);
  const whatFilters = checkboxFilters
    .filter((filter) => filter.type === 'what')
    .map((filter) => filter.value.toLowerCase());

  const whoFilters = checkboxFilters
    .filter((filter) => filter.type === 'who')
    .map((filter) => filter.value.toLowerCase());

  // Handle date filter setting and clearing
  if (dateFilter && dateFilter.dates) {
    if (url.searchParams.has('date-range-start')) {
      url.searchParams.set('date-range-start', `${dateFilter.dates[0].unix()}`);
    }
    if (!url.searchParams.has('date-range-start')) {
      url.searchParams.append('date-range-start', `${dateFilter.dates[0].unix()}`);
    }
    if (url.searchParams.has('date-range-end')) {
      url.searchParams.set('date-range-end', `${dateFilter.dates[1].unix()}`);
    }
    if (!url.searchParams.has('date-range-end')) {
      url.searchParams.append('date-range-end', `${dateFilter.dates[1].unix()}`);
    }
  } else {
    if (url.searchParams.has('date-range-start')) {
      url.searchParams.delete('date-range-start');
    }
    if (url.searchParams.has('date-range-end')) {
      url.searchParams.delete('date-range-end');
    }
  }

  if (whatFilters.length > 0) {
    if (url.searchParams.has('what-filter')) {
      url.searchParams.delete('what-filter');
    }
    url.searchParams.append('what-filter', whatFilters.join(','));
  } else if (url.searchParams.has('what-filter')) {
    url.searchParams.delete('what-filter');
  }

  if (whoFilters.length > 0) {
    if (url.searchParams.has('who-filter')) {
      url.searchParams.delete('who-filter');
    }
    url.searchParams.append('who-filter', whoFilters.join(','));
  } else if (url.searchParams.has('who-filter')) {
    url.searchParams.delete('who-filter');
  }

  if (freeFilter) {
    if (!url.searchParams.has('free')) {
      url.searchParams.append('free', 'true');
    }
  } else if (url.searchParams.has('free')) {
    url.searchParams.delete('free');
  }

  if (onlineFilter) {
    if (!url.searchParams.has('online')) {
      url.searchParams.append('online', 'true');
    }
  } else if (url.searchParams.has('online')) {
    url.searchParams.delete('online');
  }

  if (pageNumber) {
    if (!url.searchParams.has('page')) {
      url.searchParams.append('page', String(pageNumber));
    }
    if (url.searchParams.get('page')) {
      url.searchParams.set('page', String(pageNumber));
    }
  }

  window.history.replaceState({}, '', url.href);
}

export default function WhatsOn({ data, pageSize, whatsOnCategories }: WhatsOnProps): ReactElement {
  const sortedItems = data.sort((a, b) => itemMultiSort(a, b));

  const [filteredItems, setFilteredItems] = useState(sortedItems);
  const [filters, setFilters] = useState<FilterCheckboxProps[]>([]);
  const [date, setDate] = useState<DateStateProps>({ label: undefined });
  const [tags, setTags] = useState<TagProps[]>([]);
  const [onlineOnly, setOnlineOnly] = useState(false);
  const [freeEvents, setFreeEvents] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const defaultPage = new URL(window.location.href).searchParams.get('page');
    if (defaultPage) {
      setPageNumber(Number(defaultPage));
    }
  }, []);

  const filterControls = {
    filters,
    setFilters,
    date,
    setDate,
    tags,
    setTags,
    onlineOnly,
    setOnlineOnly,
    freeEvents,
    setFreeEvents
  };

  const clearAllFilters = (): void => {
    handleWhatsOnGADataLayerPush('clear filters', tags.map((tag) => tag.label).join(','));
    setFilters([]);
    setDate({});
    setTags([]);
    setOnlineOnly(false);
    setFreeEvents(false);
    setPageNumber(1);
  };

  const filtersActive: boolean = filterControls.tags.length != 0;

  const setupFilters = (): void => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const startDateParam = params.get('date-range-start');
      const endDateParam = params.get('date-range-end');
      const whatFilterParam = params.get('what-filter');
      const whoFilterParam = params.get('who-filter');
      const freeFilterParam = params.get('free');
      const onlineFilterParam = params.get('online');
      const pageNumberParam = params.get('page');

      // Date filters
      if (startDateParam && endDateParam) {
        const start = dayjs.unix(parseInt(startDateParam));
        const end = dayjs.unix(parseInt(endDateParam));
        setDate({ label: dateLabel([start, end]), dates: [start, end] });
      }

      let urlFilters: Array<FilterCheckboxProps> = [];

      if (whatFilterParam) {
        const whatFilters = whatFilterParam.split(',').map((filter): FilterCheckboxProps => {
          const filterLabel = categoryLabels.get(filter);
          const paramFilter: FilterCheckboxProps = {
            label: filterLabel ? filterLabel : '',
            value: filter,
            type: 'what'
          };
          return paramFilter;
        });

        urlFilters = [...urlFilters, ...whatFilters];
      }

      if (whoFilterParam) {
        const whoFilters = whoFilterParam.split(',').map((filter): FilterCheckboxProps => {
          const filterLabel = auidienceLabels.get(filter);
          const paramFilter: FilterCheckboxProps = {
            label: filterLabel ? filterLabel : '',
            value: filter,
            type: 'who'
          };
          return paramFilter;
        });

        urlFilters = [...urlFilters, ...whoFilters];
      }

      setFilters(urlFilters);

      setFreeEvents(freeFilterParam ? true : false);
      setOnlineOnly(onlineFilterParam ? true : false);
      if (pageNumberParam) {
        setPageNumber(pageNumber);
      }

      // Tags
      setTags(() => {
        const initTags: TagProps[] = [];
        if (startDateParam && endDateParam) {
          const start = dayjs.unix(parseInt(startDateParam));
          const end = dayjs.unix(parseInt(endDateParam));
          const dateTag: TagProps = {
            type: 'date',
            label: dateLabel([start, end]),
            dates: [start, end]
          };
          initTags.push(dateTag);
        }

        if (whatFilterParam) {
          whatFilterParam.split(',').forEach((whatFilter) => {
            const label = categoryLabels.get(whatFilter);
            initTags.push({
              label: label ? label : '',
              value: whatFilter,
              type: 'checkbox'
            });
          });
        }

        if (whoFilterParam) {
          whoFilterParam.split(',').forEach((whoFilter) => {
            const label = auidienceLabels.get(whoFilter);
            initTags.push({
              label: label ? label : '',
              value: whoFilter,
              type: 'checkbox'
            });
          });
        }

        if (onlineFilterParam) {
          initTags.push({ label: 'online', value: true, type: 'boolean' });
        }

        if (freeFilterParam) {
          initTags.push({ label: 'free', value: true, type: 'boolean' });
        }

        return initTags;
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setFilteredItems(filterItems(sortedItems, filters, date.dates, freeEvents, onlineOnly));
    updateURLFilterParams(date, filters, freeEvents, onlineOnly, pageNumber);
    setTimeout(() => setLoading(false), 100);
  }, [date, filters, freeEvents, onlineOnly, sortedItems, pageNumber]);

  useLayoutEffect(() => {
    setupFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WhatsOnFilters filterControls={filterControls} setPageNumber={setPageNumber} />
      <section className="whats-on">
        {!loading &&
          (filtersActive ? (
            filteredItems.length > 0 ? (
              <WhatsOnListing
                data={filteredItems.map((item) => mapListingItemToEventCard(item))}
                pageSize={pageSize}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            ) : (
              <WhatsOnNoResult onReset={clearAllFilters} />
            )
          ) : (
            <WhatsOnCategories {...whatsOnCategories} />
          ))}
      </section>
    </>
  );
}
