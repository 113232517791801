// We may have to amend this component when placing it in NextJS with cookieBot activated
import React, { ReactElement } from 'react';
import Accordion from '../Accordion/Accordion';
import './basicVideo.scss';
import { formatAnchorName, formatText } from '../../util';

type VideoType = 'youtube' | 'vimeo';

export interface BasicVideoProps {
  title?: string;
  anchorName?: string;
  description?: string;
  type: VideoType;
  id?: string;
  url?: string;
  transcription?: string;
}

export default function BasicVideo({
  title,
  anchorName,
  description,
  type,
  id,
  url,
  transcription
}: BasicVideoProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`basic-video ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="basic-video__grid">
        <div className="basic-video__info">
          {title && <h2 className="basic-video__title">{title}</h2>}
          {description && <p className="basic-video__description">{formatText(description)}</p>}
        </div>
        <div className="basic-video__content">
          <div className={`basic-video__video cookieconsent-optin ${description ? '' : 'basic-video__video--no-desc'}`}>
            {type === 'vimeo' && (
              <iframe
                className="basic-video__iframe"
                data-cookieblock-src={url ? url : `https://player.vimeo.com/video/${id ? id : ''}`}
                data-cookieconsent="marketing"
                width="500"
                height="281"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={`Vimeo video player; ${title ? title : ''}`}
                loading="lazy"
              />
            )}
            {type == 'youtube' && (
              <iframe
                className="basic-video__iframe"
                width="500"
                height="281"
                data-cookieblock-src={url ? url : `https://www.youtube.com/embed/${id ? id : ''}`}
                data-cookieconsent="marketing"
                title={`YouTube video player; ${title ? title : ''}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              />
            )}
          </div>
          <div
            className={`basic-video__cookie cookieconsent-optout-marketing ${
              description ? '' : 'basic-video__cookie--no-desc'
            }`}
          >
            <div className="basic-video__cookie-inner">
              <h3 className="basic-video__cookie-title">
                This content is hosted on {type === 'vimeo' ? 'Vimeo' : 'YouTube'}
              </h3>
              <p>
                You need to consent to marketing cookies set by {type === 'vimeo' ? 'Vimeo' : 'YouTube'} to view this
                content.
              </p>
              <button
                className="basic-video__cookie-link"
                onClick={(event) => {
                  event.preventDefault();
                  // eslint-disable-next-line
                  // @ts-ignore
                  // eslint-disable-next-line
                  Cookiebot.renew();
                  return false;
                }}
              >
                Manage preferences
              </button>
            </div>
          </div>
          {transcription && (
            <div className="basic-video__transcription">
              <Accordion body={transcription} expandText="Show transcription" collapseText="Hide transcription" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
