import React, { ReactElement } from 'react';
import './explorer-hero.scss';
import { ExplorerImageProps } from '../ExplorerImage/ExplorerImage';
import Cta, { CtaProps } from '../Cta/Cta';
import { formatText } from '../../util';

export type ExplorerSponsorProps = {
  logo?: { src: string; alt: string };
  messageLink?: string;
  text?: string;
};

export type ExplorerHeroProps = {
  promoTitle: string;
  promoSubtitle?: string;
  promoSubText?: string;
  promoCta: CtaProps;
  promoBackgroundColor: string;
  promoTextColor?: string;
  promoImage: ExplorerImageProps;
  browseDescription: string;
  explorerSponsors?: ExplorerSponsorProps[];
};

export default function ExplorerHero({
  promoTitle,
  promoSubtitle,
  promoSubText,
  promoCta,
  promoBackgroundColor,
  promoTextColor = 'white',
  promoImage,
  browseDescription,
  explorerSponsors
}: ExplorerHeroProps): ReactElement {
  const promoDefaultBg = promoTextColor === 'white' ? 'theme--ra-blue' : 'theme--ra-light-blue';
  const visitDefaultBg = promoTextColor === 'white' ? 'theme--ra-light-purple' : 'theme--ra-purple';

  const handleClick = (): void => {
    const cta: HTMLButtonElement | null = document.querySelector('.explorer-hero__promo__content .primary-cta');
    cta?.click();
  };

  return (
    <section className="explorer-hero">
      <div className="explorer-hero__container">
        <div className="explorer-hero__promo">
          <div className="explorer-hero__promo__grid">
            <div className="explorer-hero__promo__image" style={{ backgroundImage: `url(${promoImage.src.large})` }} />
            <div
              className={`explorer-hero__promo__content ${promoDefaultBg}`}
              style={{ backgroundColor: promoBackgroundColor }}
              onClick={handleClick}
            >
              <div className="explorer-hero__promo__inner">
                <h2 className="explorer-hero__promo__title">{formatText(promoTitle)}</h2>
                {promoSubtitle && <h3 className="explorer-hero__promo__subtitle">{promoSubtitle}</h3>}
                {promoSubText && <p className="explorer-hero__promo__subtext">{promoSubText}</p>}
                <Cta
                  {...promoCta}
                  theme={promoDefaultBg}
                  type="primary-cta--wide"
                  gaTags={{ type: 'cta', area: 'main_hero' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`explorer-hero__browse ${visitDefaultBg}`}>
          <div className="explorer-hero__browse__grid">
            <div className="explorer-hero__browse__title">{browseDescription}</div>
            <div className="explorer-hero__browse__sponsors">
              {explorerSponsors &&
                explorerSponsors.map((sponsor, index) => (
                  <div className="explorer-hero__browse__sponsor" key={`explorer-hero__browse__sponsor--${index}`}>
                    {sponsor.text && (
                      <span className="explorer-hero__browse__sponsor__text">{formatText(sponsor.text)}</span>
                    )}
                    <a
                      className="explorer-hero__browse__sponsor__link"
                      href={sponsor.messageLink}
                      target="_blank"
                      rel="noreferrer"
                      data-ga4-type="ui"
                      data-ga4-area="explorer_hero"
                    >
                      {sponsor.logo && (
                        <img
                          className="explorer-hero__browse__sponsor__logo"
                          src={sponsor.logo.src}
                          alt={sponsor.logo.alt}
                        />
                      )}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
