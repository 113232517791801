import { FeaturedItem } from 'types/featuredItem';

const getFilteredFeaturedItems = (data: FeaturedItem[] | null, urlContains: string): FeaturedItem[] | null => {
  if (data == null) {
    return null;
  }

  const matches = data.filter((x) => x?.path.startsWith(urlContains));

  if (matches.length > 0) {
    return matches;
  }

  return null;
};

export default getFilteredFeaturedItems;
