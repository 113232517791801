import React, { ReactElement, useState, useEffect } from 'react';
import './header.scss';
import './cookiebot.scss';
import { useMobile } from '../../hooks/mediaHook';
import Icon from '../Icons/Icons';
import { defaultNavigationLinks, defaultUserMenuLinks } from '../../defaultProps';
import UserMenu from './UserMenu/UserMenu';
import { EventCardProps } from '../Card/EventCard/EventCard';
import PrimaryNavigation from './Navigation/PrimaryNavigation';
import { breakpoints } from '../../util';
import { HostnameProps } from '../../api/interface';
import { BaseUrl } from '../../types';

export interface HeaderSubNavLinkProps {
  label: string;
  slug: string;
  baseUrl: BaseUrl;
}

export interface HeaderLinkProps {
  label: string;
  slug: string;
  baseUrl: BaseUrl;
  active?: boolean;
  subNav?: HeaderSubNavLinkProps[];
  subNavColor?: string;
  card?: EventCardProps;
}

export interface HeaderProps {
  hostnames: HostnameProps;
  navigationLinks: HeaderLinkProps[];
}

export interface UserStatusProps {
  loggedIn: boolean;
  friend: boolean;
}

export default function Header({ hostnames, navigationLinks = defaultNavigationLinks }: HeaderProps): ReactElement {
  const NAV_SCROLL = 150;
  const [sticky, setSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const mobile = useMobile(breakpoints.md, false);
  const [loading, setLoading] = useState(true);
  const theme = 'theme--ra-black';

  const openMobileMenu = (): void => {
    setMenuOpen(true);
    document.body.classList.add('lock-scroll');
  };

  const closeMobileMenu = (): void => {
    setMenuOpen(false);
    document.body.classList.remove('lock-scroll');
  };

  const isStickyFn = (): void => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= NAV_SCROLL;
    setSticky(stickyClass);
  };

  const [userStatus, setUserStatus] = useState<UserStatusProps>({
    loggedIn: false,
    friend: false
  });

  useEffect(() => {
    window.addEventListener('scroll', isStickyFn);
    return () => {
      window.removeEventListener('scroll', isStickyFn);
    };
  });

  const toggleMenuFn = (showMenu: boolean): ReactElement =>
    showMenu ? (
      <button onClick={closeMobileMenu}>
        <span className="sr-only"> (hide menu)</span>
        <Icon icon="close" />
      </button>
    ) : (
      <button onClick={openMobileMenu}>
        <span className="sr-only"> (show menu)</span>
        <Icon icon="menu" />
      </button>
    );

  return (
    <header
      className={`header ${theme} ${sticky ? 'header--scrolling' : ''} ${mobile && menuOpen ? 'header--open' : ''}`}
    >
      {mobile && (
        <div className={`header__mobile ${theme}`}>
          <div className="header__logo">
            <a href={hostnames.website}>
              <span className="sr-only">Royal Academy</span>
              <Icon icon="ra-logo" />
            </a>
          </div>
          <div className="header__menu-toggle">{toggleMenuFn(menuOpen)}</div>
        </div>
      )}
      <div className={`header__container  ${theme}`}>
        <div className="header__grid">
          {!mobile && (
            <div className="header__logo">
              <a href={hostnames.website}>
                <span className="sr-only">Royal Academy</span>
                <Icon icon="ra-logo" />
              </a>
            </div>
          )}
          <UserMenu
            {...defaultUserMenuLinks}
            theme={theme}
            hostnames={hostnames}
            sticky={sticky}
            userStatus={userStatus}
            setUserStatus={setUserStatus}
            loading={loading}
            setLoading={setLoading}
          />
          <PrimaryNavigation hostnames={hostnames} links={navigationLinks} theme={theme} userStatus={userStatus} />
        </div>
      </div>
    </header>
  );
}
