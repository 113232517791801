import React, { ReactElement } from 'react';
import { ImageProps } from '../../Image/Image';
import Image from '../../Image/Image';
import './imageCard.scss';
import { sanitizeText } from '../../../util';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

export type ImageCardProps = {
  altText?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  image: ImageProps;
  onClick?: () => void;
  imageOnLoad?: () => void;
};

export default function ImageCard({
  altText,
  title,
  subtitle,
  description,
  image,
  onClick,
  imageOnLoad
}: ImageCardProps): ReactElement {
  return (
    <div className="image-card" onClick={onClick} data-ga4-type="ui" data-ga4-area="image_gallery">
      <figure className="image-card__figure" data-ga4-type="ui" data-ga4-area="image_gallery">
        <div className="image-card__image">
          <Image
            {...image}
            altText={altText ?? image.altText}
            gaTags={{ type: 'ui', area: 'image_gallery' }}
            onLoad={imageOnLoad ? imageOnLoad : undefined}
          />
        </div>
        {(title || description) && (
          <figcaption className="image-card__caption" data-ga4-type="ui" data-ga4-area="image_gallery">
            {title && (
              <h3 className="image-card__title">
                <button className="image-card__cta" onClick={onClick} data-ga4-type="ui" data-ga4-area="image_gallery">
                  <span className="image-card__cta-text">{title}</span>
                  <span className="sr-only"> image</span>
                </button>
              </h3>
            )}
            {subtitle && <h4 className="image-card__subtitle">{subtitle}</h4>}
            {description && (
              <div className="image-card__description" data-ga4-type="ui" data-ga4-area="image_gallery">
                <HTMLEllipsis unsafeHTML={sanitizeText(description)} maxLine="3" basedOn="words" />
              </div>
            )}
          </figcaption>
        )}
      </figure>
    </div>
  );
}
