import { FilterProps } from '../components/ExplorerArtworks/ExplorerArtworks';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

type FilterValue = string | FilterProps[] | PriceRangeProps;

interface PriceRangeProps {
  value: string;
}

type PriceRangeKey = 'under-250' | '250-500' | '500-1000' | '1000-2000' | 'over-2000';

const formatPriceRange = (value: string): string => {
  const priceRanges: Record<PriceRangeKey, string> = {
    'under-250': '£0 - £250',
    '250-500': '£250 - £500',
    '500-1000': '£500 - £1,000',
    '1000-2000': '£1,000 - £2,000',
    'over-2000': 'Over £2,000'
  };
  return priceRanges[value as PriceRangeKey] || value;
};

const formatFilterValue = (action: string, value: FilterValue): [string, string] => {
  if (Array.isArray(value)) {
    return [value.map((filter) => filter.type).join(', '), value.map((filter) => filter.value).join(', ')];
  } else if (typeof value === 'string') {
    return [action, action === 'price' ? formatPriceRange(value) : value];
  } else {
    return ['price', formatPriceRange(value.value)];
  }
};

export const handleArtworkExplorerGADataLayerPush = (action: string, filterValue: FilterValue): void => {
  window.dataLayer = window.dataLayer || [];

  const [formattedFilterType, formattedFilterValue] = formatFilterValue(action, filterValue);

  window.dataLayer.push({
    event: 'artwork explorer filter',
    filter_field: formattedFilterType,
    filter_value: formattedFilterValue
  });
};
