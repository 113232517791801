import React, { ReactElement } from 'react';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName, formatText } from '../../util';
import Cta, { CtaProps } from '../Cta/Cta';
import Image, { ImageProps } from '../Image/Image';

import './half-half.scss';

export type HalfHalfProps = {
  title?: string;
  anchorName?: string;
  description?: string;
  theme: ThemeProps;
  alignment: string;
  image: ImageProps;
  ctaPrimary?: CtaProps;
  ctaTertiary?: CtaProps;
};

export default function HalfHalf({
  title,
  anchorName,
  description,
  theme = 'theme--ra-green',
  alignment,
  image,
  ctaPrimary,
  ctaTertiary
}: HalfHalfProps): ReactElement {
  let white = false;
  switch (theme) {
    case 'theme--ra-blue':
    case 'theme--ra-pink':
    case 'theme--ra-purple':
    case 'theme--ra-green':
      white = true;
  }
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`half-half half-half--${alignment} ${white ? ' half-half--light' : ''} ${
        anchorName ? 'ra--offset' : ''
      }`}
    >
      <div className="half-half__wrapper">
        <div className="half-half__container">
          <div className="half-half__grid">
            <div className="half-half__image">
              <Image {...image} />
            </div>
            <div className={` half-half__content ${theme}`}>
              <div className="half-half__inner">
                {title && <h2 className="half-half__title">{formatText(title)}</h2>}
                {description && <div className="half-half__description">{formatText(description)}</div>}
                <div className="half-half__cta">
                  {ctaPrimary && <Cta {...ctaPrimary} theme={theme} />}
                  {ctaTertiary && <Cta {...ctaTertiary} theme={theme} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
