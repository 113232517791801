import React, { ReactElement } from 'react';
import { ThemeProps } from '../../defaultProps';
import { formatAnchorName, formatText } from '../../util';
import Cta, { CtaProps } from '../Cta/Cta';
import Image, { ImageProps } from '../Image/Image';
import './promo.scss';
import RichText from '../RichText/RichText';

export type PromoProps = {
  title?: string;
  anchorName?: string;
  description: string;
  theme: ThemeProps;
  alignment: string;
  image: ImageProps;
  cta?: CtaProps;
};

export default function Promo({
  title,
  anchorName,
  description,
  theme,
  alignment,
  image,
  cta
}: PromoProps): ReactElement {
  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`promo promo--${alignment} ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="promo__wrapper">
        <div className="promo__container">
          <div className="promo__grid">
            <div className="promo__image">
              <Image {...image} />
            </div>
            <div className={` promo__content ${theme}`}>
              <div className="promo__inner">
                {title && <h2 className="promo__title">{formatText(title)}</h2>}
                {description && (
                  <div className="promo__description">{<RichText text={description} noGrid showMore={false} />}</div>
                )}
                {cta && (
                  <div className="promo__cta">
                    <Cta {...cta} theme={theme} gaTags={{ type: 'feature', area: 'promo' }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
