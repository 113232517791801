import { ThemeProps } from '@royalacademy/component-library';

const getTheme = (themeName: string, componentThemeDefault: string): ThemeProps => {
  if (themeName === 'theme--ra-default' || themeName === '') {
    return componentThemeDefault as ThemeProps;
  }

  return themeName as ThemeProps;
};

export default getTheme;
