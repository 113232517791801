import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import EventCard, { EventCardProps } from '../Card/EventCard/EventCard';
import TertiaryCta from '../Cta/TertiaryCta/TertiaryCta';
import './listing.scss';
import { formatAnchorName } from '../../util';
import Pagination from '../Pagination/Pagination';

export interface ListingProps {
  title: string;
  cards: EventCardProps[];
  link?: {
    href: string;
    label: string;
  };
  anchorName?: string;
  cardsPerPage?: number;
}

export default function Listing({ title, cards, cardsPerPage = 15, link, anchorName }: ListingProps): ReactElement {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const listingRef = useRef<HTMLUListElement>(null);

  const paginatedItems = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * cardsPerPage;
    const lastPageIndex = firstPageIndex + cardsPerPage;
    return cards.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, cards, cardsPerPage]);

  const handlePageChange = useCallback((page: number) => {
    setLoading(true);
    setCurrentPage(page);
    setTimeout(() => {
      if (listingRef.current) {
        const topDist = listingRef.current.getBoundingClientRect().top + window.pageYOffset - 92;
        window.scrollTo({ behavior: 'smooth', top: topDist });
      }
      setLoading(false);
    }, 100);
  }, []);

  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`listing ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="listing__container">
        <div className="listing__grid">
          <h2 className="listing__title">{title}</h2>
          <ul className="listing__cards" ref={listingRef}>
            {!loading &&
              paginatedItems.map((card, i) => (
                <li className="listing__card" key={i}>
                  <EventCard {...card} />
                </li>
              ))}
          </ul>
          {link && (
            <div className="listing__link">
              <TertiaryCta href={link.href} label={link.label} gaTags={{ type: 'ui', area: 'watch_explore' }} />
            </div>
          )}
          {cardsPerPage && (
            <div className="listing__pagination">
              <Pagination
                totalCount={cards.length}
                pageSize={cardsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
