import React, { ReactElement, useState } from 'react';
import Icon from '../../Icons/Icons';
import './primary-navigation.scss';
import { useMobile } from '../../../hooks/mediaHook';
import { UserStatusProps, HeaderLinkProps, HeaderSubNavLinkProps } from '../Header';
import EventCard from '../../Card/EventCard/EventCard';
import { ThemeProps } from '../../../defaultProps';
import Cta from '../../Cta/Cta';
import { HostnameProps } from '../../../api/interface';

export type PrimaryNavigationProps = {
  theme: ThemeProps;
  hostnames: HostnameProps;
  links: HeaderLinkProps[];
  userStatus: UserStatusProps;
};

export default function PrimaryNavigation({
  hostnames,
  links,
  theme,
  userStatus
}: PrimaryNavigationProps): ReactElement {
  const mobile = useMobile('1024px', true);
  const [subNavOpen, updateSubNav] = useState('');

  const closeSubNavFn = (): void => {
    updateSubNav('');
  };

  const openSubNavFn = (subNav: string): void => {
    if (subNav === subNavOpen) {
      closeSubNavFn();
    } else {
      updateSubNav(subNav);
    }
  };

  const handleMenuClickFn = (subNav: string, event: React.MouseEvent): void => {
    event.preventDefault();
    openSubNavFn(subNav);
  };

  const linkOutputFn = (): ReactElement[] =>
    links.map((link: HeaderLinkProps, index: number) => {
      let subNavGtmName: string;
      switch (link.label) {
        case "What's On":
          subNavGtmName = 'exhibitions & events';
          break;
        case 'Visit':
          subNavGtmName = 'visit';
          break;
        case 'Learn':
          subNavGtmName = 'learn';
          break;
        case 'Art & Artists':
          subNavGtmName = 'Art & Artists';
          break;
        case 'Join & Support':
          subNavGtmName = 'join&support';
          break;
      }
      return (
        <li
          className={`primary-navigation__link ${
            !mobile && subNavOpen === link.slug ? 'primary-navigation__link--open' : ''
          }`}
          key={`primary-navigation__link--${index}`}
        >
          <button
            onClick={(event) => handleMenuClickFn(link.slug, event)}
            data-ga4-type="navigation"
            data-ga4-area="main_primary"
            data-gtm-name="nav: primary"
          >
            {link.label}
            {mobile ? <Icon icon="chevron-right" /> : <Icon icon="underline" />}
          </button>

          {link.subNav && (
            <>
              {subNavOpen === link.slug && (
                <div className="primary-navigation__subnav__overlay" onClick={() => closeSubNavFn()}></div>
              )}

              <div
                className={`primary-navigation__subnav  ${link.subNavColor || 'theme--ra-light-blue'} ${
                  subNavOpen === link.slug ? 'primary-navigation__subnav--open' : ''
                }`}
              >
                {' '}
                <div className="primary-navigation__subnav__container">
                  <div className="primary-navigation__subnav__grid">
                    {mobile && (
                      <div className={`primary-navigation__subnav__back ${theme}`}>
                        <button onClick={() => closeSubNavFn()}>
                          <Icon icon="chevron-left" />
                          Back
                        </button>
                      </div>
                    )}
                    <ul className="primary-navigation__subnav__links">
                      {link.subNav.map((subNavLink: HeaderSubNavLinkProps, subNavIndex: number) => (
                        <li
                          className="primary-navigation__subnav__link"
                          key={`primary-navigation__subnav__link--${link.label}-${subNavIndex}`}
                        >
                          <a
                            href={`${hostnames[subNavLink.baseUrl]}/${subNavLink.slug}`}
                            data-ga4-type="navigation"
                            data-ga4-area="main_sub"
                            data-gtm-name={`nav: secondary: ${subNavGtmName}`}
                          >
                            {subNavLink.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                    {!mobile && link.card && (
                      <EventCard
                        {...link.card}
                        gaTags={{ type: 'navigation', area: 'main_sub_feature', name: 'nav: feature' }}
                      ></EventCard>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </li>
      );
    });

  return (
    <nav className={`primary-navigation  ${theme}`} role="navigation" aria-label="Main">
      <div className="primary-navigation__container">
        <ul className="primary-navigation__links">{linkOutputFn()}</ul>
        {!userStatus.friend && (
          <div className="primary-navigation__friend">
            <Cta
              type="primary"
              label="Become a Friend"
              theme={theme}
              hideArrow={true}
              href={`${hostnames.website}/friends-of-the-royal-academy`}
              wide={true}
              gaTags={{ type: 'navigation', area: 'main_primary', name: 'nav: primary' }}
            />
          </div>
        )}
      </div>
    </nav>
  );
}
