import { apiWithCredentials } from './config/axiosConfig';
import dayjs from 'dayjs';

export type SignupProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  interests?: string;
  originalSource?: string;
  originalSourceLocation?: string;
  optIn?: string | boolean;
  graduationDate?: string;
  organisation?: string;
  course?: string;
  birthdate?: string;
};

// TODO: We're currently not handling originalSource & originalSourceLocation!

const parsedData = (data: SignupProps): SignupProps => {
  const submitData = { ...data };

  // The Tessitura API accepts only booleans for optIn!
  if (submitData.optIn) {
    submitData.optIn = submitData.optIn === 'true';
  }

  // The Tessitura API accepts only MM/YYYY for graduationDate!
  if (submitData.graduationDate) {
    submitData.graduationDate = dayjs(submitData.graduationDate).format('MM/YYYY');
  }

  return submitData;
};

export const SignUpApi = {
  create: async function (endpoint: string, data: SignupProps) {
    await apiWithCredentials.request({
      url: endpoint,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: parsedData(data)
    });
  }
};
