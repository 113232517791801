export const membershipsData = [
  {
    id: 1,
    name: 'tnew-membership-young',
    memb_level_no_standard: 32,
    memb_level_no_dd: 25,
    isGift: false,
    switchId: 4,
    components: [
      {
        componentType: 'confirmAge',
        content: 'To be eligible for a Young Friend membership you must be between 16-35 years of age'
      },
      {
        componentType: 'startDate',
        content: 'When do you want your membership to start?',
        futureStartPeriod: 30
      },
      {
        componentType: 'paymentType',
        content: 'Save £12 when purchasing a recurring annual membership.'
      }
    ]
  },
  {
    id: 2,
    name: 'tnew-membership-standard',
    memb_level_no_standard: 40,
    memb_level_no_dd: 34,
    isGift: false,
    switchId: 5,
    components: [
      {
        componentType: 'startDate',
        content: 'When do you want your membership to start?',
        futureStartPeriod: 30
      },
      {
        componentType: 'paymentType',
        upsellContent: 'Want a second cardholder? Make this a Joint Friends membership',
        upsellMembershipId: 3,
        content: 'Save £12 when purchasing a recurring annual membership.'
      }
    ]
  },
  {
    id: 3,
    name: 'tnew-membership-joint',
    memb_level_no_standard: 46,
    memb_level_no_dd: 52,
    isGift: false,
    content: 'Save £12 when purchasing a recurring annual membership.',
    switchId: 6,
    components: [
      {
        componentType: 'secondCardholder',
        content: 'Who will be the second cardholder? (We’ll ask for your details at checkout).',
        whoIsSecondCardholder: false,
        contentEmail:
          '<p>Enter the cardholder’s email address to enable them to book Friends tickets online and manage their account.</p><br><p> Personal data will be managed respectfully and responsibly, in accordance with the Royal Academy\'s <a href="/privacy" target="_blank">privacy policy</a>.',
        inputData: {
          title: '',
          firstName: '',
          lastName: '',
          email: ''
        }
      },
      {
        componentType: 'startDate',
        content: 'When do you want your membership to start?',
        futureStartPeriod: 30
      },
      {
        componentType: 'paymentType',
        content: 'Save £12 when purchasing a recurring annual membership.'
      }
    ]
  },
  {
    id: 4,
    name: 'tnew-membership-young_gift',
    memb_level_no_standard: 29,
    memb_level_no_dd: 26,
    isGift: true,
    switchId: 1,
    components: [
      {
        componentType: 'confirmAge',
        content: 'To be eligible for a Young Friend membership the recipient must be between 16-35 years of age.'
      },
      {
        componentType: 'giftRecipient',
        content: 'Who is this gift for? (We’ll ask for your details at checkout).',
        contentEmail:
          '<p>Don’t worry, you can set the start date below, and we won’t email the recipient until after their membership starts so it will be a surprise. But we need their email address so they can log in and book tickets and manage their account.</p><br><p> Personal data will be managed respectfully and responsibly, in accordance with the Royal Academy\'s <a href="/privacy" target="_blank">privacy policy</a>.',
        inputData: {
          title: '',
          firstName: '',
          lastName: '',
          email: ''
        }
      },
      {
        componentType: 'startDate',
        content: 'When do you want your membership to start?',
        futureStartPeriod: 30
      },
      {
        componentType: 'paymentType',
        content: 'Save £12 when purchasing a recurring annual membership.'
      }
    ]
  },
  {
    id: 5,
    name: 'tnew-membership-standard_gift',
    memb_level_no_standard: 45,
    memb_level_no_dd: 37,
    isGift: true,
    switchId: 2,
    components: [
      {
        componentType: 'giftRecipient',
        content: 'Who is this gift for? (We’ll ask for your details at checkout).',
        contentEmail:
          '<p>Don’t worry, you can set the start date below, and we won’t email the recipient until after their membership starts so it will be a surprise. But we need their email address so they can log in and book tickets and manage their account.</p><br><p> Personal data will be managed respectfully and responsibly, in accordance with the Royal Academy\'s <a href="/privacy" target="_blank">privacy policy</a>.',
        inputData: {
          title: '',
          firstName: '',
          lastName: '',
          email: ''
        }
      },
      {
        componentType: 'startDate',
        content: 'When do you want your membership to start?',
        futureStartPeriod: 30
      },
      {
        componentType: 'paymentType',
        content: 'Save £12 when purchasing a recurring annual membership.'
      }
    ]
  },
  {
    id: 6,
    name: 'tnew-membership-joint_gift',
    memb_level_no_standard: 50,
    memb_level_no_dd: 53,
    isGift: true,
    content: 'Save £12 when purchasing a recurring annual membership.',
    switchId: 3,
    components: [
      {
        componentType: 'giftRecipient',
        content: 'Who is this gift for? (We’ll ask for your details at checkout).',
        contentEmail:
          '<p>Don’t worry, you can set the start date below, and we won’t email the recipient until after their membership starts so it will be a surprise. But we need their email address so they can log in and book tickets and manage their account.</p><br><p> Personal data will be managed respectfully and responsibly, in accordance with the Royal Academy\'s <a href="/privacy" target="_blank">privacy policy</a>.',
        inputData: {
          title: '',
          firstName: '',
          lastName: '',
          email: ''
        }
      },
      {
        componentType: 'secondCardholder',
        content: 'Don’t forget to include the second cardholder’s details (these should be different to the first)',
        whoIsSecondCardholder: true,
        contentEmail:
          '<p>Enter the cardholder’s email address to enable them to book Friends tickets online and manage their account</p><br><p> Personal data will be managed respectfully and responsibly, in accordance with the Royal Academy\'s <a href="/privacy" target="_blank">privacy policy</a>.',
        inputData: {
          title: '',
          firstName: '',
          lastName: '',
          email: ''
        }
      },
      {
        componentType: 'startDate',
        content: 'When do you want your membership to start?',
        futureStartPeriod: 30
      },
      {
        componentType: 'paymentType',
        content: 'Save £12 when purchasing a recurring annual membership.'
      }
    ]
  }
];
