import React, { ReactElement, useEffect, useState, useRef } from 'react';
import Pagination from '../../Pagination/Pagination';
import ListingInfo from '../../ListingInfo/ListingInfo';
import './explorer-listing.scss';
import ArtworkCard, { ArtworkCardProps } from '../../Card/ArtworkCard/ArtworkCard';

export interface ExplorerListingProps {
  data: ArtworkCardProps[];
  pageSize: number;
  pageNumber: number;
  onPageChange?: (page: number) => void;
  totalCount: number;
  isLoading: boolean;
}

export default function ExplorerListing({
  data,
  pageSize,
  pageNumber,
  onPageChange,
  totalCount,
  isLoading
}: ExplorerListingProps): ReactElement {
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const listingRef = useRef<HTMLElement | null>(null);

  function handlePageChange(page: number): void {
    setCurrentPage(page);
    if (onPageChange) {
      onPageChange(page);
    }
    if (listingRef.current) {
      const topDist = listingRef.current.getBoundingClientRect().top + window.pageYOffset - 92;
      setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: topDist });
      }, 1);
    }
  }

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  return (
    <section ref={listingRef} className="explorer-listing">
      <ListingInfo<ArtworkCardProps>
        currentPage={currentPage}
        pageSize={pageSize}
        results={data}
        resultsTotal={totalCount}
      />
      {isLoading ? (
        <div className="explorer-listing__loading">Loading...</div>
      ) : (
        <ul className="explorer-listing__list">
          {data.map((artwork: ArtworkCardProps, i) => (
            <li className="explorer-listing__item" key={i}>
              <ArtworkCard {...artwork} gaTags={{ type: 'card', area: 'listings_card' }} />
            </li>
          ))}
        </ul>
      )}
      <Pagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </section>
  );
}
