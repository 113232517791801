import React, { ReactElement } from 'react';
import { CtaProps } from '../Cta';
import './primaryCta.scss';

export default function PrimaryCta({
  label,
  href,
  theme,
  hideArrow = false,
  selected = false,
  wide = false,
  onClick,
  gaTags
}: CtaProps): ReactElement {
  let white = false;
  switch (theme) {
    case 'theme--ra-blue':
    case 'theme--ra-pink':
    case 'theme--ra-purple':
    case 'theme--ra-green':
    case 'theme--ra-black':
      white = true;
  }

  return (
    <>
      {href && href.length > 0 ? (
        <a
          href={href}
          className={`primary-cta ${white ? 'primary-cta--white' : ''} ${selected ? 'primary-cta--selected' : ''} ${
            hideArrow ? 'primary-cta--no-arrow' : ''
          } ${wide ? 'primary-cta--wide' : ''}`}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          <span className="primary-cta__text">{label}</span>
        </a>
      ) : (
        <button
          className={`primary-cta ${white ? 'primary-cta--white' : ''} ${selected ? 'primary-cta--selected' : ''} ${
            hideArrow ? 'primary-cta--no-arrow' : ''
          }`}
          onClick={onClick}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          <span className="primary-cta__text">{label}</span>
        </button>
      )}
    </>
  );
}
