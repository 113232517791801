import React, { ReactElement } from 'react';
import { GALinkProps } from '../../../defaultProps';
import { ImageProps } from '../../Image/Image';
import './categoryCard.scss';

export type CategoryCardProps = {
  title: string;
  image: ImageProps;
  url: string;
  color?: string;
  gaTags?: GALinkProps;
};

export default function CategoryCard({ title, image, url, color, gaTags }: CategoryCardProps): ReactElement {
  const onClick = (): void => {
    location.href = url;
  };

  return (
    <div className={`category-card ${color ? color : ''}`} onClick={onClick}>
      <div className="category-card__image" style={{ backgroundImage: `url(${image.src.large})` }} />
      <h3 className="category-card__title">
        <a
          className="category-card__anchor"
          href={url}
          data-ga4-type={gaTags?.type}
          data-ga4-area={gaTags?.area}
          data-gtm-name={gaTags?.name}
        >
          {title}
        </a>
      </h3>
    </div>
  );
}
