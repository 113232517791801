import React, { ReactElement } from 'react';
import { Dropdown, Input, Radio } from '../../FormFields/FormFields';
import { FriendFormComponentProps, NewFriendProps } from '../FriendSignup';
import './second-card-holder.scss';
import { prefixes } from '../data/prefixes';

export type SecondCardHolderProps = {
  component: FriendFormComponentProps;
  handleFormInput: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    component: FriendFormComponentProps,
    title: string
  ) => void;
  handleOnBlur: (value: string, name: string, type: string) => void;
  newFriend: NewFriendProps;
  setParam: (n: string, v: boolean) => void;
};

export default function SecondCardHolder({
  component,
  newFriend,
  setParam,
  handleFormInput,
  handleOnBlur
}: SecondCardHolderProps): ReactElement {
  const validateFieldFn = (name: string, errorMessage: string): string | undefined => {
    if (newFriend.inlineErrors && newFriend.inlineErrors.includes(name)) return errorMessage;
  };

  const handleOnChangeFn = (e: React.ChangeEvent<HTMLInputElement>, title: string): void =>
    handleFormInput(e, component, title);

  return (
    <div className="second-card-holder">
      <h4 className="second-card-holder__title">Second Card Holder</h4>
      <p className="second-card-holder__desc">{component.content} </p>
      <div className="gift-recipient__form">
        {component.whoIsSecondCardholder && (
          <div className="gift-recipient__form__row gift-recipient__form__row--inline">
            <Radio
              type="radio"
              id="other-second-card"
              name="tn-cust-field-param-constituent-as-second-card-holder"
              value={true}
              checked={newFriend.paramConstituentAsSecondCardHolder}
              onChange={() => setParam('param-constituent-as-second-card-holder', true)}
              label="Someone else"
            />
            <Radio
              type="radio"
              id="constituent-second-card"
              name="tn-cust-field-param-constituent-as-second-card-holder"
              value={false}
              checked={!newFriend.paramConstituentAsSecondCardHolder}
              onChange={() => setParam('param-constituent-as-second-card-holder', false)}
              label="Me"
            />
          </div>
        )}
        {(newFriend.paramConstituentAsSecondCardHolder || !component.whoIsSecondCardholder) && (
          <div>
            <div className="gift-recipient__form__row gift-recipient__form__row--inline">
              <Dropdown
                options={prefixes}
                name="tn-cust-field-param-joint_prefix"
                id="second-card-holder__form__title"
                required={false}
                value={component.inputData?.title}
                label="Title"
                initialOption="Select..."
                onChange={(e) => {
                  handleFormInput(e, component, 'title');
                }}
              />
              <Input
                type="text"
                id="second-card-holder__form__firstname"
                value={component.inputData ? component.inputData.firstName : ''}
                name="tn-cust-field-param-joint_fname"
                inlineError={validateFieldFn('tn-cust-field-param-joint_fname', 'Name must be 2 letters long')}
                label="Recipient first name"
                onBlur={(e) => handleOnBlur(e.target.value, e.target.name, 'firstName')}
                required={true}
                autoComplete="new-password"
                onChange={(e) => {
                  handleFormInput(e, component, 'firstName');
                }}
                border={true}
                modifierClass="form-field--firstname"
              />

              <Input
                type="text"
                id="second-card-holder__form__lastname"
                value={component.inputData ? component.inputData.lastName : ''}
                name="tn-cust-field-param-joint_lname"
                inlineError={validateFieldFn('tn-cust-field-param-joint_lname', 'Name must be 2 letters long')}
                label="Recipient last name"
                onBlur={(e) => handleOnBlur(e.target.value, e.target.name, 'lastName')}
                required={true}
                autoComplete="new-password"
                onChange={(e) => {
                  handleFormInput(e, component, 'lastName');
                }}
                border={true}
                modifierClass="form-field--lastname"
              />
            </div>
            {newFriend.giftMatchesJoint && (
              <p className="gift-recipient__form__row gift-recipient__form__row--no-margin form-field__error">
                It looks like you&rsquo;ve entered the same details twice. Please change the second cardholder&rsquo;s
                details
              </p>
            )}
            <div className="gift-recipient__form__row">
              <Input
                type="email"
                id="gift-recipient__form__email"
                value={component.inputData?.email}
                name="tn-cust-field-param-joint_email"
                label="Email (optional)"
                onChange={(e) => handleOnChangeFn(e, 'email')}
                border={true}
                help={component.contentEmail && <div dangerouslySetInnerHTML={{ __html: component.contentEmail }} />}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
