import React, { ReactElement, useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { formatAnchorName, formatText, sanitizeText } from '../../util';
import { useMobile } from '../../hooks/mediaHook';
import Cta, { CtaProps } from '../Cta/Cta';
import './rich-text.scss';

export interface RichTextProps {
  title?: string;
  text: string;
  cta?: CtaProps;
  noGrid?: boolean;
  showMore?: boolean;
  anchorName?: string;
}

export default function RichText({
  title,
  anchorName,
  text,
  cta,
  noGrid,
  showMore = false
}: RichTextProps): ReactElement {
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const toggleOpen = (): void => {
    setOpen((prev) => !prev);
  };

  return (
    <div
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`rich-text ${noGrid ? '' : 'rich-text--grid'} ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="rich-text__container">
        <div className="rich-text__wrapper">
          {title && <h2 className="rich-text__title">{title}</h2>}
          {showMore && isMobile && !open ? (
            <HTMLEllipsis
              unsafeHTML={sanitizeText(text)}
              maxLine="10"
              basedOn="words"
              onReflow={({ clamped }) => setIsClamped(clamped)}
            />
          ) : (
            formatText(text)
          )}
          {cta && (
            <div className="rich-text__cta">
              <Cta {...cta} gaTags={{ type: 'feature', area: 'text' }} />
            </div>
          )}
        </div>
        {showMore && isMobile && isClamped && (
          <button className={`rich-text__button ${open ? 'rich-text__button--open' : ''}`} onClick={toggleOpen}>
            {open ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    </div>
  );
}
